import React, { useState } from 'react'; 
import { useNavigate } from 'react-router-dom';
import { createNewCoverLetter } from '../../services/resumeService';
import LoadingSpinner from '../../components/LoadingSpinner'; // Make sure this path is correct
import { coverletterimages } from '../../util/loadImages';

const templateNames = [
  'Professional', 'Executive', 'Modern', 'Visionary', 'Creative', 'Impactful', 'Dynamic',
  'Explorer', 'Classic', 'Elegant', 'Bold', 'Expertise', 'Aspirations',
  'Minimalist', 'Influential', 'Inspired', 'Versatile', 'Vibrant',
  'Proactive', 'Empowering', 'Ambitious', 'Innovative'
];

const CoverLetterTemplates = ({ userId }) => { 
  const navigate = useNavigate();
  const [templateStyles, setTemplateStyles] = useState(templateNames);  
  const [operationLoading, setOperationLoading] = useState(false);
  
  const handleCreateNewCoverLetter = async (templateName) => {
    if (userId) {
      setOperationLoading(true); 
      try {
        const newCoverLetterId = await createNewCoverLetter(templateName, userId);
        navigate(`/editor/cover-letter/${newCoverLetterId}`);
      } catch (error) {
        console.error("Failed to create new cover letter:", error);
      } finally {
        setOperationLoading(false); 
      }
    } else {
      console.error("User must be logged in to create a new cover letter");
    }
  };

  if (operationLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="container mx-auto px-4 py-6 max-w-6xl">
      <div className='text-center py-4 mb-5'>
        <h1 className="text-2xl font-bold mb-6">Create Job-winning Cover Letter</h1>
        <h2 className='text-xl'>Select Template</h2>
      </div>
      <div className="flex flex-wrap gap-10">
        {templateStyles && templateStyles.map((templateStyle, index) => (
          <div
            key={index}
            className="relative flex flex-col justify-between items-center cursor-pointer group"
            onClick={() => handleCreateNewCoverLetter(templateStyle)}
          >
            <div className="bg-white p-1 rounded shadow dash-resume relative overflow-hidden">
              <img
                src={coverletterimages[`${templateStyle}.png`]}
                alt={templateStyle}
                width="312"
                className="w-full"
              />
              <div className="absolute inset-0 flex items-end justify-center bg-gradient-to-t from-white to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <button className="bg-blue-600 text-sm text-white py-2 px-4 rounded mb-4 transform translate-y-full group-hover:translate-y-0 transition-transform duration-300">Use This Template</button>
              </div>
            </div>
            <div className="text-center block px-4 py-2 font-bold text-sm text-left text-gray-600 w-full hover:text-gray-600">
              <h3>{templateStyle}</h3>
            </div>
          </div>
        ))}
      </div>   
    </div>
  );
};

export default CoverLetterTemplates;
