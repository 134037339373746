import { db } from "../config/firebaseConfig";
import { doc, addDoc, setDoc, collection, serverTimestamp } from "firebase/firestore";
import ResumeModal from '../modals/ResumeModal';

/**
 * Save a resume to Firestore
 * @param {Object} resumeObject The resume object to save
 * @returns {Promise<void>}
 */

const generateUniqueId = () => {
  return '_' + Math.random().toString(36).substr(2, 9);
};

const fetchWithRetry = async (url, options, retries = 3) => {
  for (let attempt = 1; attempt <= retries; attempt++) {
    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`Attempt ${attempt} failed: ${response.statusText}`);
      }
      return response.json();
    } catch (error) {
      console.error(error);
      if (attempt === retries) {
        throw new Error(`Failed after ${retries} attempts`);
      }
    }
  }
};

export const createNewResumeWithAI = async (formData, navigate) => {
  const { firstName, lastName, jobTitle, email, phone, userID, yearsOfExperience = 5 } = formData;
  const styles = ["Visionary", "Influential", "Professional", "Modern", "Creative", "Impactful", "Explorer", "Classic", "Bold", "Elegant", "Expertise", "Inspired", "Versatile", "Vibrant", "Ambitious", "Innovative"];
  const randomStyle = styles[Math.floor(Math.random() * styles.length)];

  const initialResumeData = {
    formObject: {
      ...ResumeModal.formObject,
      personalDetails: {
        ...ResumeModal.formObject.personalDetails,
        inputGroups: [
          {
            ...ResumeModal.formObject.personalDetails.inputGroups[0],
            inputs: ResumeModal.formObject.personalDetails.inputGroups[0].inputs.map(input => {
              switch (input.inputKey) {
                case 'firstName':
                  return { ...input, inputValue: firstName };
                case 'lastName':
                  return { ...input, inputValue: lastName };
                case 'jobTitle':
                  return { ...input, inputValue: jobTitle };
                // case 'photo':
                //   return { ...input, inputValue: "" };
                default:
                  return input;
              }
            })
          }
        ]
      }, //https://firebasestorage.googleapis.com/v0/b/cvdesigner-ai.appspot.com/o/images%2F1721624824411.jpg?alt=media&token=17c8b6ec-83e4-4ab6-850b-079dc982322d [restore this image]
      contactDetails: {
        ...ResumeModal.formObject.contactDetails,
        inputGroups: [
          {
            ...ResumeModal.formObject.contactDetails.inputGroups[0],
            inputs: ResumeModal.formObject.contactDetails.inputGroups[0].inputs.map(input => {
              switch (input.inputKey) {
                case 'email':
                  return { ...input, inputValue: email };
                case 'phone':
                  return { ...input, inputValue: phone };
                case 'city':
                  return { ...input, inputValue: 'NewYork' };
                case 'country':
                  return { ...input, inputValue: 'US' }; 
                case 'linkedin':
                    return { ...input, inputValue: 'LinkedIn.com' };
                default:
                  return input;
              }
            })
          }
        ]
      },
      languages: {
        ...ResumeModal.formObject.languages,
        inputGroups: [
          ...ResumeModal.formObject.languages.inputGroups,
          {
            id: generateUniqueId(),
            isExpanded: true,
            Showlevel: true,
            aiWriter: {
              isStatus: false,
              actions: [
                {
                  id: "generate"
                },
                {
                  id: "rewrite"
                }
              ]
            },
            inputs: [
              {
                inputKey: "language",
                inputLabel: "Language",
                inputType: "text",
                placeholder: "Enter a language",
                show: true,
                required: true,
                inputValue: "English"
              },
              {
                inputKey: "level",
                inputLabel: "Level",
                inputType: "dropdown",
                placeholder: "Select language level",
                show: true,
                required: true,
                inputValue: "Fluent",
                options: [
                  "Beginner",
                  "Moderate",
                  "Good",
                  "Very good",
                  "Fluent"
                ]
              }
            ]
          },
          {
            id: generateUniqueId(),
            isExpanded: true,
            Showlevel: true,
            aiWriter: {
              isStatus: false,
              actions: [
                {
                  id: "generate"
                },
                {
                  id: "rewrite"
                }
              ]
            },
            inputs: [
              {
                inputKey: "language",
                inputLabel: "Language",
                inputType: "text",
                placeholder: "Enter a language",
                show: true,
                required: true,
                inputValue: "Spanish"
              },
              {
                inputKey: "level",
                inputLabel: "Level",
                inputType: "dropdown",
                placeholder: "Select language level",
                show: true,
                required: true,
                inputValue: "Good",
                options: [
                  "Beginner",
                  "Moderate",
                  "Good",
                  "Very good",
                  "Fluent"
                ]
              }
            ]
          }
        ],
        settings: ResumeModal.formObject.languages.settings.map(setting => 
          setting.inputKey === "levelStyle" ? { ...setting, inputValue: "badge" } : setting
        )
      },
      achievements: {
        ...ResumeModal.formObject.achievements,      
        isShow: false   
      }
    },
    sectionOrder: ResumeModal.sectionOrder,
    metadata: {
      ...ResumeModal.metadata,
      userID: userID,
      createdAt: serverTimestamp(),      
      selectedStyle: randomStyle,
      templateName: `Resume-${jobTitle}`
    },
    formOrder: ResumeModal.formOrder
  };

  try {
    const fetchSectionData = (endpoint, sectionKey) => {
      const url = `https://rd-server-418cf5202d66.herokuapp.com/ai-resume/${endpoint}`;
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ jobTitle, yearsOfExperience }),
      };
      return fetchWithRetry(url, options);
    };

    const [skillsData, educationData, workExperience, professionalSummary, achievements, softSkills] = await Promise.all([
      fetchSectionData('skills', 'skills'),
      fetchSectionData('education', 'education'),
      fetchSectionData('workExperience', 'workExperience'),
      fetchSectionData('professionalSummary', 'professionalSummary'),
      fetchSectionData('achievements', 'achievements'),
      fetchSectionData('softSkills', 'softSkills')
    ]);

    const skillsInputGroups = skillsData.map(skill => ({
      id: generateUniqueId(),
      isExpanded: false,
      Showlevel: true,
      inputs: [
        {
          inputKey: "skill",
          inputLabel: "Skill",
          inputType: "text",
          placeholder: "Enter a skill",
          show: true,
          required: true,
          inputValue: skill.skill
        },
        {
          inputKey: "level",
          inputLabel: "Level",
          inputType: "dropdown",
          placeholder: "Select skill level",
          show: true,
          required: true,
          inputValue: skill.level,
          options: [
            "Beginner",
            "Moderate",
            "Good",
            "Very good",
            "Excellent"
          ]
        }
      ]
    }));
   
    const educationInputGroups = educationData.map(education => ({
      id: generateUniqueId(),
      isExpanded: true,
      aiWriter: {
        isStatus: true,
        actions: [
          { id: "generate" },
          { id: "rewrite" }
        ]
      },
      inputs: [
        {
          inputKey: "courseName",
          inputLabel: "Course Name",
          inputType: "text",
          placeholder: "Enter your course name",
          show: true,
          required: true,
          inputValue: education.courseName
        },
        {
          inputKey: "collegeName",
          inputLabel: "School/College Name",
          inputType: "text",
          placeholder: "Enter the your school/college name",
          show: true,
          required: true,
          inputValue: education.collegeName
        },
        {
          inputKey: "startDate",
          inputLabel: "Start Date",
          inputType: "daterange",
          placeholder: "Enter start month/year",
          show: true,
          required: true,
          inputValue: education.startDate
        },
        {
          inputKey: "endDate",
          inputLabel: "End Date",
          inputType: "daterange",
          placeholder: "Enter end month/year or select 'Present'",
          show: true,
          required: !education.present,
          disabled: !!education.present,
          inputValue: education.endDate
        },
        {
          inputKey: "present",
          inputLabel: "Present",
          inputType: "checkbox",
          placeholder: "",
          show: true,
          required: false,
          inputValue: education.present
        },
        {
          inputKey: "description",
          inputLabel: "Description",
          inputType: "textarea",
          placeholder: "Describe your studies, achievements, etc.",
          show: true,
          required: false,
          inputValue: education.description,
          aiWriter: {
            isStatus: true,
            actions: [
              { id: "generate" },
              { id: "rewrite" }
            ]
          }
        }
      ]
    }));
             
    const workExperienceInputGroups = workExperience.map(work => ({
      id: generateUniqueId(),
      isExpanded: true,
      aiWriter: {
        isStatus: true,
        actions: [
          { id: "generate" },
          { id: "rewrite" }
        ]
      },
      inputs: [
        {
          inputKey: "position",
          inputLabel: "Position",
          inputType: "text",
          placeholder: "Enter your position",
          show: true,
          required: true,
          inputValue: work.position
        },
        {
          inputKey: "companyName",
          inputLabel: "Company Name",
          inputType: "text",
          placeholder: "Enter your company's name",
          show: true,
          required: true,
          inputValue: work.companyName
        },
        {
          inputKey: "startDate",
          inputLabel: "Start Date",
          inputType: "daterange",
          placeholder: "Enter start month/year",
          show: true,
          required: true,
          inputValue: work.startDate
        },
        {
          inputKey: "endDate",
          inputLabel: "End Date",
          inputType: "daterange",
          placeholder: "Enter end month/year or select 'Present'",
          show: true,
          required: !work.present,
          disabled: !!work.present,
          inputValue: work.endDate
        },
        {
          inputKey: "present",
          inputLabel: "Present",
          inputType: "checkbox",
          placeholder: "",
          show: true,
          required: false,
          inputValue: work.present ? "true" : ""
        },
        {
          inputKey: "workSummary",
          inputLabel: "Work Summary",
          inputType: "textarea",
          placeholder: "Describe your responsibilities, achievements, etc.",
          show: true,
          required: false,
          inputValue: work.workSummary,
          aiWriter: {
            isStatus: true,
            actions: [
              { id: "generate" },
              { id: "rewrite" }
            ]
          }
        }
      ]
    }));
        
    const professionalSummaryInputGroups = [
      {
        id: "oz9yyt831",
        isExpanded: true,
        aiWriter: {
          isStatus: true,
          actions: [
            {
              id: "generate"
            },
            {
              id: "rewrite"
            }
          ]
        },
        inputs: [
          {
            inputKey: "summary",
            inputLabel: "Summary",
            inputType: "textarea",
            placeholder: "Enter a concise summary of your professional background, key achievements, and career goals.",
            show: true,
            required: true,
            inputValue: `${professionalSummary.summary}`
          }
        ]
      }
    ];
    
    const achievementsInputGroups = [{
      id: generateUniqueId(),
      isExpanded: true,
      aiWriter: {
        isStatus: true,
        actions: [{ id: "rewrite" }],
      },
      inputs: [{
        inputKey: "achievements",
        inputLabel: "Achievements",
        inputType: "textarea",
        placeholder: "List your key achievements, e.g., 'Increased sales by 20% within the first year as Sales Manager.'",
        show: true,
        required: true,
        inputValue: `${achievements.content}` 
      }]
    }];

    const softSkillsInputGroups = softSkills.map(skill => ({
      id: generateUniqueId(),
      isExpanded: true,
      Showlevel: true,
      inputs: [
        {
          inputKey: "softSkill",
          inputLabel: "Soft Skill",
          inputType: "text",
          placeholder: "Enter a Soft skill",
          show: true,
          required: true,
          inputValue: skill.skill
        },
        {
          inputKey: "level",
          inputLabel: "Level",
          inputType: "dropdown",
          placeholder: "Select skill level",
          show: true,
          required: true,
          inputValue: skill.level,
          options: [
            "Beginner",
            "Moderate",
            "Good",
            "Very good",
            "Excellent"
          ]
        }
      ]
    }));

    const finalResumeData = {
      ...initialResumeData,
      formObject: {
        ...initialResumeData.formObject,
        skills: {
          ...initialResumeData.formObject.skills,
          inputGroups: skillsInputGroups,
          settings: initialResumeData.formObject.skills.settings.map(setting => 
            setting.inputKey === "skillStyle" ? { ...setting, inputValue: "dash-line" } : setting
          )
        },
        workExperience: {
          ...initialResumeData.formObject.workExperience,
          inputGroups: workExperienceInputGroups
        },
        professionalSummary: {
          ...initialResumeData.formObject.professionalSummary,
          inputGroups: professionalSummaryInputGroups
        },
        education: {
          ...initialResumeData.formObject.education,
          inputGroups: educationInputGroups
        },
        softSkills: {
          ...initialResumeData.formObject.softSkills,
          inputGroups: softSkillsInputGroups
        },
        // achievements: {
        //   ...initialResumeData.formObject.achievements,
        //   inputGroups: achievementsInputGroups
        // }
      }
    };
    const docRef = await addDoc(collection(db, "resumes"), finalResumeData);
    const resumeID = docRef.id;
    await setDoc(doc(db, "resumes", resumeID), {
      ...finalResumeData,
      metadata: {
        ...finalResumeData.metadata,
        resumeID: resumeID,
        fontSize:"L"
      }
    }, { merge: true });

    if (resumeID) {
      navigate(`/editor/resume/${resumeID}`);
    }
    
    return resumeID;
  } catch (error) {
    console.error('Error creating new resume with AI:', error);
    throw error;
  }
};
