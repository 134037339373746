import React, { createContext, useContext, useState, useEffect } from 'react';
import { app } from '../config/firebaseConfig';
import { getAuth, signInWithPopup, GoogleAuthProvider, onAuthStateChanged, signOut } from 'firebase/auth';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const auth = getAuth(app);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [temporaryUser, setTemporaryUser] = useState(null); // Add temporary user state

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    }, (error) => {
      console.error("Error with auth state change: ", error);
    });
    return () => {
      unsubscribe();
    };
  }, [auth]);

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider);
  };

  const logout = async () => {
    await signOut(auth);
    setUser(null);
    setTemporaryUser(null); // Reset temporary user on logout
    localStorage.removeItem('selectedUserId'); // Remove user ID from local storage
  };

  const setTempUser = (tempUser) => {
    setTemporaryUser(tempUser);
  };

  const value = {
    user: temporaryUser || user, // Use temporary user if set
    loading,
    signInWithGoogle,
    logout,
    setTempUser,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
