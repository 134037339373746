import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
// Import the locales you need
import { enUS, es, fr, de, it, nl, pt, pl, cs, ja } from 'date-fns/locale';

// Mapping from locale string to the corresponding date-fns locale object
const localeMap = {
  en: enUS,
  es: es,
  mx: es,
  fr: fr,
  de: de,
  it: it,
  nl: nl,
  pt: pt,
  br: pt,
  pl: pl,
  cz: cs,
  jp: ja,
};

const MonthYearDatePicker = ({ selectedDate, onDateChange, disabled, locale }) => {
  const [formattedDate, setFormattedDate] = useState('');

  useEffect(() => {
    if (selectedDate) {
      // Use the passed locale prop to select the correct locale object
      const localeObject = localeMap[locale] || enUS; // Default to enUS if the locale is not found
    //  const formatted = format(selectedDate, 'MMM yyyy', { locale: localeObject });
      setFormattedDate(selectedDate);
    }
  }, [selectedDate, locale]);

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <button
      onClick={!disabled ? onClick : undefined}
      ref={ref}
      disabled={disabled}
      className={`bg-gray-50 border ${disabled ? 'border-gray-200 text-gray-400' : 'border-gray-300 text-gray-900'} text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 cursor-${disabled ? 'not-allowed' : 'pointer'} ${!value ? 'placeholder-class' : ''}`}
    >
      {value || "MMM yyyy"}
    </button>
  ));

  return (
    <div className="flex flex-col space-y-4">
      <div className="start-date-picker">
        <DatePicker
          selected={selectedDate}
          onChange={date => {
            const timestamp = date.getTime();
            onDateChange(timestamp);
          }}
          dateFormat="MMM yyyy"
          showMonthYearPicker
          customInput={<CustomInput value={formattedDate} onClick={() => {}} />}
          disabled={disabled}
          locale={localeMap[locale] || enUS} // Dynamically set the locale
        />
      </div>
    </div>
  );
};

export default MonthYearDatePicker;
