// src/pages/VerifyEmail.js
import React from 'react';

const VerifyEmail = () => {
  return (
    <div>
      <h1>Verify Email</h1>
    </div>
  );
};

export default VerifyEmail;