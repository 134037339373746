//components/Resume/ResumeForm.js
import React, { useEffect, useState, useRef, createRef } from 'react';
import dragula from 'react-dragula';
import 'dragula/dist/dragula.min.css';
import ReactQuill from 'react-quill';  
import 'react-quill/dist/quill.snow.css';  
import { storage } from "../../config/firebaseConfig";
import { saveResume, fetchResumeById } from '../../services/resumeService';
import { generateAIContent } from '../../services/aiService'; 
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { useResume } from '../../context/ResumeContext'; 
import { TbX } from "react-icons/tb";
import { Link, useNavigate } from 'react-router-dom';
import LanguageSelector from '../LanguageSelector'; 
import ResumeModal from '../../modals/ResumeModal';   
import CustomSectionForm from '../../modals/Custom'; 
import Modal from '../Modal';
import ModalComponent from '../ModalComponent';
import PhotoUploadModal from '../PhotoUploadModal';
import { useAuth } from '../../context/AuthContext';  
import AiPopover from '../AiPopover'; 

import { format } from 'date-fns';
import MonthYearDatePicker from '../../shared/MonthYearDatePicker'; 
import { LiaTrashSolid, LiaAngleDownSolid, LiaAngleUpSolid, LiaPlusSolid } from "react-icons/lia"; 
import { BiUserCircle,BiGridVertical, BiWindowClose,BsArrowRepeat, BiX, BiShow, BiHide,BiTrash,BiMove, BiTransfer, BiRefresh,BiCloudUpload } from "react-icons/bi";
import { MdMoreVert } from "react-icons/md"; 
import ResumePreview from './ResumePreview';
import Dropdown from './Dropdown';
import LoadingSpinner from '../LoadingSpinner';
import { BsStars } from "react-icons/bs";
import { BiHome, BiEdit } from 'react-icons/bi';

// Importing images directly
import cvdesignerLogo from '../../images/cvdesigner-icon.png';
import resumeDesignLogo from '../../images/resumedesign-icon.png';

const generateUniqueId = () => `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;

const ResumeForm = ({ resumeID, fetchedSections  }) => { 
  const { presentStyle } = useResume();
  const { isSingleLayout, setIsSingleLayout } = useResume();
  const { setIsPhotoThere } = useResume();
  const { currentLanguage, setCurrentLanguage } = useResume();

  const { user, loading } = useAuth();
  const navigate = useNavigate();
  const [resumeData, setResumeData] = useState(null); // Adjusted to hold the fetched resume data
  const [sections, setSections] = useState({});
  const [userId, setUserId] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState({ type: '', sectionKey: '', groupId: '' });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [draggedItem, setDraggedItem] = useState(null);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [currentResumeId, setCurrentResumeId] = useState(resumeID); 
  const [isPhotoUploading, setIsPhotoUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(true); 
  const [aiLoading, setAiLoading] = useState(false);
  const [selectedStyle, setSelectedStyle] = useState();
  const [resumeName, setResumeName] = useState();


  const [isAIWriterModalOpen, setIsAIWriterModalOpen] = useState(false);
  const [currentSectionForAI, setCurrentSectionForAI] = useState(null);

  const [showPopover, setShowPopover] = useState({});
  const [activeAIWriter, setActiveAIWriter] = useState({sectionKey: null, groupId: null});
  const [modalContent, setModalContent] = useState({
    title: "",
    description: "",
    inputPlaceholder: "",
    inputValue: "",
    inputObj:{format:""}
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownVisibility, setDropdownVisibility] = useState({});
  const [selectedFormat, setSelectedFormat] = useState('list'); // Default value
  const [isPhotoVisible, setIsPhotoVisible] = useState(); // State to toggle photo visibility
  const [showTemplateCollection, setShowTemplateCollection] = useState(false);

  // const [currentLanguage, setCurrentLanguage] = useState('en');
  const [showForm, setShowForm] = useState(true); // State to manage form visibility

  const [showingTemplates, setShowingTemplates] = useState(false);
  const handleUpdateResumeData = (updatedData) => {
      // Update the resumeID in the metadata object
      const updatedResumeData = {
          ...updatedData,
          metadata: {
              ...updatedData.metadata,
              resumeID: resumeID
          }
      };
      // Set the updated resume data
      setResumeData(updatedResumeData);
  };
  const handleToggleTemplates = (value) => {
    setShowingTemplates(value);
  };

  const toggleFormVisibility = () => {
    setShowForm(!showForm);
  };

  // function animateTextUpdate({
  //   getSections, 
  //   setSections, 
  //   sectionKey, 
  //   groupId, 
  //   inputType, 
  //   newText, 
  //   action = "append", 
  //   delay = 100
  // }) {
  //   // Fetch the current state of the sections.
  //   const sections = getSections();
  //   const section = sections[sectionKey];
  //   const group = section.inputGroups.find(g => g.id === groupId);
  //   const input = group.inputs.find(i => i.inputType === inputType);
  
  //   // Determine the starting content based on the action.
  //   let existingContent = input.inputValue;
  
  //   // Prepare new content, ensuring proper HTML wrapping.
  //   const isList = newText.trim().startsWith('-');
  //   let newContentFormatted = isList
  //     ? `<ul>${newText.trim().split("\n").map(line => `<li>${line.substring(1).trim()}</li>`).join("")}</ul>`
  //     : newText.trim().split("\n").map(line => `<p>${line.trim()}</p>`).join("");
  
  //   // Ensure not to wrap with extra <p> or <ul> tags if existingContent is empty or only contains whitespace HTML.
  //   if (!existingContent.trim() || /^(<br\s*\/?>|\s)*$/.test(existingContent)) {
  //     existingContent = "";
  //   }
  
  //   // Append or replace existingContent with newContentFormatted based on the action.
  //   const fullContent = action === "append" ? existingContent + newContentFormatted : newContentFormatted;
  
  //   // Immediately update the input value with fullContent.
  //   setSections(prevSections => ({
  //     ...prevSections,
  //     [sectionKey]: {
  //       ...prevSections[sectionKey],
  //       inputGroups: prevSections[sectionKey].inputGroups.map(g => g.id === groupId
  //         ? {
  //             ...g,
  //             inputs: g.inputs.map(i => i.inputType === inputType ? { ...i, inputValue: fullContent } : i)
  //           }
  //         : g)
  //     }
  //   }));
  // }

  const handleLanguageChange = (countryCode) => {
    setCurrentLanguage(countryCode);
    // Convert countryCode to your language codes if necessary
  };
  
  const toggleDropdown = (sectionKey) => {
    setDropdownVisibility(prevState => ({
      ...prevState,
      [sectionKey]: !prevState[sectionKey] // Toggle the current state
    }));
  };

  function animateTextUpdate({
    getSections, 
    setSections, 
    sectionKey, 
    groupId, 
    inputType, 
    newText, 
    action = "append", 
    delay = 100
  }) {
    const sections = getSections();
    const section = sections[sectionKey];
    const group = section.inputGroups.find(g => g.id === groupId);
    const input = group.inputs.find(i => i.inputType === inputType);
  
    // Function to remove empty HTML and whitespace.
    const cleanUpContent = (html) => {
      const div = document.createElement('div');
      div.innerHTML = html;
      // Remove empty elements
      Array.from(div.querySelectorAll('*')).forEach(el => {
        if (el.innerHTML === '' || el.innerHTML === '<br>') {
          el.parentNode.removeChild(el);
        }
      });
      return div.innerHTML.trim();
    };
  
    // Clean existing content if it's effectively empty.
    let existingContent = cleanUpContent(input.inputValue);
  
    // Determine if new content is a list and format accordingly.
    const isList = newText.trim().startsWith('-');
    const newContentFormatted = isList
      ? `<ul>${newText.trim().split("\n").map(line => `<li>${line.substring(1).trim()}</li>`).join("")}</ul>`
      : newText.trim().split("\n").map(line => `<p>${line.trim()}</p>`).join("");
  
    // Append or replace existingContent with newContentFormatted based on the action.
    const fullContent = action === "append" && existingContent
      ? existingContent + newContentFormatted
      : newContentFormatted;
  
    // Update the sections with the new content.
    setSections(prevSections => ({
      ...prevSections,
      [sectionKey]: {
        ...prevSections[sectionKey],
        inputGroups: prevSections[sectionKey].inputGroups.map(g => g.id === groupId
          ? {
              ...g,
              inputs: g.inputs.map(i => i.inputType === inputType ? { ...i, inputValue: fullContent } : i)
            }
          : g)
      }
    }));
  }
 
  const updateSkillsSectionWithAIGeneratedContent = async (aiGeneratedSkillsResponse, sectionType) => {
    // Check if the response is in the expected format
    if (typeof aiGeneratedSkillsResponse === 'object' && aiGeneratedSkillsResponse.hasOwnProperty('generated_text')) {
        const aiGeneratedSkillsText = aiGeneratedSkillsResponse.generated_text;

        // Extract skill names from the generated text
        const skillsArray = aiGeneratedSkillsText.split('\n').map(skill => {
            const skillName = skill.substring(skill.indexOf('. ') + 2);
            return skillName;
        });

        // Assume `sections` is the state variable holding all sections, including the skills section
        let skillsSection = sections['skills']; // Adjust the key as necessary to match your skills section identifier

        if (!skillsSection) {
            console.log("Skills section not found");
            return;
        }

        let inputKeyType = 'skill';
        if(sectionType == 'softSkills'){
          inputKeyType = 'softSkill';
          skillsSection = sections['softSkills'];
        }
       
        // Add each skill as a new input group to the skills section
        skillsArray.forEach(skillName => {
            // Construct the new input group for the skill
            const skillInputGroup = {
                id: `${Math.random().toString(36).substr(2, 9)}`, 
                isExpanded: false,
                Showlevel: true, 
                inputs: [
                    {
                        inputKey: inputKeyType,
                        inputLabel: "Skill",
                        inputType: "text",
                        placeholder: "Enter a skill",
                        show: true,
                        required: true,
                        inputValue: skillName
                    },
                    {
                        inputKey: "level",
                        inputLabel: "Level",
                        inputType: "dropdown",
                        placeholder: "Select skill level",
                        show: true,
                        required: true,
                        inputValue: "Very good", 
                        options: ["Beginner", "Moderate", "Good", "Very good", "Excellent"]
                    }
                ]
            };

            // Add the new input group to the skills section
            skillsSection.inputGroups.push(skillInputGroup);
        });

        // Update the sections state with the modified skills section
        // Use a functional update to ensure we're working with the most current state
        setSections(prevSections => ({
            ...prevSections,
            [sectionType]: skillsSection // Update the skills section ['skills']
        }));
        setAiLoading(false);  
    } else {
        setAiLoading(false);  
        console.error("Unexpected response format from generateAIContent", aiGeneratedSkillsResponse);
    }
};


  const handleGenerateNew = async (inputVal, inputObj) => {   
    // console.log(inputObj)  
    // console.log(inputVal)
    if((inputObj.sectionKey == 'skills' || inputObj.sectionKey == 'softSkills') && inputVal){
     setAiLoading(true);  
     const context= inputVal;
     const isType = 'generate';
     const sectionType = inputObj.sectionKey;
     const format = inputObj.format;   
     const coverPoints = inputObj.coverPoints;
     const aiGeneratedSkillsText  = await generateAIContent(context, isType, sectionType, format, coverPoints, currentLanguage); 
     console.log(aiGeneratedSkillsText) 
     updateSkillsSectionWithAIGeneratedContent(aiGeneratedSkillsText, sectionType);
      return
    }
  
    
    const { actionId, sectionKey, groupId, format, coverPoints } = inputObj;
    const section = sections[sectionKey];
  //  console.log(section)
    const inputGroup = groupId ? section.inputGroups.find(group => group.id === groupId) : null;
    if (inputGroup && inputGroup.aiWriter.isStatus) {
      try {
        setAiLoading(true);  
        const context = inputVal; 
        const isType = actionId;
        const aiContent = await generateAIContent(context, isType, section.sectionType, format, coverPoints, currentLanguage);    
        // animateTextUpdate({
        //   getSections: () => sections,
        //   setSections: setSections,
        //   sectionKey: sectionKey,
        //   groupId: groupId,
        //   inputType: "textarea",
        //   text: aiContent.generated_text,
        //   delay: 100
        // });
        animateTextUpdate({
          getSections: () => sections,
          setSections: setSections,
          sectionKey: sectionKey,
          groupId: groupId,
          inputType: "textarea",
          newText: aiContent.generated_text,
          action: "append",
          delay: 300
        });
        
        setAiLoading(false);
      } catch (error) {
        console.error('Failed to generate AI content:', error);
        setAiLoading(false); 
      }
    }
  }
   const handleAIWriterAction = async (actionId, sectionKey, groupId) => {
    console.log(actionId)
    console.log(sectionKey)
    console.log(groupId)
    const section = sections[sectionKey];
    const inputGroup = groupId ? section.inputGroups.find(group => group.id === groupId) : null;

    if (actionId === "rewrite") {
      if (inputGroup && inputGroup.aiWriter.isStatus) {
        try {
          setAiLoading(true);  
          const context = inputGroup.inputs.find(input => input.inputType === "textarea").inputValue; 
          const isType = actionId;
          const coverPoints = null;
          const aiContent = await generateAIContent(context, isType, section.sectionType, format, coverPoints, currentLanguage);    
          // animateTextUpdate({
          //   getSections: () => sections,
          //   setSections: setSections,
          //   sectionKey: sectionKey,
          //   groupId: groupId,
          //   inputType: "textarea",
          //   text: aiContent.generated_text,
          //   delay: 100
          // });
          animateTextUpdate({
            getSections: () => sections,
            setSections: setSections,
            sectionKey: sectionKey,
            groupId: groupId,
            inputType: "textarea",
            newText: aiContent.generated_text,
            action: "replace", 
            delay: 300
          });
          setAiLoading(false);
        } catch (error) {
          console.error('Failed to generate AI content:', error);
          setAiLoading(false); 
        }
      }
      return;
    }
  
    switch (section.sectionType) {
      case "summary":
         // Finding 'personal' section by iterating over object values
         const personalSection = Object.values(sections).find(s => s.sectionType === "personal");      
         // Find the 'Job Title' input within the 'personal' section
         const jobTitleInput = personalSection?.inputGroups?.flatMap(group => group.inputs).find(input => input.inputKey === "jobTitle");       
         const hasJobTitle = jobTitleInput && jobTitleInput.inputValue;       
         // Adjust modal content based on whether the job title is present
         if (hasJobTitle) {
           // Job title is present, ask for confirmation
           setModalContent({
             title: "Is this your job title?",
             description: "Did we identify your job title correctly? If not, change it to the job title for which you'd like to generate tailored content.",
             inputPlaceholder: "Enter job title here",
             inputValue: jobTitleInput.inputValue,
             inputObj: {actionId, sectionKey, groupId}
           });
         } else {
           // Job title is not present, prompt for input
           setModalContent({
             title: "What is your job title?",
             description: "Enter your job title, and we will generate tailored content for that job title with the help of AI.",
             inputPlaceholder: "Enter job title here",
             inputValue: "",
             inputObj: {actionId, sectionKey, groupId}
           });
         } 
        break;
        case "skills":
          // Finding 'personal' section by iterating over object values
          const skillSection = Object.values(sections).find(s => s.sectionType === "personal");      
          // Find the 'Job Title' input within the 'personal' section
          const jobTitleInput2 = skillSection?.inputGroups?.flatMap(group => group.inputs).find(input => input.inputKey === "jobTitle");       
          const hasJobTitle2 = jobTitleInput2 && jobTitleInput2.inputValue;       
          // Adjust modal content based on whether the job title is present
          if (hasJobTitle2) {
            // Job title is present, ask for confirmation
            setModalContent({
              title: "Is this your job title?",
              description: "Did we identify your job title correctly? If not, change it to the job title for which you'd like to generate tailored content.",
              inputPlaceholder: "Enter job title here",
              inputValue: jobTitleInput2.inputValue,
              inputObj: {actionId, sectionKey, groupId}
            });
          } else {
            // Job title is not present, prompt for input
            setModalContent({
              title: "What is your job title?",
              description: "Enter your job title, and we will generate tailored content for that job title with the help of AI.",
              inputPlaceholder: "Enter job title here",
              inputValue: "",
              inputObj: {actionId, sectionKey, groupId}
            });
          } 
         break;  
         case "softSkills":
          // Finding 'personal' section by iterating over object values
          const skillSection1 = Object.values(sections).find(s => s.sectionType === "personal");      
          // Find the 'Job Title' input within the 'personal' section
          const jobTitleInput22 = skillSection1?.inputGroups?.flatMap(group => group.inputs).find(input => input.inputKey === "jobTitle");       
          const hasJobTitle22 = jobTitleInput22 && jobTitleInput22.inputValue;       
          // Adjust modal content based on whether the job title is present
          if (hasJobTitle22) {
            // Job title is present, ask for confirmation
            setModalContent({
              title: "Is this your job title?",
              description: "Did we identify your job title correctly? If not, change it to the job title for which you'd like to generate tailored content.",
              inputPlaceholder: "Enter job title here",
              inputValue: jobTitleInput22.inputValue,
              inputObj: {actionId, sectionKey, groupId}
            });
          } else {
            // Job title is not present, prompt for input
            setModalContent({
              title: "What is your job title?",
              description: "Enter your job title, and we will generate tailored content for that job title with the help of AI.",
              inputPlaceholder: "Enter job title here",
              inputValue: "",
              inputObj: {actionId, sectionKey, groupId}
            });
          } 
         break; 
      case "work":
        const positionInput = inputGroup ? inputGroup.inputs.find(input => input.inputKey === "position") : null;      
        if(positionInput && positionInput.inputValue) {
          // If "Position" has data
          setModalContent({
            title: "Is this your job Position?",
            description: "Did we identify your job Position correctly? If not, change it to the job Position for which you'd like to generate tailored content.",
            inputPlaceholder: "Enter job Position here",
            inputValue: positionInput.inputValue,
            inputObj: {actionId, sectionKey, groupId}
          });
        } else {
          // If "Position" is empty
          setModalContent({
            title: "What is your Position?",
            description: "Enter your position and we will generate tailored content for that job Position with the help of AI.",
            inputPlaceholder: "Enter job Position here",
            inputValue: "",
            inputObj: {actionId, sectionKey, groupId}
          });
        }
        break;
      case "education":
        const CourseInput = inputGroup ? inputGroup.inputs.find(input => input.inputKey === "courseName") : null;
      
        if(CourseInput && CourseInput.inputValue) {
          // If "Position" has data
          setModalContent({
            title: "Is this your education?",
            description: "Did we identify your Course correctly? If not, change it to the Course for which you'd like to generate tailored content.",
            inputPlaceholder: "Enter Education Course here",
            inputValue: CourseInput.inputValue,
            inputObj: {actionId, sectionKey, groupId}
          });
        } else {
          // If "Position" is empty
          setModalContent({
            title: "What is your education?",
            description: "Enter your Course and we will generate tailored content for that Course with the help of AI.",
            inputPlaceholder: "Enter Education Course here",
            inputValue: "",
            inputObj: {actionId, sectionKey, groupId}
          });
        }
        break;
        case "volunteer":
        const VolunteerInput = inputGroup ? inputGroup.inputs.find(input => input.inputKey === "role") : null;
      
        if(VolunteerInput && VolunteerInput.inputValue) {
          // If "Position" has data
          setModalContent({
            title: "Is this your role?",
            description: "Did we identify your role correctly? If not, change it to the role for which you'd like to generate tailored content.",
            inputPlaceholder: "Enter volunteer role here",
            inputValue: VolunteerInput.inputValue,
            inputObj: {actionId, sectionKey, groupId}
          });
        } else {
          // If "Position" is empty
          setModalContent({
            title: "What is your role?",
            description: "Enter your role and we will generate tailored content for that role with the help of AI.",
            inputPlaceholder: "Enter volunteer role here",
            inputValue: "",
            inputObj: {actionId, sectionKey, groupId}
          });
        }
        break;
        case "internship":
          const positionInput2 = inputGroup ? inputGroup.inputs.find(input => input.inputKey === "position") : null;
      
          if(positionInput2 && positionInput2.inputValue) {
            // If "Position" has data
            setModalContent({
              title: "Is this your job Position?",
              description: "Did we identify your job Position correctly? If not, change it to the job Position for which you'd like to generate tailored content.",
              inputPlaceholder: "Enter job Position here",
              inputValue: positionInput2.inputValue,
              inputObj: {actionId, sectionKey, groupId}
            });
          } else {
            // If "Position" is empty
            setModalContent({
              title: "What is your Position?",
              description: "Enter your position and we will generate tailored content for that job Position with the help of AI.",
              inputPlaceholder: "Enter job Position here",
              inputValue: "",
              inputObj: {actionId, sectionKey, groupId}
            });
          }
        break;
      // Include other cases as needed
    }
    
    setIsAIWriterModalOpen(true); // Open the modal with the content set above
  };
 
  const togglePopoverVisibility = (sectionKey, groupId) => {
    setShowPopover(prevState => ({
      ...prevState,
      [`${sectionKey}-${groupId}`]: !prevState[`${sectionKey}-${groupId}`]
    }));
  };
  // On First Page Load..
  useEffect(() => {
    if (!loading && !user) {
      navigate('/login');
    } else if (user && resumeID) {
      setUserId(user.uid);
      const loadData = async () => {
        try {
          const fetchedData = await fetchResumeById(resumeID);
          if (fetchedData) { // Assuming this fetches the entire resume data structure
            setSections(fetchedData.formObject || {}); // Directly setting sections based on fetched data   
            setResumeData(fetchedData);
            setSelectedStyle(fetchedData.metadata.selectedStyle)
            setCurrentLanguage(fetchedData.metadata.selectedLanguage)
            setResumeName(fetchedData.metadata.templateName)
            setIsSingleLayout(fetchedData.metadata.isSingleLayout); // Ensure `isSingleLayout` is set correctly
            const photoInput = fetchedData.formObject.personalDetails.inputGroups[0].inputs.find(input => input.inputKey === "photo");            
              setIsPhotoVisible(photoInput.settings.photoVisible)
              if (photoInput && photoInput.inputValue) {
                setIsPhotoThere(true);
              } else {
                setIsPhotoThere(false);
              }

            //sam added this for section reordering
            if (fetchedData && fetchedData.formObject && fetchedData.formOrder) {
              // Call reorderSections and update your state with the ordered sections
              const ordered = reorderSections(fetchedData.formObject, fetchedData.formOrder);
              setSections(ordered);
            }//end

          }
        } catch (error) {
          console.error("Error fetching resume data:", error);
          // Handle error appropriately
        } finally {
          setIsLoading(false); // Hide loader when data is fetched or in case of an error
        }
      };
      loadData();
    }
  }, [user, loading, resumeID, navigate, setIsSingleLayout]);

// Modify handleRemoveInputGroup and handleRemoveCustomSection to show confirmation modal
const prepareRemoveInputGroup = (sectionKey, groupId) => {
  setItemToDelete({ type: 'inputGroup', sectionKey, groupId });
  setIsConfirmModalOpen(true);
};

const prepareRemoveCustomSection = (sectionKey) => {
  setItemToDelete({ type: 'customSection', sectionKey });
  setIsConfirmModalOpen(true);
};

const reorderSections = (formObject, formOrder) => {
  const orderedSections = {};
  if (Array.isArray(formOrder)) {
    formOrder.forEach(sectionKey => {
      if (formObject[sectionKey]) {
        orderedSections[sectionKey] = formObject[sectionKey];
      }
    });
  } else {
    console.error('formOrder is not an array:', formOrder);
  }
  return orderedSections;
};

// useEffect(() => { 
//   if (resumeData && resumeData.formObject && ResumeModal.formOrder) {
//     const ordered = reorderSections(resumeData.formObject, ResumeModal.formOrder);
//     setSections(ordered);
//   }
// }, [resumeData, ResumeModal.formOrder]);


// Implement the confirm deletion logic
const confirmDelete = () => {
  if (itemToDelete.type === 'inputGroup') {
    handleRemoveInputGroup(itemToDelete.sectionKey, itemToDelete.groupId);
  } else if (itemToDelete.type === 'customSection') {
    handleRemoveCustomSection(itemToDelete.sectionKey);
  }
  closeConfirmModal();
};

// Implement the cancel deletion logic
const cancelDelete = () => {
  closeConfirmModal();
};

// Helper to close confirmation modal
const closeConfirmModal = () => {
  setIsConfirmModalOpen(false);
  setItemToDelete({ type: '', sectionKey: '', groupId: '' });
};

// Toggle the expand/collapse state of a section
const toggleSection = (sectionId) => {
  setSections(prevSections => {
    const isCurrentlyExpanded = prevSections[sectionId].isExpanded;
    let shouldScroll = false;

    const updatedSections = Object.keys(prevSections).reduce((acc, key) => {
      const isTargetSection = key === sectionId;
      const isExpanded = isTargetSection ? !isCurrentlyExpanded : false;
      
      acc[key] = {
        ...prevSections[key],
        isExpanded: isExpanded,
      };

      // Determine if we need to scroll
      if (isTargetSection && !isCurrentlyExpanded) {
        shouldScroll = true;
      }

      return acc;
    }, {});

    if (shouldScroll) {
      // Use a timeout to ensure the DOM updates before scrolling
      setTimeout(() => scrollToSection(sectionId), 0);
    }

    return updatedSections;
  });
};

  // Toggle the expand/collapse state of a section
// const toggleSection = (sectionId) => {
//   setSections(prevSections => ({
//     ...prevSections,
//     [sectionId]: {
//       ...prevSections[sectionId],
//       isExpanded: !prevSections[sectionId].isExpanded,
//     }
//   }));
// };
 
const toggleInputGroup = (sectionKey, inputGroupId) => {
  setSections(prevSections => ({
    ...prevSections,
    [sectionKey]: {
      ...prevSections[sectionKey],
      inputGroups: prevSections[sectionKey].inputGroups.map(inputGroup => 
        inputGroup.id === inputGroupId ? { ...inputGroup, isExpanded: !inputGroup.isExpanded } : inputGroup
      )
    }
  }));
};


const handleAddInputGroup = (sectionKey) => {
  const newSection = { ...sections[sectionKey] };
  const templateInputGroup = ResumeModal.formObject[sectionKey].inputGroups[0]; // Use the template from the original model

  const newInputGroup = JSON.parse(JSON.stringify(templateInputGroup));
  newInputGroup.id = generateUniqueId();
  newInputGroup.isExpanded = true; // Ensure the new group is expanded

  newInputGroup.inputs.forEach(input => {
    input.inputValue = input.inputType === 'checkbox' ? false : '';
  });

  // Collapse all other inputGroups in this section
  newSection.inputGroups.forEach(group => {
    group.isExpanded = false; // Collapse each group
  });

  newSection.inputGroups.push(newInputGroup);

  setSections(prev => ({
    ...prev,
    [sectionKey]: newSection
  }));
};

const togglePhotoVisibility = () => {
  let updatedVisible = null; // Define outside to use in both setSections and setIsPhotoVisible

  setSections(prevSections => {
    const newSections = { ...prevSections };
    const personalDetails = newSections.personalDetails;

    personalDetails.inputGroups.forEach(group => {
      group.inputs.forEach(input => {
        if (input.inputKey === 'photo') {
          if (updatedVisible === null) { // Only update if not already set
            updatedVisible = !input.settings.photoVisible;
          }
          input.settings.photoVisible = updatedVisible;
        }
      });
    });

    return newSections;
  });

  // Update UI visibility based on the last value set in the loop
  setIsPhotoVisible(prev => {
    if (prev === updatedVisible) {
      // If no change is to be made, do not update state
      return prev;
    }
    //console.log(`Updating isPhotoVisible from: ${prev} to: ${updatedVisible}`);
    return updatedVisible;
  });
};


 
  const handleRemoveInputGroup = (sectionKey, groupId) => {
    const newSection = { ...sections[sectionKey] };
    newSection.inputGroups = newSection.inputGroups.filter(group => group.id !== groupId);
    setSections(prev => ({
      ...prev,
      [sectionKey]: newSection
    }));
  };

  const handleInputChange = (sectionKey, groupId, inputIndex, value) => {
    const newSections = { ...sections };
    const section = newSections[sectionKey];
    const group = section.inputGroups.find(g => g.id === groupId);
    if (group) {
      const input = group.inputs[inputIndex];
      //console.log(value)
      // Update the input value
      input.inputValue = value;
  
      // Special handling for the "Present" checkbox
      if (input.inputKey === 'present') {
        // Find the "End Date" input in the same group
        const endDateInput = group.inputs.find(i => i.inputKey === 'endDate');
        if (endDateInput) {
          // Disable or enable the "End Date" input based on the "Present" checkbox
          endDateInput.disabled = !!value; // Convert to boolean in case 'value' is not already
        }
      }
  
      setSections(newSections);
    }
  };
  

  const renderInput = (input, sectionKey, groupId, inputIndex) => {
    let inputComponent;
  
    switch (input.inputType) {
      case 'text':
      case 'email':
      case 'date':
        inputComponent = (
          <input
            type={input.inputType}
            placeholder={input.placeholder}
            required={input.required}
            value={input.inputValue}
            onChange={(e) => handleInputChange(sectionKey, groupId, inputIndex, e.target.value)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 focus:outline-none focus:ring-2 focus:ring-blue-100"
          />
        );
        break;
        case 'daterange':
          const isDisabled = input.disabled;  
          //console.log(input.inputValue)       
          const convertToDate = (inputValue) => {
            if (inputValue && typeof inputValue === 'object' && inputValue.seconds !== undefined) {
              // Convert Firestore Timestamp to JavaScript Date
              return new Date(inputValue.seconds * 1000 + inputValue.nanoseconds / 1000000);
            } else if (typeof inputValue === 'number') {
              // Convert milliseconds to JavaScript Date
              return new Date(inputValue);
            }
            return null;
          };

          inputComponent = (
            <MonthYearDatePicker
               selectedDate={convertToDate(input.inputValue)}
               onDateChange={(date) => { 
                console.log(date);
                handleInputChange(sectionKey, groupId, inputIndex, date);
              }}
              locale={currentLanguage} 
              disabled={isDisabled} // Pass the disabled state to the component
            />
          ); 
        break;
       // Text Editor with AI Writer logic
       case 'textarea':
        const sectionValue = sections[sectionKey]; // Retrieve the current section's data
        const inputGroupAiWriterConfig = sectionValue.inputGroups.find(group => group.id === groupId)?.aiWriter;
        const aiWriterConfig = inputGroupAiWriterConfig?.isStatus ? inputGroupAiWriterConfig : sectionValue?.aiWriter;
      
        inputComponent = (
          <div>
            {/* Flex container for label and AI Writer button */}
            <div className="flex justify-between items-center mb-2">
              {input.inputLabel && (
                <label className="block text-gray-700 text-sm font-bold mb-1">
                  {input.inputLabel}
                </label>
              )}
              {aiWriterConfig && aiWriterConfig.isStatus && (
                <div className="ai-writer-footer relative">
                  {!aiLoading && 
                    <button
                      onClick={() => togglePopoverVisibility(sectionKey, groupId)}
                      className="ai-writer-btn"
                    >
                      <BsStars /> AI Writer
                    </button>
                  }
                  {aiLoading && 
                    <button type="button" className="flex gap-2 text-sm items-center justify-between align-center ai-writer-btn py-2 px-4 bg-blue-500 text-white rounded-3xl hover:bg-blue-700 transition-colors bg-gradient-to-r from-blue-600 to-violet-600 opacity-65">
                      <svg width="20" height="20" fill="currentColor" className="mr-1 animate-spin" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                        <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z">
                        </path>
                      </svg>
                      Generating..
                    </button>
                  }
      
                  {showPopover[`${sectionKey}-${groupId}`] && (
                    <AiPopover
                      isVisible={showPopover[`${sectionKey}-${groupId}`]}
                      onClose={() => togglePopoverVisibility(sectionKey, groupId)}
                      sectionKey={sectionKey}
                      inputGroupId={groupId}                     
                    >
                      {aiWriterConfig.actions.map((action, idx) => {
                        const currentInputGroup = sections[sectionKey].inputGroups.find(group => group.id === groupId);
                        const textareaInput = currentInputGroup.inputs.find(input => input.inputType === "textarea");
                        const textareaContentLength = textareaInput ? textareaInput.inputValue.trim().length : 0;
                        const isDisabled = action.id === 'rewrite' && (textareaContentLength < 50);
                        
                        return (
                          <div
                            key={idx}
                            className={`p-2 px-3 cursor-pointer ${isDisabled ? 'text-gray-400 cursor-not-allowed' : 'text-gray-500 hover:bg-gray-100 hover:rounded-md hover:text-blue-500'} focus:outline-none`}                        
                            onClick={() => {
                              if (!isDisabled) {
                                handleAIWriterAction(action.id, sectionKey, groupId);
                                togglePopoverVisibility(sectionKey, groupId);
                              }
                            }}
                          >
                            {action.id === 'generate' && 'Generate New'}
                            {action.id === 'rewrite' && (isDisabled ? 'Rewrite Section' : 'Rewrite Section')}
                          </div> 
                        );
                      })}
                    </AiPopover>
                  )}
                </div>
              )}
            </div>
      
            <ReactQuill
              theme="snow"
              value={input.inputValue}
              onChange={(content) => handleInputChange(sectionKey, groupId, inputIndex, content)}
              className="text-editor w-full"
            />
          </div>
        );
      
        return (
          <div className="col-span-2"> {/* Full width for text editor and AI Writer button */}
            {inputComponent}
          </div>
        );
      
      case 'checkbox':
        inputComponent = (
          <input
            type="checkbox"
            checked={input.inputValue}
            onChange={(e) => handleInputChange(sectionKey, groupId, inputIndex, e.target.checked)}
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
          />          
        );
        break;
      case 'dropdown':
        inputComponent = (
          <select
            required={input.required}
            value={input.inputValue}
            onChange={(e) => handleInputChange(sectionKey, groupId, inputIndex, e.target.value)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 focus:outline-none focus:ring-2 focus:ring-blue-100"
          >
            {input.options.map((option, idx) => (
              <option key={idx} value={option}>
                {option}
              </option>
            ))}
          </select>
        );
        break;
        case 'upload': // Photo upload case
        inputComponent = (
          <div>
            <div className='flex items-center'>
              <button
                className='actionButtons flex justify-between items-center py-2.5 px-5 text-sm font-medium bg-gray-50 border border-gray-300 text-gray-900 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-gray-200 mr-4'
                onClick={(event) => {
                  event.preventDefault();
                  setIsModalOpen(true);
                }}
              >
                <span className='mr-2 text-gray-500'><BiUserCircle /></span> <span>Add/Replace Photo</span>
              </button>
            
              <PhotoUploadModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSave={(croppedImageData) => handleImageSave(croppedImageData, sectionKey, groupId, inputIndex)}/>

                {/* <pre>{JSON.stringify(input, null, 2)}</pre> */}
            </div>
            {input.inputValue && !isPhotoUploading && (
              <div className='mt-2 flex items-center'>
              <div>
              <div className='relative mr-4 ml-1.5'>
                <img src={input.inputValue} alt="Uploaded" className='border border-gray-300 w-24 h-24 rounded-md shadow-sm'/>                
                {!isPhotoVisible && (
                  <div className="absolute rounded-md inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <BiHide className='text-white text-4xl'/>
                  </div>
                )}
                </div>
                <button
                    onClick={() => togglePhotoVisibility()}
                    className="actionButtons text-gray-400 flex items-center py-1 px-1 text-sm font-medium hover:text-gray-600 focus:outline-none"
                  >
                    {isPhotoVisible ? <><BiHide className='mr-2'/> Hide photo</> : <><BiShow className='mr-2'/> Show photo</>}
                  </button>
                </div>
              
              <button
                className='p-2 rounded-full bg-slate-100 text-gray-500 hover:bg-slate-200 focus:outline-none'
                onClick={(event) => {
                  event.preventDefault(); // Prevent the form from submitting
                  handleRemoveImage(sectionKey, groupId, inputIndex);
                }}
              >
                <LiaTrashSolid />
              </button>
          </div>
          
            )}
            {isPhotoUploading && (
              <div className='border mt-2 border-gray-300 flex items-center justify-center w-24 h-24 rounded-md shadow-sm mr-4'>                
              <LoadingSpinner displayType="inline" />
              </div>
            )}
          </div>
        );
        break;      
      default:
        return null;
    }
  
    return (
      <div className="col-span-1"> {/* Each input takes one column */}
        {inputComponent}
      </div>
    );
  };
  const handleRemoveImage = async (sectionKey, groupId, inputIndex) => {
    const imageUrl = sections[sectionKey].inputGroups.find(group => group.id === groupId).inputs[inputIndex].inputValue;
    
    if (!imageUrl) return; // If there's no image URL, just return
    
    try {
      // Create a reference to the file to delete from the URL
      const imageRef = ref(storage, imageUrl);
      
      // Delete the file
      await deleteObject(imageRef);
      setIsPhotoThere(false)  

      // Reset the input value to remove the image from the UI
      handleInputChange(sectionKey, groupId, inputIndex, '');
  
      // Optionally, reset any additional UI states if needed
      console.log("Image deleted successfully from Firebase Storage");
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };  
  
  const handleImageSave = async (croppedImageData, sectionKey, groupId, inputIndex) => {
    setIsPhotoUploading(true); // Start uploading
    const blob = await fetch(croppedImageData).then(r => r.blob());
    const file = new File([blob], `${new Date().getTime()}.jpg`, { type: 'image/jpeg' }); // Creating a file object
    const storageRef = ref(storage, 'images/' + file.name);
  
    try {
      const snapshot = await uploadBytes(storageRef, file);
      const url = await getDownloadURL(snapshot.ref);
      //console.log("Image URL: ", url);
      if(url){
        setIsPhotoThere(true)
      }
      handleInputChange(sectionKey, groupId, inputIndex, url); // Save the URL instead of the blob
      setCroppedImage(url); // Display the image from the URL
    } catch (error) {
      //console.error("Upload failed", error);
    } finally {
      setIsPhotoUploading(false); // End uploading
    }
  };
  
  
  
  
  const handleSectionTitleChange = (sectionKey, newTitle) => {
    setSections((prevSections) => ({
      ...prevSections,
      [sectionKey]: {
        ...prevSections[sectionKey],
        customSectionTitle: newTitle, // Update the customSectionTitle
      },
    }));
  };
  const handleKeyPress = (e, sectionKey) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent the default action to avoid submitting the form
      e.target.blur(); // Remove focus from the input field
    }
  };
  const handleToggleIsShow = (sectionKey) => {
    setSections(prevSections => ({
      ...prevSections,
      [sectionKey]: {
        ...prevSections[sectionKey],
        isShow: !prevSections[sectionKey].isShow,
      }
    }));
  };
  const handleToggleIsDisplay = (sectionKey) => {
    setSections(prevSections => ({
      ...prevSections,
      [sectionKey]: {
        ...prevSections[sectionKey],
        isDisplay: !prevSections[sectionKey].isDisplay,
      }
    }));
  };
  const handleToggleShow = (sectionKey) => { 
    setSections(prevSections => { 
      const newSections = {...prevSections}; 
      if(newSections[sectionKey] && newSections[sectionKey].hasOwnProperty('isShow')) {
        newSections[sectionKey].isShow = true;  
      } 
      return newSections;  
    });
  };
  
  const handleAddCustomSection = () => {
    // Generate a unique ID for the new custom section
    const uniqueSectionId = `custom-${Math.random().toString(36).substr(2, 9)}`;
  
    // Deep clone the custom section template to ensure no shared references
    let newCustomSection = JSON.parse(JSON.stringify(CustomSectionForm.custom));
  
    // Assign the unique ID to the new custom section
    newCustomSection.sectionID = uniqueSectionId;
    newCustomSection.isShow = true;
  
    // Ensure each inputGroup within the new custom section has a unique ID
    newCustomSection.inputGroups = newCustomSection.inputGroups.map(group => ({
      ...group,
      id: `group-${Math.random().toString(36).substr(2, 9)}`,
      inputs: group.inputs.map(input => ({
        ...input,
        inputValue: '' // Reset input values for the new section
      }))
    }));    
    // Update the sections state with the new custom section
    setSections(prevSections => ({
      ...prevSections,
      [uniqueSectionId]: newCustomSection
    }));
       // Add uniqueSectionId to the resumeData.formOrder array as the last item
    // setResumeData(prevResumeData => ({
    //   ...prevResumeData,
    //   formOrder: [...prevResumeData.formOrder, uniqueSectionId],      
    // }));
    setResumeData(prevResumeData => {    
      const updatedRightColumn = [
        ...prevResumeData.sectionOrder.twoColumns.rightColumn,
        uniqueSectionId,
      ];
      const updatedFormOrder = [
        ...prevResumeData.formOrder,
        uniqueSectionId,
      ];
      return {
        ...prevResumeData,
        formObject: {
          ...prevResumeData.formObject,
          [uniqueSectionId]: newCustomSection,
        },
        sectionOrder: {
          ...prevResumeData.sectionOrder,
          twoColumns: {
            ...prevResumeData.sectionOrder.twoColumns,
            rightColumn: updatedRightColumn,
          },
        },
        formOrder: updatedFormOrder,
      };
    });     
  };
  
  const handleRemoveCustomSection = (sectionIdToRemove) => {
    // Note: Assuming setSections function is defined elsewhere for local component state management
    setSections(prevSections => {
      const newSections = { ...prevSections };
      delete newSections[sectionIdToRemove];
      return newSections;
    });
    // Remove the specified section from the resumeData.formObject
    setResumeData(prevResumeData => {
      const newFormObject = { ...prevResumeData.formObject };
      delete newFormObject[sectionIdToRemove]; // Remove the custom section
  
      const newRightColumn = prevResumeData.sectionOrder.twoColumns.rightColumn.filter(sectionId => sectionId !== sectionIdToRemove);
      const newFormOrder = prevResumeData.formOrder.filter(sectionId => sectionId !== sectionIdToRemove);
  
      return {
        ...prevResumeData,
        formObject: newFormObject,
        sectionOrder: {
          ...prevResumeData.sectionOrder,
          twoColumns: {
            ...prevResumeData.sectionOrder.twoColumns,
            rightColumn: newRightColumn,
          },
        },
        formOrder: newFormOrder,
      };
    });
  };

  
//   const handleDragStart = (e, groupId) => {
//   setDraggedItem(groupId);
//   e.dataTransfer.effectAllowed = "move"; 
//   e.dataTransfer.setData('text/plain', ''); 
// };

const moveSectionToOtherColumn = (sectionKey) => {
  setResumeData(prevResumeData => {
    // Extract left and right columns for easier manipulation
    const { leftColumn, rightColumn } = prevResumeData.sectionOrder.twoColumns;

    // Determine the new columns by moving the section from one to the other
    const newLeftColumn = leftColumn.includes(sectionKey) ? leftColumn.filter(id => id !== sectionKey) : [...leftColumn, sectionKey];
    const newRightColumn = rightColumn.includes(sectionKey) ? rightColumn.filter(id => id !== sectionKey) : [...rightColumn, sectionKey];

    // Construct the new section order
    const newSectionOrder = {
      ...prevResumeData.sectionOrder,
      twoColumns: {
        leftColumn: newLeftColumn,
        rightColumn: newRightColumn,
      }
    };

    return {
      ...prevResumeData,
      sectionOrder: newSectionOrder
    };
  });
};


const moveSectionToColumn = (sectionId, targetColumn) => {
  setResumeData(prevResumeData => {
    const newSectionOrder = { ...prevResumeData.sectionOrder };
    const { leftColumn, rightColumn } = newSectionOrder.twoColumns;
    
    // Remove section from its current column
    if (leftColumn.includes(sectionId)) {
      newSectionOrder.twoColumns.leftColumn = leftColumn.filter(id => id !== sectionId);
    } else if (rightColumn.includes(sectionId)) {
      newSectionOrder.twoColumns.rightColumn = rightColumn.filter(id => id !== sectionId);
    }

    // Add section to target column
    if (targetColumn === 'left') {
      newSectionOrder.twoColumns.leftColumn.push(sectionId);
    } else if (targetColumn === 'right') {
      newSectionOrder.twoColumns.rightColumn.push(sectionId);
    }

    return {
      ...prevResumeData,
      sectionOrder: newSectionOrder,
    };
  });
};

const handleDragStart = (e, groupId) => {
  e.dataTransfer.setData('application/reactflow', groupId); // Use a custom MIME type
  e.dataTransfer.effectAllowed = 'move';
  setDraggedItem(groupId);
};

const handleDragOver = (e, groupId) => {
  e.preventDefault(); // Necessary to allow dropping
  setHoveredItem(groupId); // Update hovered item
};

const handleDragLeave = (e) => {
  e.preventDefault();
  setHoveredItem(null); // Reset hovered item when leaving
};

const handleDrop = (e, sectionKey, targetGroupId) => {
  e.preventDefault();
  if (draggedItem === targetGroupId) return; // No action if dropped on itself

  // Logic to reorder inputGroups based on drag and drop
  // This requires you to adjust your sections state to reflect the new order
  const newSections = { ...sections };
  const sourceIndex = newSections[sectionKey].inputGroups.findIndex(group => group.id === draggedItem);
  const targetIndex = newSections[sectionKey].inputGroups.findIndex(group => group.id === targetGroupId);

  if (sourceIndex < 0 || targetIndex < 0) return; // Safety check

  // Simple array item swap logic
  const temp = newSections[sectionKey].inputGroups[sourceIndex];
  newSections[sectionKey].inputGroups[sourceIndex] = newSections[sectionKey].inputGroups[targetIndex];
  newSections[sectionKey].inputGroups[targetIndex] = temp;

  setSections(newSections);
  setDraggedItem(null); // Reset dragged item state
};

const getSettingValue = (settings, settingName) => {
  // Find the setting by name and return its value
  const setting = settings.find(s => s.inputKey === settingName);
  return setting ? setting.isShow : false; // Adjust based on your structure
};

const handleSettingChange = (e, sectionKey, settingLabel) => {
  const newValue = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
  setSections(prevSections => ({
    ...prevSections,
    [sectionKey]: {
      ...prevSections[sectionKey],
      settings: prevSections[sectionKey].settings.map(setting => 
        setting.inputLabel === settingLabel ? { ...setting, inputValue: newValue } : setting
      )
    }
  }));
};

// State to track if all sections are expanded or collapsed
const [allExpanded, setAllExpanded] = useState(false);

const toggleAllSections = () => {
  // Toggle the global expanded/collapsed state
  setAllExpanded(!allExpanded);
  setSections(prevSections => {
    const newSections = {};
    Object.keys(prevSections).forEach(sectionKey => {
      const section = prevSections[sectionKey];
      newSections[sectionKey] = {
        ...section,
        isExpanded: !allExpanded, 
        inputGroups: section.inputGroups.map(group => ({
          ...group,
          isExpanded: !allExpanded 
        }))
      };
    });
    return newSections;
  });
};


// const handleSubmit = async (event) => {
//   event.preventDefault();
//   const resumeObject = {
//     formObject: Object.keys(sections).reduce((acc, key) => {
//       const section = sections[key];
//       acc[key] = {
//         ...section, 
//         inputGroups: section.inputGroups.map(group => ({
//           ...group,
//           inputs: group.inputs.map(input => ({
//             ...input,
//             inputValue: input.inputValue
//           }))
//         }))
//       };
//       return acc;
//     }, {}),  
//     sectionOrder: resumeData.sectionOrder,
//     formOrder: resumeData.formOrder,
//     metadata: {
//       ...resumeData.metadata, 
//       lastModifiedDate: new Date().toISOString(),
//       userID: userId,
//       resumeID: currentResumeId, 
//     }
//   };
//   try { 
//     await saveResume(resumeObject);
//     console.log("Resume saved successfully");
//   } catch (error) {
//     console.error("Error saving resume: ", error);
//   }
// };

//Auto Save

// const saveResumeData = async () => {
//   const resumeObject = {
//     formObject: Object.keys(sections).reduce((acc, key) => {
//       const section = sections[key];
//       acc[key] = {
//         ...section, 
//         inputGroups: section.inputGroups.map(group => ({
//           ...group,
//           inputs: group.inputs.map(input => ({
//             ...input,
//             inputValue: input.inputValue
//           }))
//         }))
//       };
//       return acc;
//     }, {}),  
//     sectionOrder: resumeData.sectionOrder,
//     formOrder: resumeData.formOrder,
//     metadata: {
//       ...resumeData.metadata, 
//       lastModifiedDate: new Date().toISOString(),
//       userID: userId,
//       resumeID: currentResumeId, 
//     }
//   };
//   try { 
//     await saveResume(resumeObject);
//     console.log("Resume saved successfully");
//   } catch (error) {
//     console.error("Error saving resume: ", error);
//   }
// };
/************************** */
//Auto save
const firstUpdate = useRef(true);
// Adjusted useDebounce to work with resumeData instead of just sections
const debouncedResumeData = useDebounce(resumeData, 1000);
const debouncedSections = useDebounce(sections, 1000); 

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
const [updating, setUpdating] = useState(false);
const saveResumeData = async () => {
  setUpdating(true);
  if (!debouncedResumeData || firstUpdate.current) {
    return;
  }
  const { formObject, formOrder, sectionOrder, metadata } = debouncedResumeData;
  // Construct the resumeObject including potentially updated formOrder
  // console.log(sectionOrder)
  // console.log(formOrder)
  // console.log(resumeData)
  // console.log(formObject)
  // console.log(sections)
  sectionOrder.singleColumn = formOrder;
  const resumeObject = {
    formObject: sections || {}, // Fallback to empty object if undefined
    sectionOrder: sectionOrder || [], // Fallback to empty array if undefined
    formOrder: formOrder, // This includes the updated order after drag and drop
    metadata: {
      ...metadata,
      lastModifiedDate: new Date().toISOString(),
      userID: userId,
      resumeID: currentResumeId,
      selectedLanguage: currentLanguage,
      templateName: resumeName
    }
  };

  try {
    await saveResume(resumeObject);
   // console.log("Resume saved successfully");
    setUpdating(false);
  } catch (error) {
    console.error("Error saving resume: ", error);
    setUpdating(false);
  }
};

useEffect(() => {
  if (firstUpdate.current) {
    firstUpdate.current = false;
    return;
  }

  saveResumeData();
}, [debouncedSections, debouncedResumeData]);

/******************************************************************** */
//Drag and Drop
// useEffect(() => {
//   const drake = dragula([document.querySelector('.sections-container')]);
//   drake.on('drop', () => {
//     const newOrder = Array.from(document.querySelectorAll('.sections-container > .section'))
//       .map(element => element.getAttribute('data-section-key'));
//     console.log(newOrder)
//     setResumeData(prevResumeData => ({
//       ...prevResumeData,
//       formOrder: newOrder
//     }));
//   });

//   return () => drake.destroy(); 
// }, []); 
useEffect(() => {
  const drake = dragula([document.querySelector('.sections-container')], {
    moves: (el, container, handle) => {
      // Prevent the first element from being moved
      if (el === container.firstChild) {
        return false;
      }
      // Check if the handle itself or any of its parents up to the container has the 'drag-handle' class
      let currentElement = handle;
      while (currentElement && currentElement !== container) {
        if (currentElement.classList.contains('drag-handle')) {
          return true; // Allow the drag if 'drag-handle' class is found
        }
        currentElement = currentElement.parentElement;
      }
      return false; // Disallow the drag if 'drag-handle' class is not found
    },
    accepts: (el, target, source, sibling) => {
      // Disallow dropping elements before the first element
      if (sibling === target.firstChild) {
        return false;
      }
      return true; // Allow the drop otherwise
    }
  });

  drake.on('drop', () => {
    // After drop, read the new order from the DOM    
    const newOrder = Array.from(document.querySelectorAll('.sections-container > .section'))
      .map(element => element.getAttribute('data-section-key'));
      
      //console.log(newOrder);
      const updatedNewOrder = updateFormOrder(newOrder);
      //console.log(updatedNewOrder)
      const newSectionOrder = updateSectionOrder(updatedNewOrder);
      //console.log(newSectionOrder);

    // Update formOrder in your resumeData state
    setResumeData(prevResumeData => ({
      ...prevResumeData,
      formOrder: updatedNewOrder,
      sectionOrder: newSectionOrder,
      // sectionOrder:{
      //   singleColumn:newOrder
      // }
    }));
    
    // Optionally, trigger a save operation or mark the form as 'dirty' for saving later
  });
  function updateFormOrder(newOrder) {
    // A set for efficient lookup of items in newOrder
    const newOrderSet = new Set(newOrder);
    const updatedNewOrder = [...newOrder];
    resumeData.formOrder.forEach(item => {
      if (!newOrderSet.has(item)) {
        updatedNewOrder.push(item);
      }
    });    
    return updatedNewOrder;
  }
  function updateSectionOrder(formOrder) {
    // Define the original columns structure for reference
    // const originalLeftColumnItems = new Set([
    //   "contactDetails",
    //   "skills",
    //   "languages",
    //   "hobbies",
    // ]);
    const originalLeftColumnItems = new Set(resumeData.sectionOrder.twoColumns.leftColumn);
    // console.log(originalLeftColumnItems)
    // console.log(resumeData.sectionOrder.twoColumns.leftColumn)
    // console.log(new Set(resumeData.sectionOrder.twoColumns.leftColumn));

    // Initialize the new section order structure
    const sectionOrder = {
      singleColumn: formOrder, // singleColumn is directly the updated formOrder
      twoColumns: {
        leftColumn: [],
        rightColumn: [],
      },
    };
  
    // Iterate over the formOrder to distribute items into left and right columns
    formOrder.forEach(item => {
      if (originalLeftColumnItems.has(item)) {
        sectionOrder.twoColumns.leftColumn.push(item);
      } else {
        sectionOrder.twoColumns.rightColumn.push(item);
      }
    });
  
    return sectionOrder;
  }

  return () => drake.destroy(); // Cleanup dragula instance when component unmounts
}, [isLoading, resumeData]); // Add dependencies if necessary

useEffect(() => {
  const containers = Array.from(document.querySelectorAll('.section .input-groups-container'));
  if (containers.length === 0) return;
  const drake = dragula(containers, {
    moves: (el, container, handle) => handle.classList.contains('group-drag-handle'),
  });
  return () => drake.destroy();
}, []); 

const sectionRefs = useRef({});
useEffect(() => {
  const updatedRefs = {};
  Object.keys(sections).forEach((key) => {
    // Allocate a ref if it doesn't exist
    updatedRefs[key] = sectionRefs.current[key] || createRef();
  });
  sectionRefs.current = updatedRefs;
}, [sections]); // Re-run when sections change

// const scrollToSection = (sectionKey) => {
//   // Scroll to the section
//   const sectionRef = sectionRefs.current[sectionKey];
//   if (sectionRef?.current) {
//     sectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
//   }

//   // Expand the section
//   setSections(prevSections => ({
//     ...prevSections,
//     [sectionKey]: {
//       ...prevSections[sectionKey],
//       isExpanded: true,
//     },
//   }));
// };
const scrollToSection = (sectionKey) => {
  setSections(prevSections => {
    // First, collapse all sections
    const updatedSections = Object.keys(prevSections).reduce((acc, key) => {
      acc[key] = {
        ...prevSections[key],
        isExpanded: false, // Collapse all sections
      };
      return acc;
    }, {});

    // Now, specifically expand the section that was requested
    updatedSections[sectionKey] = {
      ...updatedSections[sectionKey],
      isExpanded: true,
    };

    return updatedSections;
  });

  // After state update, ensure the section is scrolled into view
  // This needs to be done after a slight delay to ensure the DOM updates
  setTimeout(() => {
    const sectionRef = sectionRefs.current[sectionKey];
    if (sectionRef?.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, 0);
};

// useEffect(() => {
//   import(`./locales/${currentLanguage}.json`)
//     .then(translations => {
//       translateAndUpdateSections(translations.default || translations);
//     });
// }, [currentLanguage]);
useEffect(() => {
  import(`./locales/${currentLanguage}.json`)
    .then(translations => {
      translateAndUpdateSections(translations.default || translations);
    })
    .catch(error => {
      console.error(`Error loading translation file for ${currentLanguage}:`, error);
      // Optionally, load a default language file or handle the error gracefully
      import(`./locales/en.json`).then(defaultTranslations => {
        translateAndUpdateSections(defaultTranslations.default || defaultTranslations);
      });
    });
}, [currentLanguage]);

const translateAndUpdateSections = (translations) => {
  const translatedSections = { ...sections };

  Object.entries(translatedSections).forEach(([sectionKey, sectionValue]) => {
    if (translations[sectionKey]?.sectionTitle) {
      sectionValue.sectionTitle = translations[sectionKey].sectionTitle;
    }
    if (translations[sectionKey]?.addButtonLabel) {
      sectionValue.addButtonLabel = translations[sectionKey].addButtonLabel;
    }
    sectionValue.inputGroups.forEach(group => {
      group.inputs.forEach(input => {
        const inputTranslations = translations[sectionKey]?.inputs[input.inputKey];
        if (inputTranslations) {
          input.inputLabel = inputTranslations.inputLabel || input.inputLabel;
          input.placeholder = inputTranslations.placeholder || input.placeholder;
        }
      });
    });
  });

  setSections(translatedSections);
};

const logoImage = process.env.REACT_APP_ICON_IMAGE === 'cvdesigner-icon.png' ? cvdesignerLogo : resumeDesignLogo;
const logoClass = process.env.REACT_APP_ICON_IMAGE === 'cvdesigner-icon.png' ? 'cvdesigner-icon' : 'resumedesign-icon';

return (
  <div className="flex flex-col md:flex-row">
 
   {/* <button onClick={handleSubmit} className="mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
      Save Resume
    </button> */}
    {isLoading && 
    <div className="flex items-center justify-center w-full">
    <LoadingSpinner displayType='block' />
    </div>
    }
      {/*Templates preview */}
    {!isLoading && !showTemplateCollection && 
      <div className={`flex flex-row w-full md:w-[40%] h-screen ${showForm ? 'edit-mode' : 'preview-mode'}`}> 
    
      {/* <div className="flex flex-col bg-blue-500 hover:bg-blue-700 text-white font-bold px-4">
          {Object.entries(sections).filter(([, section]) => section.isShow).map(([key, section]) => (
            <button
              key={key}
              onClick={() => scrollToSection(key)}
              className="text-white hover:text-gray-200 text-sm p-2"
              style={{ display: "inline-block", marginRight: "10px" }} >
              {section.customSectionTitle || section.sectionTitle}
            </button>
          ))} 
        </div>*/}
        <div className="flex flex-col w-full h-screen border-l-10 border-cyan-400">
          <div className="bg-white flex justify-between"> 
              <div className='resume-form w-full flex justify-between p-4 py-3'>
              <div className='flex'>
              <Link to="/dashboard">
              <div className={`${logoClass}`}>
                <div className="logo">
                  <img src={logoImage} alt="Logo" className="logo-img" />
                </div>
              </div>
                </Link>       
              {/* <Link href="/dashboard">
                <button className="text-sm text-gray-600 rounded italic py-1 my-1 ml-4 px-2 hover:bg-gray-200 focus:outline-none focus:border-gray-500">
                    Back to Dashboard
                </button>
            </Link> */}
              </div>
              <div className='flex'>
              <div className="resume-name">
                <input type="text" className="rounded-none max-w-full outline-none inline-block text-start overflow-hidden border-b duration-150 transition-colors bg-transparent cursor-text px-0 truncate border-transparent placeholder-opacity-1 py-0.5 my-0.5 
                placeholder-gray-200 focus:placeholder-gray-400 focus:border-brand-400 hover:border-gray-700" alt="Resume James Dow" 
                placeholder="Enter resume name" value={resumeName} onChange={e => setResumeName(e.target.value)} onBlur={saveResumeData}/>
                </div>
                <div className="resume-saving-status flex justify-center items-center">
                        {updating
                            ? <BiRefresh className="resume-updating" />
                            : <BiCloudUpload />}
                    </div>
              </div>
              <div>
          <LanguageSelector selected={currentLanguage} onSelectLanguage={handleLanguageChange} />
          {/* Other parts of your form */}
        </div>
              {/* <select className='text-sm text-gray-600 rounded py-1 my-1 ml-4 bg-gray-100 px-2 hover:bg-gray-200 focus:outline-none focus:border-gray-500' value={currentLanguage} onChange={(e) => setCurrentLanguage(e.target.value)}>
                <option value="en">English</option>
                <option value="en-GB">English (UK)</option>
                <option value="es">Español</option>
                <option value="es-MX">Español (MX)</option>
                <option value="fr">Français</option>
                <option value="de">Deutsch</option>
                <option value="it">Italiano</option>
                <option value="nl">Nederlands</option>
                <option value="pt">Português</option>
                <option value="pt-BR">Português (BR)</option>
                <option value="pl">Polski</option>
                <option value="cs">Čeština</option>
                <option value="ja">日本語</option>
                <option value="sv">Svenska</option>
                <option value="da">Dansk</option>
                <option value="de-CH">Switzerland</option>
                <option value="no">Norsk</option>
                <option value="fi">Suomi</option>
                <option value="ru">Русский</option>
                <option value="hu">Magyar</option>
                <option value="ro">Română</option>
                <option value="af">Afrikaans</option>
                <option value="el">Ελληνικά</option>
                <option value="en-IE">Ireland</option>
                <option value="de-AT">Austria</option>
                <option value="bg">Bulgaria</option>
                <option value="es-CL">Chile</option>
                <option value="sr">Serbia</option>
                <option value="vi">Vietnam</option>
            </select> */}
              </div>
          </div>
      
          {!isLoading && 
          <div className="flex-1 bg-white overflow-auto custom-scrollbar p-5">
          <div className="form-container">
          <div className="sections-container bg-white rounded">  
          {Object.entries(sections).filter(([, sectionValue]) => sectionValue.isShow !== false).map(([sectionKey, sectionValue]) => (
                <div
                      key={sectionKey}
                      data-section-key={sectionKey}
                      ref={sectionRefs.current[sectionKey]} // Ensure this ref has been initialized
                      className="section bg-gray-100 rounded-lg order-gray-500 p-4 mb-4"
                    >
                  <div className='flex justify-between items-center'>
                  
                  <h3 className="font-bold text-lg"> {sectionValue.icon}
                    <input
                      type="text"
                      value={sectionValue.customSectionTitle || sectionValue.sectionTitle} //All sectionTitles or customeSectionTitle goes here
                      onChange={(e) => handleSectionTitleChange(sectionKey, e.target.value)}
                      onKeyPress={(e) => handleKeyPress(e, sectionKey)} 
                      className="editable-title-input bg-transparent ml-2 focus:border-blue-500 focus:ring-blue-500 "
                    /> 
                  </h3>
                  {/* <h3 className="font-bold text-lg"> {sectionValue.icon}
      <input
        type="text"
        // Use translation if available, fallback to existing titles if not
        value={t[sectionKey]?.sectionTitle || sectionValue.customSectionTitle || sectionValue.sectionTitle}
        onChange={(e) => handleSectionTitleChange(sectionKey, e.target.value)}
        onKeyPress={(e) => handleKeyPress(e, sectionKey)} 
        className="editable-title-input bg-transparent ml-2 focus:border-blue-500 focus:ring-blue-500"
      /> 
    </h3> */}

                  <div className='actionButtons flex justify-between items-center'>
                  <div>
                  <div>
                  {/* <div className='actionButtons flex justify-between items-center'>
      <div className='flex'>
      {sectionKey !== "personalDetails" && (    
      <button className="drag-handle grabbable flex p-2 rounded-full bg-slate-100 text-gray-500 hover:bg-slate-200 focus:outline-none" aria-label="Drag to reorder"> 
          <BiGridVertical/> 
        </button>  
        )}
      <div className='isDisplay'>
        <button
          onClick={(event) => {
            event.preventDefault();
            handleToggleIsDisplay(sectionKey);
          }}
          className="p-2 rounded-full bg-slate-100 text-gray-500 hover:bg-slate-200 focus:outline-none"
          aria-label={sectionValue.isDisplay ? "Hide Section" : "Show Section"}
        >
          {sectionValue.isDisplay ? <BiShow /> : <BiHide />}
      </button>
      </div>
      </div>
    <div>
        <button
          onClick={(event) => {
            event.preventDefault();
            handleToggleIsShow(sectionKey);
          }}
          className="p-2 rounded-full bg-slate-100 mr-2 text-gray-500 hover:bg-slate-200 focus:outline-none"
          aria-label={sectionValue.isShow ? "Hide Section" : "Show Section"}
        >
          {sectionValue.isShow ? <BiWindowClose /> : <BiWindowClose />}
        </button>
      </div>
    </div> */}
    <div className='actionButtons flex justify-between items-center relative'>
                {sectionKey !== "personalDetails" && (
                    <button className="drag-handle grabbable p-2 rounded-full bg-slate-100 text-gray-500 hover:bg-slate-200 focus:outline-none">
                      <BiGridVertical />
                    </button>
                )}
                {/* {sectionKey !== "personalDetails" && (
                    <button onClick={() => toggleDropdown(sectionKey)} className="dropdown-toggle p-2 rounded-full bg-slate-100 text-gray-500 hover:bg-slate-200 focus:outline-none">
                      <MdMoreVert />
                    </button>
                )}        
                {dropdownVisibility[sectionKey] && (
                <div className="dropdown-menu absolute mt-2 right-0 bg-white shadow-lg rounded-md overflow-hidden z-10">
                  <div className="py-1">
                    <button
                      onClick={(event) => {
                        event.preventDefault();
                        handleToggleIsDisplay(sectionKey);
                      }}
                      className="block px-4 py-2 flex justify-start text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                    >
                      <span className='mr-2'>{sectionValue.isDisplay ? <BiHide /> : <BiShow />}</span> <span className='text-xs'>{sectionValue.isDisplay ? 'Hide section' : 'Show section'}</span>
                    </button>

                    <button
                      onClick={(event) => {
                        event.preventDefault();
                        handleToggleIsShow(sectionKey);
                      }}
                      className="block px-4 py-2 flex justify-start text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                    >
                      <span className='mr-2'>
                        <LiaTrashSolid />
                        </span> <span className='text-xs'>Remove section</span> 
                    </button>
                    
                    {!isSingleLayout && <>
                      <strong className='text-xs w-full py-2 px-4'>
                        Move Section to</strong>
                      <button
                        onClick={(event) => {
                          event.preventDefault();
                          moveSectionToOtherColumn(sectionKey);
                        }}
                        className="block px-4 py-2 flex justify-start text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                      >
                      <span className='mr-2'><BiTransfer /></span> <span className='text-xs'>Other column</span>
                      </button>
                    </>}      
                  </div>
                </div>
            )} */}
    <Dropdown
        sectionKey={sectionKey}
        items={[
            {
                label: sectionValue.isDisplay ? 'Hide section' : 'Show section',
                action: handleToggleIsDisplay,
                icon: sectionValue.isDisplay ? <BiHide /> : <BiShow />,
            },
            {
                label: 'Remove section',
                action: handleToggleIsShow,
                icon: <BiTrash />,
            },
            ...(isSingleLayout ? [] : [{
                label: 'Move section to other column',
                action: moveSectionToOtherColumn,
                icon: <BiTransfer />,
            }])
        ]}
    />


              </div>
      </div>
                  </div>
                  <button
                  onClick={(event) => {
                    event.preventDefault(); 
                    toggleSection(sectionKey);
                  }}
                  className="p-2 rounded-full bg-slate-100 mr-2 hover:bg-slate-200 focus:outline-none"
                  aria-label={sectionValue.isShow ? "Hide Section" : "Show Section"}
                >
                  {sectionValue.isExpanded ? <LiaAngleUpSolid/> : <LiaAngleDownSolid/>}
                </button>
                  </div>
                
                  </div>
                  {sectionValue.isExpanded && ( <>

                    <div className='mb-4'></div>

                    {sectionValue.inputGroups.map((group) => (
      <div
      key={group.id}
      // draggable="true"  
      onDragOver={(e) => handleDragOver(e, group.id)}
      onDragLeave={handleDragLeave}
      onDrop={(e) => handleDrop(e, sectionKey, group.id)}
      className={`mb-4 rounded shadow-md ${draggedItem === group.id ? 'shadow-lg' : ''} ${hoveredItem === group.id && draggedItem !== group.id ? 'shadow-outline' : ''}`}
    >
      
      <div key={group.id} className="mb-4 rounded">  
      
      {sectionValue.sectionType !== "summary" && sectionValue.sectionType !== "achievements" && (
      <div className="bg-white rounded p-4 flex justify-between items-center">   
        <h4 className="text-md">    
          {!group.inputs.slice(0, 2).some(input => input.inputValue) && `${sectionValue.sectionTitle} `}
          {group.inputs.slice(0, 2).map((input, index) => (
            input.inputValue && (
              <span key={index} className={`input-preview ${index === 0 ? 'first-input' : 'second-input'}`}>
                {input.inputValue}{index < 1 ? ' ' : ''}
              </span>
            )
          ))}
        </h4> 
        <div className='actionButtons flex justify-between items-center'>
        <span
          draggable="true"
          onDragStart={(e) => {
            console.log("Drag Start", group.id);
            handleDragStart(e, group.id);
          }}
          className="grabbable" 
        >
          <span className='flex text-gray-400'>
          <BiGridVertical className='text-lg'/>
            </span>
        </span>

        {sectionValue.allowMultiple && (      
                <button onClick={(event) => {event.preventDefault(); prepareRemoveInputGroup(sectionKey, group.id)}} className="remove-button text-red-500 p-2">
                <LiaTrashSolid />
              </button>
            )}
          <a
            href="#!"
            onClick={(e) => {
              e.preventDefault();
              toggleInputGroup(sectionKey, group.id);
            }}
            className="toggle-button p-2"
          >
            {group.isExpanded ? <LiaAngleUpSolid/> : <LiaAngleDownSolid/>}
          </a>
        
            </div>
        </div>
        )}
          {group.isExpanded && (
          <div className="bg-white rounded p-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4"> 
              {group.inputs.map((input, inputIndex) => (
                <div key={inputIndex} className={input.inputType === 'textarea' ? "md:col-span-2" : ""}>
                   {input.inputType !== 'textarea' && input.inputLabel && (
                    <label className="block text-gray-700 text-sm font-bold mb-1">
                      {input.inputLabel} 
                    </label>
                  )}
                  {renderInput(input, sectionKey, group.id, inputIndex)}
                </div>
              ))}
            </div> 
          </div>
        )}
          {sectionKey.startsWith("custom-") && (
      <div className='flex justify-end'>
        <a href="#!" onClick={(even) => prepareRemoveCustomSection(sectionKey)} className="flex justify-end text-right p-2 rounded-full bg-slate-100 mr-2 text-red-500 hover:text-red-800 ">
          <span className='mt-1'><LiaTrashSolid /></span> 
        </a>
        </div>
      )}
      </div>

      </div>
    ))}

    {/* Render AI Writer for skills section above the settings container */}
    {sectionValue.sectionType === "skills" && (
    <div className='flex justify-between'>

    <div className="settings-container">
      {sectionValue.settingsIsShow && sectionValue.settings.map((setting) => {
        let settingComponent;
        switch (setting.inputType) {
          case 'select':
            settingComponent = (
              <label className="flex items-center">
                <div className="text-gray-700 w-200 text-sm" style={{"width":'110px'}}>{setting.inputLabel}</div>
                <select
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  onChange={(e) => handleSettingChange(e, sectionKey, setting.inputLabel)}
                >
                  {setting.selectOptions.map((option, idx) => (
                    <option key={idx} value={option}>{option}</option>
                  ))}
                </select>
              </label>
            );
            break;
          default:
            return null;
        }
        return (
          <div key={setting.inputLabel} className="flex justify-between items-center mb-3">
            <div className="flex-grow"></div> 
            {settingComponent}
          </div>
        );
      })}
    </div>

      <div className="ai-writer-footer flex justify-end mt-2 relative"> 
    {!aiLoading && 
        <button
          onClick={() => setActiveAIWriter({sectionKey: sectionKey, groupId: null,})} 
          className="ai-writer-btn"
        >
          <BsStars /> AI Writer
        </button>}
        {aiLoading && 
      <button type="button" className="flex gap-2 text-sm items-center justify-between align-center ai-writer-btn py-2 px-4 bg-blue-500 text-white rounded-3xl hover:bg-blue-700 transition-colors bg-gradient-to-r from-blue-600 to-violet-600 opacity-65">
      <svg width="20" height="20" fill="currentColor" className="mr-1 animate-spin" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
          <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z">
          </path>
      </svg>
      Generating..
    </button>
        }


        
        {activeAIWriter.sectionKey === sectionKey && (
          <AiPopover
            isVisible={activeAIWriter.sectionKey === sectionKey}
            onClose={() => setActiveAIWriter({sectionKey: null, groupId: null})}
            sectionKey={sectionKey}
            className="flex gap-2 rounded-2xl text-sm items-center justify-between align-center ai-writer-btn px-4 py-1 bg-blue-500 text-white rounded hover:bg-blue-700 transition-colors bg-gradient-to-r from-blue-600 to-violet-600"
          >
            {sectionValue.aiWriter.actions.map((action, index) => (
              <div
                key={index}
                className='p-2 px-3 cursor-pointer text-gray-500 hover:bg-gray-100 hover:rounded-md hover:text-blue-500 focus:outline-none'
                onClick={() => {
                  handleAIWriterAction(action.id, sectionKey, null); // Assuming AI Writer action does not need groupId
                  setActiveAIWriter({sectionKey: null, groupId: null}); // Close popover after action
                }}
              >
                {action.id === 'generate' && 'Generate New'}
                {action.id === 'rewrite' && 'Rewrite Section'}
              </div>
            ))}
          </AiPopover>
        )}
      </div>
      </div>
    )}

    {sectionValue.sectionType === "softSkills" && (
    <div className='flex justify-between'>

    <div className="settings-container">
      {sectionValue.settingsIsShow && sectionValue.settings.map((setting) => {
        let settingComponent;
        switch (setting.inputType) {
          case 'select':
            settingComponent = (
              <label className="flex items-center">
                <div className="text-gray-700 w-200 text-sm" style={{"width":'110px'}}>{setting.inputLabel}</div>
                <select
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  onChange={(e) => handleSettingChange(e, sectionKey, setting.inputLabel)}
                >
                  {setting.selectOptions.map((option, idx) => (
                    <option key={idx} value={option}>{option}</option>
                  ))}
                </select>
              </label>
            );
            break;
          default:
            return null;
        }
        return (
          <div key={setting.inputLabel} className="flex justify-between items-center mb-3">
            <div className="flex-grow"></div> 
            {settingComponent}
          </div>
        );
      })}
    </div>

      <div className="ai-writer-footer flex justify-end mt-2 relative"> 
    {!aiLoading && 
        <button
          onClick={() => setActiveAIWriter({sectionKey: sectionKey, groupId: null,})} 
          className="ai-writer-btn"
        >
          <BsStars /> AI Writer
        </button>}
        {aiLoading && 
      <button type="button" className="flex gap-2 text-sm items-center justify-between align-center ai-writer-btn py-2 px-4 bg-blue-500 text-white rounded-3xl hover:bg-blue-700 transition-colors bg-gradient-to-r from-blue-600 to-violet-600 opacity-65">
      <svg width="20" height="20" fill="currentColor" className="mr-1 animate-spin" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
          <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z">
          </path>
      </svg>
      Generating..
    </button>
        }


        
        {activeAIWriter.sectionKey === sectionKey && (
          <AiPopover
            isVisible={activeAIWriter.sectionKey === sectionKey}
            onClose={() => setActiveAIWriter({sectionKey: null, groupId: null})}
            sectionKey={sectionKey}
            className="flex gap-2 rounded-2xl text-sm items-center justify-between align-center ai-writer-btn px-4 py-1 bg-blue-500 text-white rounded hover:bg-blue-700 transition-colors bg-gradient-to-r from-blue-600 to-violet-600"
          >
            {sectionValue.aiWriter.actions.map((action, index) => (
              <div
                key={index}
                className='p-2 px-3 cursor-pointer text-gray-500 hover:bg-gray-100 hover:rounded-md hover:text-blue-500 focus:outline-none'
                onClick={() => {
                  handleAIWriterAction(action.id, sectionKey, null); // Assuming AI Writer action does not need groupId
                  setActiveAIWriter({sectionKey: null, groupId: null}); // Close popover after action
                }}
              >
                {action.id === 'generate' && 'Generate New'}
                {action.id === 'rewrite' && 'Rewrite Section'}
              </div>
            ))}
          </AiPopover>
        )}
      </div>
      </div>
    )}

    {sectionValue.sectionType === "languages" && (
    <div className='flex justify-between'>

    <div className="settings-container">
      {sectionValue.settingsIsShow && sectionValue.settings.map((setting) => {
        let settingComponent;
        switch (setting.inputType) {
          case 'select':
            settingComponent = (
              <label className="flex items-center">
                <div className="text-gray-700 w-200 text-sm" style={{"width":'110px'}}>{setting.inputLabel}</div>
                <select
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  onChange={(e) => handleSettingChange(e, sectionKey, setting.inputLabel)}
                >
                  {setting.selectOptions.map((option, idx) => (
                    <option key={idx} value={option}>{option}</option>
                  ))}
                </select>
              </label>
            );
            break;
          default:
            return null;
        }
        return (
          <div key={setting.inputLabel} className="flex justify-between items-center mb-3">
            <div className="flex-grow"></div> 
            {settingComponent}
          </div>
        );
      })}
    </div> 
      </div>
    )}






                  {(sectionValue.allowMultiple || sectionValue.inputGroups.length === 0) && (
                    <button
                      type="button"
                      onClick={() => handleAddInputGroup(sectionKey)}
                      className="flex justify-between items-center font-bold text-sm text-blue-500 hover:text-blue-800"
                    >
                      <LiaPlusSolid /> 
                      <span className='ml-2'>{sectionValue.inputGroups.length > 0 ? sectionValue.addButtonLabel : `Add ${sectionValue.sectionTitle}`}</span>
                    </button>
                  )}

    <div className='flex'>
      
    </div>



                </> )}
                </div>
              ))}
          </div>
        {/* <div className='px-8 text-right'>
        <button onClick={toggleAllSections} type="button" className="py-1 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-blue-600 text-blue-600 hover:border-blue-500 hover:text-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
            {allExpanded ? 'Collapse All' : 'Expand All'}
    </button>
        </div> */}
    
        
          <div className='addSectionsWrap pt-0 pb-2 mb-4'> 
            {/* <h2 className='mb-2 font-bold'>Add Section</h2> */}
            <div className=''>
            
            <div className='add-section-btns flex flex-wrap gap-2.5'>      
            {Object.entries(sections).filter(([, sectionValue]) => sectionValue.isShow === false).map(([sectionKey, sectionValue]) => (
              <div key={sectionKey} >
                <button
                  type="button"
                  onClick={() => handleToggleShow(sectionKey)}
                  className="flex justify-between items-center text-sm hover:text-blue-800 p-3 bg-gray-100 rounded-lg order-gray-500"
                >
                  {sectionValue.icon} <span className='ml-2'>{sectionValue.sectionTitle}</span>
                </button>
                </div>
              ))}
                  <div>
              <button
              type="button"
              onClick={handleAddCustomSection}
              className="flex justify-between items-center text-sm hover:text-blue-800 p-3 bg-gray-100 rounded-lg order-gray-500"
            >
              ✨ <span className='ml-2'>Add Custom Section</span>
            </button>
              </div>
              </div>
            </div>
            <div>
      
              
          <Modal isOpen={isConfirmModalOpen} closeModal={cancelDelete} title="Confirm Deletion"> 
          <p>Are you sure you want to delete this item?</p>
            <div className='bg-white rounded pt-4 flex justify-between mt-5 items-center'>  
              <button type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none" onClick={confirmDelete}>Confirm</button>  
              <button type="button" className="py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 " onClick={cancelDelete}>Cancel</button>
            </div> 
          </Modal>
        </div>
          </div>
        </div>
          </div>}    
          </div>
      </div>}

  {/*ResumePreview*/}
      {!isLoading && resumeData && <ResumePreview sections={sections} resumeData={resumeData} isPhotoVisible={isPhotoVisible} onSectionClick={scrollToSection} setShowTemplateCollection={setShowTemplateCollection} onToggleTemplates={handleToggleTemplates} showResume={showForm} updateResumeData={handleUpdateResumeData} />} 

    {isAIWriterModalOpen && (
  <ModalComponent isOpen={isAIWriterModalOpen} onClose={() => setIsAIWriterModalOpen(false)}>
    <div className="p-4">
      <h3 className="mb-4 font-bold">{modalContent.title}</h3>
      <p className='text-sm'>{modalContent.description}</p>
   
      <input
        type="text"
        value={modalContent.inputValue}
        placeholder={modalContent.inputPlaceholder}
        onChange={(e) => setModalContent(prev => ({ ...prev, inputValue: e.target.value }))}
        className="w-full my-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5   focus:outline-none focus:ring-2 focus:ring-blue-100"
      />

      {/* Link to show textarea */}
      {!modalContent.isCoverPointsVisible && (
        <a 
          href="#" 
          onClick={(e) => {
            e.preventDefault();
            setModalContent(prev => ({ ...prev, isCoverPointsVisible: true }));
          }}
          className="text-blue-500 text-sm hover:underline"
        >
          Want to add any points?
        </a>
      )}

      {/* Text area for covering particular points, shown conditionally */}
      {modalContent.isCoverPointsVisible && (<div className='relative'> 
        <a 
          href="#" 
          onClick={(e) => {
            e.preventDefault();
            setModalContent(prev => ({ ...prev, isCoverPointsVisible: false }));
          }}
          className="text-blue-500 p-3 text-sm absolute right-0 hover:underline"
        >
          <BiX/>
        </a>

        <textarea
          value={modalContent.coverPoints}
          placeholder="Cover any particular points?"
          onChange={(e) => setModalContent(prev => ({ ...prev, coverPoints: e.target.value }))}
          className="w-full my-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 h-28  focus:outline-none focus:ring-2 focus:ring-blue-100"
        ></textarea></div>
      )}

      <div className='flex mt-4 pt-4 justify-end justify-between'>
                 
          <div className="">     
         
    <select
  id="formatSelect"
  name="format"
  value={modalContent.format || 'list'} // Ensures "list" is the default if modalContent.format is not defined or not set
  onChange={(e) => setModalContent(prev => ({ ...prev, format: e.target.value }))}
  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none focus:ring-2 focus:ring-blue-100"
> 
{(modalContent.inputObj.sectionKey !== 'skills' && modalContent.inputObj.sectionKey !== 'softSkills') && (
    <>
        <option value="" disabled>Format</option>
        <option value="list">List</option>
        <option value="paragraph">Paragraph</option>
    </>
)}

  {(modalContent.inputObj.sectionKey == 'skills' || modalContent.inputObj.sectionKey == 'softSkills') && <>
              <option value="" disabled>Format</option>
              <option value="max 3 words">very short</option>
              <option value="max 10 words">one line</option>
              </>
          } 
</select>

          </div>
 
          <button
  className={`flex gap-2 text-sm items-center justify-between align-center ai-writer-btn py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700 transition-colors ${
    modalContent.inputValue.trim().length ? "bg-blue-500 hover:bg-blue-700" : "bg-blue-300"
  }`}
  disabled={!modalContent.inputValue.trim().length}
  onClick={() => {
    // Directly using modalContent.format in the submission
    // Ensures we're not defaulting to 'list' unintentionally
    const format = modalContent.format || (modalContent.inputObj.sectionKey === 'skills' ? 'max 3 words' : 'list');
    const inputObj = {
      ...modalContent.inputObj,
      coverPoints: modalContent.coverPoints,
      format,
    };
    handleGenerateNew(modalContent.inputValue, inputObj);
    setIsAIWriterModalOpen(false);
  }}
>
  Generate with AI
</button>

      </div>
    </div>
  </ModalComponent>
)}

      {/* Toggle Button for Mobile View */}
  {!showingTemplates && 
        <div className="md:hidden sticky-footer flex justify-between py-4 bg-white fixed bottom-0 w-full text-black shadow-md">
        <Link to="/dashboard" className="flex flex-col items-center fo-btn text-white text-md py-1.5 px-4 rounded">
          <BiHome className="text-xl" />
          <span className="text-xs">Dashboard</span>
        </Link>
        <button
          onClick={toggleFormVisibility}
          className="flex flex-col items-center fo-btn text-white py-1.5 px-4 text-md rounded"
        >
          <BiEdit className="text-xl" />
          <span className="text-xs">{showForm ? 'Preview' : 'Edit'}</span>
        </button>
      </div>
    }
  </div>
);


  
};

export default ResumeForm;

