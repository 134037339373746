import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import LoadingSpinner from '../components/LoadingSpinner';
import { fetchCoverletterById, duplicateCoverLetter2 } from '../services/resumeService';

const CLExampleRedirect = () => {
  const { user, loading: authLoading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const hasProcessed = useRef(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const coverLetterId = queryParams.get('id');

    const handleExampleCoverLetter = async () => {
      if (authLoading || hasProcessed.current) return;

      if (!user) {
        navigate(`/login?redirect=${location.pathname}${location.search}`);
      } else {
        hasProcessed.current = true; // Mark as processed to prevent duplication
        try {
          const coverLetterData = await fetchCoverletterById(coverLetterId);
          const newCoverLetterData = {
            ...coverLetterData,
            metadata: {
              ...coverLetterData.metadata,
              userID: user.uid,
              createdAt: new Date(),
            },
          };
          const newCoverLetterId = await duplicateCoverLetter2(newCoverLetterData);
          navigate(`/editor/cover-letter/${newCoverLetterId}`);
        } catch (error) {
          navigate(`/dashboard`);
          console.error('Failed to fetch or clone cover letter:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    handleExampleCoverLetter();
  }, [user, authLoading, navigate, location]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return null;
};

export default CLExampleRedirect;
