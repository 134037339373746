// src/pages/FinishSignUp.js
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAuth, signInWithEmailLink } from 'firebase/auth';
import { app } from '../config/firebaseConfig'; 
import LoadingSpinner from '../components/LoadingSpinner'; 

const auth = getAuth(app);

const FinishSignUp = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const signInWithEmail = async () => {
      const urlParams = new URLSearchParams(location.search);
      let email = urlParams.get('email');

      // If email is not found in URL, check local storage
      if (!email) {
        email = window.localStorage.getItem('emailForSignIn');
      }

      try {
        if (email) {
          await signInWithEmailLink(auth, email, window.location.href);
          // Clear email from local storage
          window.localStorage.removeItem('emailForSignIn');
          navigate('/dashboard'); // Redirect to dashboard or another appropriate page
        } else {
          throw new Error('Email not found in URL or local storage');
        }
      } catch (error) {
        console.error('Error completing sign-up:', error);
        // Handle error, e.g., display an error message to the user
        navigate('/'); // Redirect to home page or error page
      }
    };

    signInWithEmail();
  }, [navigate, location.search]);

  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-50">
      <div className="text-center">
        <h1 className="text-2xl font-bold mb-4">Finishing Sign Up...</h1>
        <p className="text-gray-600 mb-4">Please wait while we complete the sign-up process. This may take a few moments.</p>
        <LoadingSpinner displayType='inline' />
      </div>
    </div>
  );
};

export default FinishSignUp;
