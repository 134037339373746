import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createNewResume } from '../../services/resumeService';
import LoadingSpinner from '../../components/LoadingSpinner';  
import { images } from '../../util/loadImages';

const templateNames = [
  'Professional', 'Executive', 'Modern', 'Visionary', 'Creative', 'Impactful', 'Dynamic',
  'Explorer', 'Classic', 'Elegant', 'Bold', 'Expertise', 'Aspirations',
  'Minimalist', 'Influential', 'Inspired', 'Versatile', 'Vibrant',
  'Proactive', 'Empowering', 'Ambitious', 'Innovative'
];

const ResumeTemplates = ({ userId, showLoginModal }) => {
  const navigate = useNavigate();
  const [templateStyles, setTemplateStyles] = useState(templateNames);  
  const [operationLoading, setOperationLoading] = useState(false);

  const handleCreateNewResume = async (templateName) => {
    if (userId) {
      setOperationLoading(true); 
      try {
        const newResumeId = await createNewResume(templateName, userId);
        navigate(`/editor/resume/${newResumeId}`);
      } catch (error) {
        console.error("Failed to create new resume:", error);
      } finally {
        setOperationLoading(false); 
      }
    } else {
      showLoginModal();
    }
  };

  if (operationLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="container mx-auto px-4 py-6 max-w-6xl">
      <div className='text-center py-4 mb-5'>
        <h1 className="text-2xl font-bold mb-6">Create Job-winning Resume</h1>
        <h2 className='text-xl'>Select Template</h2>
      </div>
      <div className="flex flex-wrap gap-10 all-templates">
        {templateStyles && templateStyles.map((templateStyle, index) => (
          <div
            key={index}
            className="relative flex flex-col justify-between items-center cursor-pointer group"
            onClick={() => handleCreateNewResume(templateStyle)}
          >
            <div className="bg-white p-1 rounded shadow dash-resume relative overflow-hidden" style={{"width":"242px", "height":"321.2px"}}>
              <img
                src={images[`${templateStyle}.png`] || images[`${templateStyle}.jpg`]}
                alt={templateStyle}
                width="312"
                className="w-full"
              />
              <div className="absolute inset-0 flex items-end justify-center bg-gradient-to-t from-white to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <button className="bg-blue-600 text-white text-sm py-2 px-4 rounded mb-4 transform translate-y-full group-hover:translate-y-0 transition-transform duration-300">Use This Template</button>
              </div>
            </div>
            <div className="text-center block px-4 py-2 font-bold text-sm text-left text-gray-600 w-full hover:text-gray-600">
              <h3>{templateStyle.replace(/-/g, ' ')}</h3>
            </div>
          </div>
        ))}
      </div>   
    </div>
  );
};

export default ResumeTemplates;
