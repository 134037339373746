// src/pages/Jobs.js
import React, { useState, useCallback, useEffect } from 'react'; 
import LoadingSpinner from '../components/LoadingSpinner';
import JobCard from '../components/Job/JobCard';
import JobDetails from '../components/Job/JobDetails';
import { fetchSavedJobs } from '../services/resumeService';
import { BiBriefcase, BiMap, BiSearchAlt, BiCalendar  } from 'react-icons/bi';
import { GoHeartFill } from 'react-icons/go';
import { useAuth } from '../context/AuthContext';

const Jobs = () => {
  const { user } = useAuth();
  const [userId, setUserId] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [savedJobs, setSavedJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState({ query: '', location: '', datePosted: '' });
  const [activeTab, setActiveTab] = useState('search'); // 'search' or 'saved'
  const [pageIndex, setPageIndex] = useState(0);
  const [searchInitiated, setSearchInitiated] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // Add state for error message

  const convertDaysAgoToDate = (daysAgo) => {
    const match = daysAgo.match(/(\d+)\s+days? ago/);
    return match ? parseInt(match[1], 10) : Infinity;
  };

  useEffect(() => {
    if (user) {
      setUserId(user.uid);
      fetchSavedJobsForUser();
    }
  }, [user]);
  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    const handleMediaQueryChange = (e) => {
      setSearchInitiated(e.matches);
    };

    handleMediaQueryChange(mediaQuery); // Set the initial state based on the current viewport size
    mediaQuery.addEventListener('change', handleMediaQueryChange);

    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);
  const fetchJobs = useCallback(async (index) => {
    setIsLoading(true);
    setErrorMessage(''); // Clear previous error message
    try {
      const url = new URL('https://rd-server-418cf5202d66.herokuapp.com/search-jobs');
      url.searchParams.append('query', formValues.query);
      url.searchParams.append('location', formValues.location);
      url.searchParams.append('index', index);
      if (formValues.datePosted) {
        url.searchParams.append('datePosted', formValues.datePosted);
      }
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Failed to fetch jobs');
      }
      const data = await response.json();
      const sortedJobs = data.jobs.sort((a, b) => {
        const daysA = convertDaysAgoToDate(a.datePosted);
        const daysB = convertDaysAgoToDate(b.datePosted);
        return daysA - daysB;
      });

      setJobs((prevJobs) => {
        // Combine old and new jobs, and remove duplicates by id
        const combinedJobs = [...prevJobs, ...sortedJobs];
        const uniqueJobs = Array.from(new Set(combinedJobs.map(job => job.id)))
          .map(id => combinedJobs.find(job => job.id === id));
        return uniqueJobs;
      });

      if (sortedJobs.length > 0 && index === 0) {
        setSelectedJob(sortedJobs[0]); // Automatically select the first job if there are new jobs
      }
    } catch (error) {
      console.error('Error fetching jobs:', error);
      setErrorMessage('Failed to fetch jobs. Please try again later.'); // Set error message
    } finally {
      setIsLoading(false);
    }
  }, [formValues]);

  useEffect(() => {
    if (pageIndex > 0) {
      fetchJobs(pageIndex);
    }
  }, [pageIndex, fetchJobs]);

  const loadMoreJobs = () => {
    setPageIndex((prevPageIndex) => prevPageIndex + 1);
  };

  const handleInputChange = (e) => {
    setFormValues(prev => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    setActiveTab('search');
    setPageIndex(0);
    setJobs([]);
    setSearchInitiated(true);
    await fetchSavedJobsForUser(); // Ensure saved jobs are fetched before searching
    fetchJobs(0);
  };

  const handleJobSelect = (job) => {
    setSelectedJob(job);
  };

  const switchTab = async (tabName) => {
    setActiveTab(tabName);
    setSearchInitiated(true);
    if (tabName === 'saved') {
      await fetchSavedJobsForUser();
      if (savedJobs.length > 0) {
        setSelectedJob(savedJobs[0]);
      }
    } else {
      if (jobs.length > 0) {
        setSelectedJob(jobs[0]);
      }
    }
  };

  const removeJobFromState = (jobId) => {
    setSavedJobs((prevJobs) => {
      const updatedJobs = prevJobs.filter(job => job.id !== jobId);
      if (updatedJobs.length > 0) {
        setSelectedJob(updatedJobs[0]); // Set the next job as the selected job
      } else {
        setSelectedJob(null); // No jobs left, clear selection
      }
      return updatedJobs;
    });
  };

  const fetchSavedJobsForUser = async () => {
    setIsLoading(true);
    setErrorMessage(''); // Clear previous error message
    try {
      const jobs = await fetchSavedJobs(userId);
      setSavedJobs(jobs);
      if (activeTab === 'saved' && jobs.length > 0) {
        setSelectedJob(jobs[0]);
      }
    } catch (error) {
      console.error('Error fetching saved jobs:', error);
      setErrorMessage('Failed to fetch saved jobs. Please try again later.'); // Set error message
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {!searchInitiated ? (
        <div className="flex items-center justify-center h-[60%]">
          <div className='flex-wrap'>
            <h2 className="text-2xl font-bold mb-5 text-center">Search jobs</h2>
            <div className="w-full p-3 bg-white rounded-lg shadow-md mx-auto" style={{ width: '1000px' }}>
  <form onSubmit={handleSearchSubmit}>
    <div className="job-search-form flex gap-2 text-md">
      <div className="relative flex-grow">
        <BiBriefcase className="absolute z-10 left-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
        <input
          name="query"
          type="text"
          placeholder="Job Title or Company Name"
          value={formValues.query}
          onChange={handleInputChange}
          className="job-input job-title border-right p-3 pl-12 w-full"
        />
      </div>
      <div className="relative flex-grow-0 flex-basis-1/4">
        <BiMap className="absolute z-10 left-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
        <input
          name="location"
          type="text"
          placeholder="Location"
          value={formValues.location}
          onChange={handleInputChange}
          className="job-input job-location p-3 pl-12 w-full"
        />
      </div>
      <div className="relative flex-grow-0 flex-basis-1/4">
      <BiCalendar className="absolute z-10 left-2 top-1/2 transform -translate-y-1/2 text-gray-400" />       
        <select
          name="datePosted"
          value={formValues.datePosted}
          onChange={handleInputChange}
          className="job-input job-date-posted p-3 pl-12 w-full"
        >
          <option value="">Posted Anytime</option>
          <option value="month">Within 30 days</option>
          <option value="week">Within 7 days</option>
          <option value="3days">Within 3 days</option>
          <option value="today">Within 1 day</option>
        </select>
      </div>
      <button
        type="submit"
        className="job-search-btn flex items-center text-md gap-1.5 ml-3 bg-blue-500 text-white px-4 py-1.3 rounded hover:bg-blue-700 transition-colors duration-300"
      >
        <BiSearchAlt /> Search
      </button>
    </div>
  </form>
</div>

            <div className="flex justify-between">
              <div className="flex gap-5 items-baseline text-sm mt-4">
                <button
                  onClick={() => switchTab('saved')}
                  className={`tab flex items-center text-blue-500 hover:text-blue-700 ${activeTab === 'saved' ? 'active underline' : ''}`}>
                  <GoHeartFill className="mr-1.5" style={{ fill: '#f36262' }} /> Saved Jobs
                </button>
              </div>
              <div className='flex items-center justify-center p-6 text-sm pb-3 text-gray-500 italic'>Explore over 5,00,000+ jobs</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col md:flex-row">
          <div className="flex flex-col w-full md:w-[40%] h-screen border-l border-gray-200">
            <div className='flex flex-col w-full h-screen border-l-10 border-cyan-400'>
              <div className="bg-white p-4 py-6">
                <div className='flex justify-between'>
                  <h2 className="text-lg font-bold mb-2 md:w-[50%]">Search jobs</h2>
                  <div className='flex grow-1 gap-5 items-baseline text-sm'>
                    <button
                      onClick={() => switchTab('search')}
                      className={`tab flex items-center text-blue-500 hover:text-blue-700 ${activeTab === 'search' ? 'active underline' : ''}`}>
                      <BiSearchAlt className='mr-1.5' /> Search Results
                    </button>
                    <button
                      onClick={() => switchTab('saved')}
                      className={`tab flex items-center text-blue-500 hover:text-blue-700 ${activeTab === 'saved' ? 'active underline' : ''}`}>
                      <GoHeartFill className='mr-1.5' style={{ fill: `#f36262` }} /> Saved Jobs
                    </button>
                  </div>
                </div>
                <form onSubmit={handleSearchSubmit} className="space-y-4 w-full">
  <div className="flex flex-col md:flex-row gap-2 text-sm w-full">
    <div className="relative w-full">
      <BiBriefcase className="absolute z-10 left-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
      <input
        name="query"
        type="text"
        placeholder="Job Title"
        value={formValues.query}
        onChange={handleInputChange}
        className="job-input job-title border p-2 rounded pl-10 w-full"
      />
    </div>
  </div>
  <div className="flex flex-col md:flex-row gap-2 text-sm w-full">
    <div className="relative w-full md:flex-1">
      <BiMap className="absolute z-10 left-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
      <input
        name="location"
        type="text"
        placeholder="Location"
        value={formValues.location}
        onChange={handleInputChange}
        className="job-input job-location border p-2 rounded pl-10 w-full"
      />
    </div>
    <div className="relative w-full md:flex-1">
      <BiCalendar className="absolute z-10 left-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
      <select
        name="datePosted"
        value={formValues.datePosted}
        onChange={handleInputChange}
        className="job-input job-date-posted border p-2 rounded pl-10 w-full"
      >
        <option value="">Anytime</option>
        <option value="today">Today</option>
        <option value="3days">Last 3 days</option>
        <option value="week">Last week</option>
        <option value="month">Last month</option>
      </select>
    </div>
    <button type="submit" className="job-search-btn flex items-center gap-3 bg-blue-500 text-white px-3 py-2 text-lg rounded shadow hover:bg-blue-700 transition-colors duration-300 w-full md:w-auto md:px-4">
  <BiSearchAlt /> Search
</button>

  </div>
</form>


              </div>
              <div className="flex-1 bg-white overflow-auto custom-scrollbar p-5">
                {isLoading && activeTab === 'search' && <LoadingSpinner />}
                {errorMessage && <div className="job-error text-red-500 text-center my-2">{errorMessage}</div>}
                {activeTab === 'search' && !selectedJob && !isLoading && !errorMessage &&<div className='flex items-center justify-center h-full text-gray-600'>Enter the job title and location.</div>}
                {(activeTab === 'search' ? jobs : savedJobs).map((job) => (
                  <JobCard
                    key={job.id}
                    job={job}
                    onClick={() => handleJobSelect(job)}
                    active={selectedJob && job.id === selectedJob.id}
                    isSavedJobList={activeTab === 'saved'}
                    isSaved={savedJobs.some(savedJob => savedJob.id === job.id)}
                    removeJobFromList={() => removeJobFromState(job.id)}
                  />
                ))}
                {activeTab === 'search' && jobs.length > 0 && (
                  <div className="flex flex-col items-center">
                    {isLoading && pageIndex > 0 && <div className='my-4 py-6'><LoadingSpinner /></div>}
                    <button onClick={loadMoreJobs} className="load-more-btn bg-gray-200 text-gray-600 my-4 px-4 py-2 text-md rounded hover:text-gray-800 hover:bg-gray-300 transition-colors duration-300">
                      Load more
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full md:w-[60%] h-screen">
            <div className='flex flex-col w-full h-screen'>
              <div className="flex-1 overflow-auto custom-scrollbar p-5">
                {selectedJob ? (
                  <JobDetails job={selectedJob} />
                ) : (
                  <div className='flex items-center justify-center h-full text-gray-600'>Select a job to view details.</div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Jobs;
