import React from 'react';
import ReactFlagsSelect from 'react-flags-select';
import './react-select-language.css'; 

// Mapping language codes to country codes
const langToCountryCode = {
    "en": "US",
    "gb": "GB",
    "es": "ES",
    "mx": "MX",
    "fr": "FR",
    "de": "DE",
    "it": "IT",
    "nl": "NL",
    "pt": "PT",
    "br": "BR",
    "pl": "PL",
    "cz": "CZ",
    "jp": "JP"
};

const LanguageSelector = ({ selected, onSelectLanguage }) => {
  // Convert language code to uppercase country code when selecting
  const handleSelect = (countryCode) => {
    const langCode = Object.keys(langToCountryCode).find(key => langToCountryCode[key] === countryCode);
    onSelectLanguage(langCode);
  };

  // Convert selected language code to country code
  const selectedCountryCode = langToCountryCode[selected];

  return (
    <ReactFlagsSelect
      selected={selectedCountryCode}
      onSelect={handleSelect}
      countries={Object.values(langToCountryCode)}
      customLabels={Object.fromEntries(Object.entries(langToCountryCode).map(([lang, country]) => [country, customLabelMapping[lang]]))}
      placeholder="Select Language"
      searchable={true}
    />
  );
};

// Mapping language codes to labels
const customLabelMapping = {
    "en": "English",
    "gb": "English (UK)",
    "es": "Español",
    "mx": "Español (MX)",
    "fr": "Français",
    "de": "Deutsch",
    "it": "Italiano",
    "nl": "Nederlands",
    "pt": "Português",
    "br": "Português (BR)",
    "pl": "Polski",
    "cz": "Čeština",
    "jp": "日本語"
};

export default LanguageSelector;
