// src/services/firestoreService.js
import { doc, getDoc, setDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../config/firebaseConfig";

export const createUserProfile = async (userId, email, name, subscriptionType) => {
  if (!userId) {
    console.error("userId is undefined.");
    return;
  }
  try {
    await setDoc(doc(db, "users", userId), {
      userId,
      email,
      name,
      subscriptionType,
      createdAt: serverTimestamp(),
    });
    console.log("User profile created/updated successfully.");
  } catch (error) {
    console.error("Error creating/updating user profile: ", error);
  }
};

export const checkUserExists = async (userId) => {
  const docRef = doc(db, "users", userId);
  const docSnap = await getDoc(docRef);
  return docSnap.exists();
};
