import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import LoadingSpinner from '../components/LoadingSpinner';
import { fetchResumeById, duplicateResume2 } from '../services/resumeService';
import { storage } from "../config/firebaseConfig"; 
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

const ExampleRedirect = () => {
  const { user, loading: authLoading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const hasProcessed = useRef(false);

  const duplicatePhoto = async (photoURL) => {
    try {
      if (!photoURL) {
        console.error("No photo URL provided for duplication.");
        return null;
      }
      const response = await fetch(photoURL);
      const blob = await response.blob();
      const fileName = `${new Date().getTime()}.jpg`;
      const file = new File([blob], fileName, { type: 'image/jpeg' });
      const storageRef = ref(storage, `images/${fileName}`);
      const snapshot = await uploadBytes(storageRef, file);
      const newPhotoURL = await getDownloadURL(snapshot.ref);
      console.log("Duplicated image URL: ", newPhotoURL);
      return newPhotoURL;
    } catch (error) {
      console.error("Error duplicating photo:", error);
      throw error;
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const resumeId = queryParams.get('id');

    const handleExampleResume = async () => {
      if (authLoading || hasProcessed.current) return;

      if (!user) {
        navigate(`/login?redirect=${location.pathname}${location.search}`);
      } else {
        hasProcessed.current = true; // Mark as processed to prevent duplication
        try {
          const resumeData = await fetchResumeById(resumeId);

          const photoURL = resumeData.formObject.personalDetails.inputGroups[0].inputs.find(input => input.inputKey === "photo")?.inputValue;
          let newPhotoURL = null;
          if (photoURL) {
            newPhotoURL = await duplicatePhoto(photoURL);
          }
          const newResumeData = {
            ...resumeData,
            metadata: {
              ...resumeData.metadata,
              resumeID:'',
              userID: user.uid,
              createdAt: new Date(),
            },
            formObject: {
              ...resumeData.formObject,
              personalDetails: {
                ...resumeData.formObject.personalDetails,
                inputGroups: resumeData.formObject.personalDetails.inputGroups.map(group => ({
                  ...group,
                  inputs: group.inputs.map(input => 
                    input.inputKey === "photo" ? { ...input, inputValue: newPhotoURL } : input
                  ),
                })),
              },
            },
          };
          const newResumeId = await duplicateResume2(resumeId, newResumeData);
          navigate(`/editor/resume/${newResumeId}`);
        } catch (error) {
          navigate(`/dashboard`);
          console.error('Failed to fetch or clone resume:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    handleExampleResume();
  }, [user, authLoading, navigate, location]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return null;
};

export default ExampleRedirect;
