import React, { useState } from 'react';
import { FaChevronDown, FaCheck } from 'react-icons/fa';
import { TbSection } from 'react-icons/tb'; // Ensure this icon is imported

const CustomDropdown = ({ label, options, value, onChange, className, icon: Icon }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleDropdown = () => setIsOpen(!isOpen);

  const handleSelectOption = (selectedValue) => {
    if (value !== selectedValue) {
      onChange(selectedValue);
    }
    setIsOpen(false);
  };

  return (
    <div className={`custom-dropdown ${className}`} onBlur={() => setIsOpen(false)} tabIndex="0">
      <div className={`custom-dropdown-header ${isOpen ? 'active' : ''}`}  onClick={handleToggleDropdown}>
        {Icon && <Icon className="custom-dropdown-icon" />}
        {/* <span className="custom-dropdown-value">{options.find(option => option.value === value)?.label}</span>
        <FaChevronDown className="custom-dropdown-chevron" /> */}
      </div>
      {isOpen && (
        <>      
        <ul className="custom-dropdown-list">
          <li><span className="custom-dropdown-title">{label}</span></li>
          {options.map((option) => (
            <li key={option.value} onClick={() => handleSelectOption(option.value)} className={value === option.value ? 'active' : ''}>
              {option.label}
              {value === option.value && <FaCheck className="custom-dropdown-checkmark" />}
            </li>
          ))}
        </ul>
        </>
      )}
    </div>
  );
};

export default CustomDropdown;
