const CustomSectionForm = {
    custom: {
      "sectionTitle": "Custom Section",
      "customSectionTitle": "",
      "icon": "✨",
      "sectionID": `${Math.random().toString(36).substr(2, 9)}`,
      "isShow": true,
      "isDisplay":true,
      "onLoad": true,
      "sectionType": "other",
      "inputGroups": [
        {
          "id":`${Math.random().toString(36).substr(2, 9)}`,
          "isExpanded": true, 
          "aiWriter": {
            "isStatus": true,
            "actions": [{"id":"rewrite"}]
          }, 
          "inputs": [
            {
              "inputLabel": "Title",
              "inputType": "text",
              "placeholder": "Enter the title of your custom entry",
              "show": true,
              "required": true,
              "inputValue": ""
            },
            {
              "inputLabel": "Sub Title",
              "inputType": "text",
              "placeholder": "Enter the title of your custom entry",
              "show": true,
              "required": false,
              "inputValue": ""
            },
            {
              "inputLabel": "Description",
              "inputType": "textarea",
              "placeholder": "Describe the details of your custom entry",
              "show": true,
              "required": true,
              "inputValue": ""
            }
          ]
        }
      ],
      "allowMultiple": false,
      "addButtonLabel": "Add Another Entry",       
    }
  }
  export default CustomSectionForm;
