import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { BiUser, BiChevronDown, BiChevronUp, BiHome, BiBrain, BiConversation, BiBriefcase, BiDollarCircle, BiSupport, BiLogOut, BiShield } from 'react-icons/bi';
import { doc, getDoc } from "firebase/firestore";
import { db } from "../config/firebaseConfig";

// Importing images directly
import cvdesignerLogo from '../images/cvdesigner-logo.png';
import resumeDesignLogo from '../images/resumedesign-logo.png';

const Sidebar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [adminEmail, setAdminEmail] = useState(null);
  const dropdownRef = useRef(null);
  const { user, logout } = useAuth();
  const location = useLocation();

  const handleLogout = async () => {
    try {
      await logout();
      console.log('Logout successful');
    } catch (error) {
      console.error('Error logging out: ', error);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownOpen]);

  const truncateName = (name, length = 15) => {
    if (name.length > length) {
      return name.substring(0, length) + '...';
    }
    return name;
  };

  const getNavItemClass = (path) => {
    return location.pathname === path ? 'mb-2 flex items-center active' : 'mb-2 flex items-center';
  };

  const getDropdownItemClass = (path) => {
    return location.pathname === path ? 'px-4 py-2 hover:bg-gray-100 flex items-center active' : 'px-4 py-2 hover:bg-gray-100 flex items-center';
  };

  const handleLinkClick = () => {
    setIsDropdownOpen(false);
  };

  // Fetch admin email from Firestore
  useEffect(() => {
    const fetchAdminEmail = async () => {
      try {
        const docRef = doc(db, "admin", "IStA6fJzjiHovjcz6B0V");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setAdminEmail(data.adminUser[0]);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching admin email: ", error);
      }
    };
    fetchAdminEmail();
  }, []);

  // Determine the logo image based on the environment variable
  const logoImage = process.env.REACT_APP_LOGO_IMAGE === 'cvdesigner-logo.png' ? cvdesignerLogo : resumeDesignLogo;
  const logoClass = process.env.REACT_APP_LOGO_IMAGE === 'cvdesigner-logo.png' ? 'cvdesigner-logo' : 'resumedesign-logo';

  const isAdmin = Boolean(localStorage.getItem('selectedUserId')) || (user && user.email === adminEmail);

  return (
    <aside className="sidebar w-64 h-full text-white flex flex-col justify-between">
      <div>
        <div className={`flex p-4 h-16 ${logoClass}`}>
          <div className="logo">
            <img src={logoImage} alt="Logo" className="logo-img" />
          </div>
        </div>

        {/* Navigation Menu */}
        <nav className="py-6">
          <ul>
            <li className={getNavItemClass('/dashboard')}>
              <Link to="/dashboard" className="flex hover:text-gray-300" onClick={handleLinkClick}>
                <BiHome className="mr-4 text-xl" /> My Dashboard
              </Link>
            </li>
            <li className={getNavItemClass('/resume-analysis')}>
              <Link to="/resume-analysis" className="flex hover:text-gray-300" onClick={handleLinkClick}>
                <BiBrain className="mr-4 text-xl" /> AI Resume Analysis
              </Link>
            </li>
            <li className={getNavItemClass('/job-interview')}>
              <Link to="/job-interview" className="flex hover:text-gray-300" onClick={handleLinkClick}>
                <BiConversation className="mr-4 text-xl" /> Job Interview
              </Link>
            </li>
            <li className={getNavItemClass('/jobs')}>
              <Link to="/jobs" className="flex hover:text-gray-300" onClick={handleLinkClick}>
                <BiBriefcase className="mr-4 text-xl" /> Find Jobs
              </Link>
            </li>
            {isAdmin && (
              <li className={getNavItemClass('/admin')}>
                <Link to="/admin" className="flex hover:text-gray-300" onClick={handleLinkClick}>
                  <BiShield className="mr-4 text-xl" /> Admin
                </Link>
              </li>
            )}
          </ul>
        </nav>
      </div>

      <div className="relative float-left m-2" ref={dropdownRef}>
        {/* User Account Section */}
        {user ? (
          <button
            className="flex items-center text-gray-700 border p-2 rounded hover:bg-gray-100 w-full"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <BiUser className="text-xl" />
            <span className="ml-2 text-sm">{user.displayName ? truncateName(user.displayName) : "Your Account"}</span>
            {isDropdownOpen ? <BiChevronUp className="ml-auto" /> : <BiChevronDown className="ml-auto" />}
          </button>
        ) : (
          <Link to="/login" className="flex items-center justify-center text-gray-700 border p-2 bg-blue-500 rounded text-white hover:bg-blue-700 rounded w-full">
            <BiUser className="text-xl" />
            <span className="ml-2 text-sm">Sign In to Your Account</span>
          </Link>
        )}

        {/* Dropdown Menu */}
        {user && isDropdownOpen && (
          <ul className="absolute bottom-full mb-3 left-0 w-full bg-white text-gray-700 py-2 rounded shadow-lg">
            <li className={getDropdownItemClass('/my-account')}>
              <Link to="/my-account" className="flex w-full items-center text-sm" onClick={handleLinkClick}>
                <BiUser className="mr-3 text-lg" />My Account
              </Link>
            </li>
            <li className={getDropdownItemClass('/plans')}>
              <Link to="/plans" className="flex w-full items-center text-sm" onClick={handleLinkClick}>
                <BiDollarCircle className="mr-3 text-lg" />Plans
              </Link>
            </li>
            <li className={getDropdownItemClass('/support')}>
              <Link to="/support" className="flex w-full items-center text-sm" onClick={handleLinkClick}>
                <BiSupport className="mr-3 text-lg" />Support
              </Link>
            </li>
            <li className="px-4 py-2 hover:bg-gray-100 flex items-center" onClick={() => { handleLogout(); handleLinkClick(); }}>
              <span className="flex items-center w-full cursor-pointer text-sm">
                <BiLogOut className="mr-3 text-lg" />Logout
              </span>
            </li>
          </ul>
        )}
      </div>
    </aside>
  );
};

export default Sidebar;
