// src/components/Header.js
import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import {  BiUser, BiChevronDown, BiChevronUp, BiHome, BiBrain, BiConversation, BiBriefcase, BiDollarCircle, BiSupport, BiLogOut, BiShield  } from 'react-icons/bi';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebaseConfig';
import cvdesignerLogo from '../images/cvdesigner-logo.png';
import resumeDesignLogo from '../images/resumedesign-logo.png';

const Header = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [adminEmail, setAdminEmail] = useState(null);
  const dropdownRef = useRef(null);
  const { user, logout } = useAuth();

  const handleLogout = async () => {
    try {
      await logout();
      console.log('Logout successful');
    } catch (error) {
      console.error('Error logging out: ', error);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownOpen]);

  const truncateName = (name, length = 15) => {
    if (name.length > length) {
      return name.substring(0, length) + '...';
    }
    return name;
  };

  useEffect(() => {
    const fetchAdminEmail = async () => {
      try {
        const docRef = doc(db, 'admin', 'IStA6fJzjiHovjcz6B0V');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setAdminEmail(data.adminUser[0]);
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching admin email: ', error);
      }
    };
    fetchAdminEmail();
  }, []);

  const logoImage = process.env.REACT_APP_LOGO_IMAGE === 'cvdesigner-logo.png' ? cvdesignerLogo : resumeDesignLogo;
  const logoClass = process.env.REACT_APP_LOGO_IMAGE === 'cvdesigner-logo.png' ? 'cvdesigner-logo' : 'resumedesign-logo';

  const isAdmin = Boolean(localStorage.getItem('selectedUserId')) || (user && user.email === adminEmail);

  return (
    <header className="header fixed top-0 left-0 right-0 bg-white shadow-md z-50 flex justify-between items-center px-4 py-2 lg:hidden">
      <div className={`logo ${logoClass}`}>
        <img src={logoImage} alt="Logo" className="logo-img" />
      </div>
      <div className="relative" ref={dropdownRef}>
        <button
          className="flex items-center text-gray-700"
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <BiUser className="text-xl" />
          {isDropdownOpen ? <BiChevronUp className="ml-2" /> : <BiChevronDown className="ml-2" />}
        </button>
        {isDropdownOpen && (
          <ul className="absolute right-0 mt-2 w-48 bg-white border rounded shadow-lg">
            {user && (
              <>
                <li className="px-4 py-2 hover:bg-gray-100 flex items-center">
                  <Link to="/my-account" className="flex items-center w-full" onClick={() => setIsDropdownOpen(false)}>
                    <BiUser className="mr-3 text-lg" />My Account
                  </Link>
                </li>
                <li className="px-4 py-2 hover:bg-gray-100 flex items-center">
                  <Link to="/plans" className="flex items-center w-full" onClick={() => setIsDropdownOpen(false)}>
                    <BiDollarCircle className="mr-3 text-lg" />Plans
                  </Link>
                </li>
                <li className="px-4 py-2 hover:bg-gray-100 flex items-center">
                  <Link to="/support" className="flex items-center w-full" onClick={() => setIsDropdownOpen(false)}>
                    <BiSupport className="mr-3 text-lg" />Support
                  </Link>
                </li>
                <li className="px-4 py-2 hover:bg-gray-100 flex items-center cursor-pointer" onClick={() => { handleLogout(); setIsDropdownOpen(false); }}>
                  <span className="flex items-center w-full">
                    <BiLogOut className="mr-3 text-lg" />Logout
                  </span>
                </li>
              </>
            )}
          </ul>
        )}
      </div>
    </header>
  );
};

export default Header;
