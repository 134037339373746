import React, { useEffect, useRef } from 'react';

const AiPopover = ({ children, isVisible, onClose, sectionKey }) => {
  const popoverRef = useRef(null);

  // Effect for adding/removing event listener
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        onClose(); // Call onClose prop if click is outside
      }
    };

    // Add when the popover is visible
    if (isVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Cleanup
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisible, onClose]); // Re-run when isVisible changes

  if (!isVisible) return null;

  return (
    <div
      ref={popoverRef}
      className="popover-menu absolute mt-2 text-sm rounded-lg shadow-lg bg-white z-10 px-3 py-3"
      // Adjust positioning as needed
      style={{ bottom: '100%', right: '0' }}
    >
      {children}
    </div>
  );
};

export default AiPopover;
