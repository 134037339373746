// src/components/Layout.js
import React from 'react';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import Header from '../components/Header';

const Layout = ({ children }) => {
  return (
    <div className="dashboard-container">
    <Sidebar />
    <Header />
    <main className="main-content">{children}</main>
    <Footer />
  </div>
  );
};

export default Layout;
