// components/LoginModal.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { app } from "../config/firebaseConfig";
import { createUserProfile, checkUserExists } from '../services/firestoreService';
import { getAuth, GoogleAuthProvider, sendSignInLinkToEmail, signInWithPopup } from "firebase/auth";
import Modal from './Modal'; // Ensure Modal component is available
import { TbCircleCheck } from "react-icons/tb";

// Importing images directly
import cvdesignerLogo from '../images/cvdesigner-logo.png';
import resumeDesignLogo from '../images/resumedesign-logo.png';

const auth = getAuth(app);

const LoginModal = ({ isOpen, closeModal }) => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { user, signInWithGoogle } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/dashboard');
      closeModal(); // Close modal on successful login
    }
  }, [user, navigate, closeModal]);

  const displayError = (message) => {
    setErrorMessage(message);
    setTimeout(() => {
      setErrorMessage("");
    }, 5000);
  };

  const handleEmailLinkSignup = async (e) => {
    e.preventDefault();
    const actionCodeSettings = {
      url: process.env.REACT_APP_FINISH_SIGN_UP_URL,
      handleCodeInApp: true,
    };

    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', email);
      displayError("Check your email for the sign-in link.");
    } catch (error) {
      console.error("Error sending sign-in link: ", error);
      displayError(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      const { user } = result;
      const userExists = await checkUserExists(user.uid);

      if (!userExists) {
        await createUserProfile(user.uid, user.email, user.displayName, "Free");
      }
      closeModal(); // Close modal on successful login
    } catch (error) {
      console.error("Error signing in with Google:", error);
      displayError(error.message);
    }
  };

  const logoImage = process.env.REACT_APP_LOGO_IMAGE === 'cvdesigner-logo.png' ? cvdesignerLogo : resumeDesignLogo;
  const logoClass = process.env.REACT_APP_LOGO_IMAGE === 'cvdesigner-logo.png' ? 'cvdesigner-login' : 'resumedesign-login';

  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <div className="flex flex-col items-center justify-center p-8 pt-0">
      {/*   <div className={`flex p-4 mb-8 ${logoClass}`}>
          <img src={logoImage} alt="Logo" className="logo-img" />
        </div> */}
        <h1 className="text-xl font-bold text-gray-700 mb-2">Sign In to Your Account</h1>
        <p className='text-sm mb-3 text-gray-500'>Create and save your Resume/CV effortlessly.</p>

        <button
          onClick={handleGoogleSignIn}
          className="mt-5 w-full p-2 bg-gray-100 border hover:bg-gray-300 text-black rounded flex items-center justify-center border-gray-500 focus:outline-none"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" className="w-6 h-6 mr-2">
            <path fill="#4285F4" d="M44.5 20H24v8.5h11.8C33.4 33.8 29 36 24 36c-6.6 0-12-5.4-12-12s5.4-12 12-12c3 0 5.6 1.1 7.6 2.9l5.8-5.8C34.1 6 29.3 4 24 4 12.9 4 4 12.9 4 24s8.9 20 20 20c10.6 0 19.3-8.1 19.9-18.5H44.5z"/>
            <path fill="#34A853" d="M24 38c4.7 0 8.6-1.5 11.5-4.1l-5.5-4.1c-1.6 1.1-3.6 1.8-6 1.8-4.6 0-8.5-3.1-9.9-7.4H7.6v4.7C10.4 32.9 16.8 38 24 38z"/>
            <path fill="#FBBC05" d="M13.9 24c-.4-1.2-.6-2.5-.6-3.8s.2-2.6.6-3.8V11.7H7.6C5.9 15.3 5 19.5 5 24s.9 8.7 2.6 12.3l6.3-4.7z"/>
            <path fill="#EA4335" d="M24 10c2.6 0 4.9.9 6.7 2.4l5.1-5.1C32.6 5.2 28.5 4 24 4c-4.6 0-8.9 1.4-12.4 4.1l6.3 4.7C19.5 11.4 21.6 10 24 10z"/>
          </svg>
          Signup with Google
        </button>
        <div className="flex items-center my-4">
          <div className="flex-grow border-t border-gray-300"></div>
          <span className="mx-4 text-gray-500 pl-2 pr-2">Or</span>
          <div className="flex-grow border-t border-gray-300"></div>
        </div>
        {errorMessage && (
          <div className="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg" role="alert">
            {errorMessage}
          </div>
        )}
        <form onSubmit={handleEmailLinkSignup} className="w-full max-w-sm">
          <input
            type="email"
            placeholder="Email address"
            className="w-full p-2 mb-4 border rounded"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button type="submit" className="w-full p-2 mb-4 bg-blue-500 rounded text-white hover:bg-blue-700">
            Send Sign-Up Link
          </button>
        </form>
      </div>
    </Modal>
  );
};

export default LoginModal;
