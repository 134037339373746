import { db } from "../config/firebaseConfig";
import { doc, addDoc, setDoc, collection, serverTimestamp } from "firebase/firestore";
import CoverLetterModal from '../modals/CoverLetterModal';

const generateUniqueId = () => {
  return '_' + Math.random().toString(36).substr(2, 9);
};

const fetchWithRetry = async (url, options, retries = 3) => {
  console.log('Triggering fetchWithRetry');
  for (let attempt = 1; attempt <= retries; attempt++) {
    try {
      const response = await fetch(url, options);
      console.log('Response received', response);
      if (!response.ok) {
        throw new Error(`Attempt ${attempt} failed: ${response.statusText}`);
      }
      return response.json();  // Parse the response as JSON
    } catch (error) {
      console.error(error);
      if (attempt === retries) {
        throw new Error(`Failed after ${retries} attempts`);
      }
    }
  }
};

export const createNewCoverLetterWithAI = async (formData, navigate) => {
  const { firstName, lastName, jobTitle, email, phone, userID, yearsOfExperience = 5 } = formData;
  const styles = ["Visionary", "Influential", "Professional", "Modern", "Creative", "Impactful", "Explorer", "Classic", "Bold", "Elegant", "Expertise", "Inspired", "Versatile", "Vibrant", "Ambitious", "Innovative"];
  const randomStyle = styles[Math.floor(Math.random() * styles.length)];

  console.log(CoverLetterModal); // Check CoverLetterModal structure
  if (!CoverLetterModal.formObject || !CoverLetterModal.formObject.personalDetails || !CoverLetterModal.formObject.employerDetails || !CoverLetterModal.formObject.letterContent) {
    console.error('CoverLetterModal structure is not as expected');
    return;
  }

  const initialCoverLetterData = {
    formObject: {
      ...CoverLetterModal.formObject,
      personalDetails: {
        ...CoverLetterModal.formObject.personalDetails,
        inputs: CoverLetterModal.formObject.personalDetails.inputs.map(input => {
          switch (input.inputKey) {
            case 'firstName':
              return { ...input, inputValue: firstName || 'Robert' };
            case 'lastName':
              return { ...input, inputValue: lastName || 'Mcurry' };
            case 'emailAddress':
              return { ...input, inputValue: email || 'support@cvdesigner.ai' };
            case 'phoneNumber':
              return { ...input, inputValue: phone || '1234567890' };
              case 'streetNumber':
                return { ...input, inputValue: '7th Avenue' };
              case 'city':
                return { ...input, inputValue: 'New York' };
              case 'postalCode':
                return { ...input, inputValue: '1005' };
              case 'country':
                return { ...input, inputValue: 'United States' };
              case 'linkedin':
                return { ...input, inputValue: 'Linkedin.com' };
            default:
              return input;
          }
        }),
      },
      employerDetails: {
        ...CoverLetterModal.formObject.employerDetails,
        inputs: CoverLetterModal.formObject.employerDetails.inputs.map(input => {
          switch (input.inputKey) {
            case 'title':
              return { ...input, inputValue: 'Mr' };
            case 'firstName':
              return { ...input, inputValue: 'James' };
            case 'lastName':
              return { ...input, inputValue: 'Dow' };
            case 'companyName':
              return { ...input, inputValue: 'Amazon' };
            case 'department':
              return { ...input, inputValue: 'HR Department' };
            case 'streetNumber':
                return { ...input, inputValue: '5th Avenue' };
            case 'city':
               return { ...input, inputValue: 'Los Angeles' };
            case 'postalCode':
              return { ...input, inputValue: '5002' };
            case 'country':
              return { ...input, inputValue: 'United States' };
            default:
              return input;
          }
        }),
      },
      letterContent: {
        ...CoverLetterModal.formObject.letterContent,
        inputs: CoverLetterModal.formObject.letterContent.inputs.map(input => {
          switch (input.inputKey) {
            // If you have any specific keys to update, add them here
            default:
              return input;
          }
        }),
      },
    },
    metadata: {
      ...CoverLetterModal.metadata,
      userID: userID,
      createdAt: serverTimestamp(),
      selectedStyle: randomStyle,
      templateName: `CoverLetter-${jobTitle}`,
    },
  };

  console.log(initialCoverLetterData); // Check the structure of initialCoverLetterData

  try {
    const url = 'https://rd-server-418cf5202d66.herokuapp.com/ai-coverletter/letterBody'; //http://localhost:3002
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ firstName, lastName, jobTitle, yearsOfExperience }),
    };     

    const letterBody = await fetchWithRetry(url, options);
    //console.log('letterBody:', letterBody.content); // Check the content of letterBody
    const letterData = letterBody.content.replace(/\n/g, '<br />\n');

    const letterContentInputs = [
      {
        inputKey: "subject",
        inputLabel: "Subject",
        inputType: "text",
        placeholder: "Enter the subject of your cover letter",
        show: true,
        required: true,
        inputValue: `${jobTitle}`,
      },
      {
        inputKey: "date",
        inputLabel: "Date",
        inputType: "text",
        placeholder: "Enter today's date",
        show: true,
        required: true,
        inputValue: new Date().toISOString().split('T')[0],
      },
      {
        inputKey: "letterBody",
        inputLabel: "Letter Body",
        inputType: "textarea",
        placeholder: "Write the content of your letter here.",
        show: true,
        required: true,
        inputValue: `${letterData}`,  
      },
      {
        inputKey: "signature",
        inputLabel: "Signature",
        inputType: "text",
        placeholder: "Your Name",
        show: true,
        required: true,
        inputValue: `${firstName} ${lastName}`,
      },
    ];

    const finalCoverLetterData = {
      ...initialCoverLetterData,
      formObject: {
        ...initialCoverLetterData.formObject,
        letterContent: {
          ...initialCoverLetterData.formObject.letterContent,
          inputs: letterContentInputs,
        },
      },
    };

    console.log(finalCoverLetterData); // Check the structure of finalCoverLetterData

    const docRef = await addDoc(collection(db, "cover-letters"), finalCoverLetterData);
    const coverLetterID = docRef.id;
    await setDoc(doc(db, "cover-letters", coverLetterID), {
      ...finalCoverLetterData,
      metadata: {
        ...finalCoverLetterData.metadata,
        coverLetterID: coverLetterID,
        fontSize: "L",
        lineHeight:"1.55",
      },
    }, { merge: true });

    if (coverLetterID) {
      navigate(`/editor/cover-letter/${coverLetterID}`);
    }

    return coverLetterID;
  } catch (error) {
    console.error('Error creating new cover letter with AI:', error);
    throw error;
  }
};
