import React from 'react';

const LoadingSpinner = ({ displayType = 'block' }) => {
  // Define spinner classes based on displayType
  const spinnerClass = displayType === 'inline'
    ? "animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-blue-500"
    : displayType === 'normal'
    ? "animate-spin rounded-full h-14 w-14 border-t-4 border-b-4 border-blue-500"
    : "animate-spin rounded-full h-14 w-14 border-t-4 border-b-4 border-blue-500";

  // Define container classes based on displayType
  const containerClass = displayType === 'block'
    ? "flex justify-center items-center h-full min-h-screen"
    : displayType === 'normal'
    ? "flex justify-center"
    : "inline-flex";

  return (
    <div className={containerClass}>
      <div className="loaderBox"></div>
    </div>
  );
};

export default LoadingSpinner;
/* HTML: <div class="loader"></div> */
