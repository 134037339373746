import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'; 
import { app } from "../config/firebaseConfig";
import { getAuth, GoogleAuthProvider, signInWithPopup, sendSignInLinkToEmail } from "firebase/auth";
import { checkUserExists, createUserProfile } from '../services/firestoreService';
import { TbCircleCheck } from "react-icons/tb";
import cvdesignerLogo from '../images/cvdesigner-logo.png';
import resumeDesignLogo from '../images/resumedesign-logo.png';

const auth = getAuth(app);

const Login = () => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { user, signInWithGoogle } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (user) {
      const redirectTo = new URLSearchParams(location.search).get('redirect') || '/dashboard';
      navigate(redirectTo);
    }
  }, [user, navigate, location]);

  const displayError = (message) => {
    setErrorMessage(message);
    setTimeout(() => {
      setErrorMessage("");
    }, 5000);
  };

  const handleEmailLinkSignup = async (e) => {
    e.preventDefault();
    const actionCodeSettings = {
      url: `${window.location.origin}/finish-sign-up`,
      handleCodeInApp: true,
    };

    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', email);
      displayError("Check your email for the sign-in link.");
    } catch (error) {
      console.error("Error sending sign-in link: ", error);
      displayError(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      const { user } = result;
      const userExists = await checkUserExists(user.uid);

      if (!userExists) {
        await createUserProfile(user.uid, user.email, user.displayName, "Free");
      }
      
      const redirectTo = new URLSearchParams(location.search).get('redirect') || '/dashboard';
      navigate(redirectTo);
    } catch (error) {
      console.error("Error signing in with Google:", error);
      displayError(error.message);
    }
  };

  const logoImage = process.env.REACT_APP_LOGO_IMAGE === 'cvdesigner-logo.png' ? cvdesignerLogo : resumeDesignLogo;
  const logoClass = process.env.REACT_APP_LOGO_IMAGE === 'cvdesigner-logo.png' ? 'cvdesigner-login' : 'resumedesign-login';

  return (
    <div className="flex min-h-screen bg-blue-50 login-page">
      <div className="w-1/2 bg-blue-50 flex items-center justify-center p-5 login-col-left">
        <div className="text-left max-w-md">
          
<div className='text-center mt-[-50px] mb-7'>
<h2 className="text-3xl font-bold mb-2">🚀Accelerate Your Career</h2>
<h3 className="text-md mb-6">Build a Job-winning Perfect Resume / CV in just 5 minutes.</h3>
</div>
        <div class="mt-8 flex justify-center relative">
    <img src="https://app.cvdesigner.ai/static/media/Professional.ac2547101d1de9c53842.png" alt="Professional Template" class="shadow-lg rounded-lg max-w-[350px] ml-[-250px]" />
    <img src="https://app.cvdesigner.ai/static/media/Influential.641f11a044e5532b51f3.png" alt="Influential Template" class="absolute shadow-2xl rounded-lg max-w-[350px] ml-[50px] mt-[50px] mr-[-100px]" />
</div>


          {/* <h2 className="text-3xl font-bold mb-2">Accelerate Your Career</h2>
          <h3 className="text-md mb-6">Build a Job-winning Perfect Resume & CV in just 5 minutes with AI</h3>
          <ul className="space-y-4">
            <li className="flex items-start">
              <TbCircleCheck className="w-6 h-6 mr-4 text-green-500" />
              <span><strong>Boost Your Career:</strong> Top AI resume builder.</span>
            </li>
            <li className="flex items-start">
              <TbCircleCheck className="w-6 h-6 mr-4 text-green-500" />
              <span><strong>Stand Out:</strong> ATS-friendly Resume/CV templates.</span>
            </li>
            <li className="flex items-start">
              <TbCircleCheck className="w-6 h-6 mr-4 text-green-500" />
              <span><strong>AI Score:</strong> Get performance score for your resume.</span>
            </li>
            <li className="flex items-start">
              <TbCircleCheck className="w-6 h-6 mr-4 text-green-500" />
              <span><strong>Job Search:</strong> Find jobs that perfectly match your skills.</span>
            </li>
          </ul>
          <p className="mt-8 text-blue-500">
            Get inspired by <a href="#" className="font-bold">200+ Free Resume Examples and Templates</a>
          </p> */}
        </div>
      </div>
      <div className="w-1/2 bg-white flex flex-col items-center justify-center p-8 login-col-right">
        <div className={`flex p-4 mb-12 ${logoClass}`}>
          <img src={logoImage} alt="Logo" className="logo-img" />
        </div>
        <h1 className="text-lg font-bold mb-6">Welcome, Sign In to Your Account.</h1> 
        <div className="w-full max-w-sm">
          <button
            onClick={handleGoogleSignIn}
            className="mt-7 w-full p-2 bg-gray-100 border hover:bg-gray-300 text-black rounded flex items-center border-gray-500 justify-center"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" className="w-6 h-6 mr-2">
              <path fill="#4285F4" d="M44.5 20H24v8.5h11.8C33.4 33.8 29 36 24 36c-6.6 0-12-5.4-12-12s5.4-12 12-12c3 0 5.6 1.1 7.6 2.9l5.8-5.8C34.1 6 29.3 4 24 4 12.9 4 4 12.9 4 24s8.9 20 20 20c10.6 0 19.3-8.1 19.9-18.5H44.5z"/>
              <path fill="#34A853" d="M24 38c4.7 0 8.6-1.5 11.5-4.1l-5.5-4.1c-1.6 1.1-3.6 1.8-6 1.8-4.6 0-8.5-3.1-9.9-7.4H7.6v4.7C10.4 32.9 16.8 38 24 38z"/>
              <path fill="#FBBC05" d="M13.9 24c-.4-1.2-.6-2.5-.6-3.8s.2-2.6.6-3.8V11.7H7.6C5.9 15.3 5 19.5 5 24s.9 8.7 2.6 12.3l6.3-4.7z"/>
              <path fill="#EA4335" d="M24 10c2.6 0 4.9.9 6.7 2.4l5.1-5.1C32.6 5.2 28.5 4 24 4c-4.6 0-8.9 1.4-12.4 4.1l6.3 4.7C19.5 11.4 21.6 10 24 10z"/>
            </svg>
            Signup with Google
          </button>

          <div className="flex items-center my-8">
            <div className="flex-grow border-t border-gray-300"></div>
            <span className="mx-4 text-gray-500 pl-2 pr-2">Or</span>
            <div className="flex-grow border-t border-gray-300"></div>
          </div>
          {errorMessage && (
            <div className="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg" role="alert">
              {errorMessage}
            </div>
          )}
          <form onSubmit={handleEmailLinkSignup} className="w-full max-w-sm">
            <input
              type="email"
              placeholder="Email address"
              className="w-full p-2 mb-4 border rounded"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button type="submit" className="w-full p-2 mb-4 bg-blue-500 rounded text-white hover:bg-blue-700">
              Send Sign-Up Link
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
