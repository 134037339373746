// src/components/Job/JobCard.js
import React, { useState, useEffect } from 'react';
import { saveJob, deleteSavedJob } from '../../services/resumeService';
import { BiMap, BiBriefcaseAlt2, BiAlarm, BiTrash } from 'react-icons/bi';
import { GoHeart, GoHeartFill } from "react-icons/go";
import { useAuth } from '../../context/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const JobCard = ({ job, onClick, active, isSavedJobList, isSaved, removeJobFromList }) => {
  const [isSavedState, setIsSavedState] = useState(isSaved); // Track whether the job is saved
  const [userId, setUserId] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState('');
  const { title, id, fileId, company, image, location, employmentType, datePosted } = job;
  const activeStyle = active ? 'bg-gray-200' : '';
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      setUserId(user.uid);
      const colors = ['bg-red-400', 'bg-blue-400', 'bg-green-400', 'bg-yellow-400', 'bg-purple-400'];
      setBackgroundColor(colors[Math.floor(Math.random() * colors.length)]);
    }
  }, [user]);

  const handleSaveJob = async () => {
    if (isSavedState) {
      return;
    }
    try {
      await saveJob(userId, job);
      setIsSavedState(true); // Update the saved state to true
      toast.success('Job saved successfully!');
    } catch (error) {
      console.error('Failed to save job:', error);
      toast.error('Failed to save job.');
    }
  };

  const handleDeleteJob = async (id, jobId) => {
    try {
      await deleteSavedJob(userId, jobId);
      toast.success('Job deleted successfully!');
      removeJobFromList(id);
      setIsSavedState(false); // Reset the saved state to false
    } catch (error) {
      console.error('Failed to delete job:', error);
      toast.error('Failed to delete job.');
    }
  };

  const imageContent = (!image || image === "https://fonts.gstatic.com/s/i/googlematerialicons/auto_stories/v8/gm_grey-24dp/1x/gm_auto_stories_gm_grey_24dp.png") ?
    <div className={`w-14 h-14 rounded ${backgroundColor} flex items-center justify-center text-white font-bold text-2xl`}>
      {company[0]}
    </div> :
    <img src={image} alt={title} className="" />;

  return (
    <div>
      <div className={`border-b border-i-indigo-500 cursor-pointer p-4 py-6 hover:bg-gray-100 ${activeStyle}`} onClick={onClick}>
        <div className='flex w-full position-relative'>
          <div className="company-logos mr-4 w-14">
            {imageContent}
          </div>
          <div className="company-details md:w-[85%]">
            <div className='flex justify-between'>
              <div className='grow-1'>
                <h3 className="text-sm mb-1 font-bold">{title}</h3>
                <p className="text-sm mb-2">
                  {company}
                </p>
              </div>
              <div className='saveJob'>
                {!isSavedJobList && (
                  <button onClick={handleSaveJob} className="text-xl p-1">
                    {isSavedState ? <GoHeartFill /> : <GoHeart />}
                  </button>
                )}
                {isSavedJobList && (
                  <button onClick={() => handleDeleteJob(id, fileId)} className="text-xl p-1"><BiTrash /></button>
                )}
              </div>
            </div>
            <div className='flex text-gray-500 justify-between'>
              <div className="text-sm">
                <p>
                  <BiMap className="inline mr-1" />
                  {location}
                </p>
                <p className='mt-1'>
                  <BiBriefcaseAlt2 className="inline mr-1" />
                  {employmentType}
                </p>
              </div>
              <p className="text-sm ">
                <BiAlarm className="inline mr-1" />
                {datePosted}
              </p>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default JobCard;
