import React, { useEffect, useState, useRef } from 'react'; 
import { useNavigate, Link } from 'react-router-dom';
import CoverLetterPreview from './CoverLetterPreview';
import { useAuth } from '../../context/AuthContext';
import LoadingSpinner from '../LoadingSpinner';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { BsStars } from "react-icons/bs";
import { BiRefresh,BiCloudUpload } from "react-icons/bi";
import ModalComponent from '../ModalComponent';
import { saveCoverLetter, fetchCoverletterById } from '../../services/resumeService';
import { generateAIContent, rewriteAIContent  } from '../../services/aiService';
import { useResume } from '../../context/ResumeContext'; 
import LanguageSelector from '../LanguageSelector';  
import { BiHome, BiEdit } from 'react-icons/bi';

// Importing images directly
import cvdesignerLogo from '../../images/cvdesigner-icon.png';
import resumeDesignLogo from '../../images/resumedesign-icon.png';

const CoverLetterForm = ({coverletterID}) => {
  const { user, loading } = useAuth(); 
  const navigate = useNavigate();
  const { currentLanguage, setCurrentLanguage } = useResume();
  const [coverLetterFullData, setCoverLetterFullData] = useState({});
  const [currentCoverletterId, setCurrentCoverletterId] = useState(coverletterID); 
  const [isLoading, setIsLoading] = useState(false);
  const [aiLoading, setAiLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [coverletterName, setCoverletterName] = useState();
  const [showTemplateCollection, setShowTemplateCollection] = useState(false);

  const [isAiGenerating, setIsAiGenerating] = useState(false);

  const [coverLetterData, setCoverLetterData] = useState({
    personalDetails: { sectionTitle: "Personal Details", inputs: [] },
    employerDetails: { sectionTitle: "Employer Details", inputs: [] },
    letterContent: { sectionTitle: "Letter Content", inputs: [] },
  });
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [popoverContext, setPopoverContext] = useState({ sectionKey: null, inputIndex: null });
  const popoverRef = useRef(null);

  const [isAIWriterModalOpen, setIsAIWriterModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: '',
    description: '',
    inputValue: '',
    inputPlaceholder: '',
    coverPoints: ''
  });

  const [showForm, setShowForm] = useState(true); // State to manage form visibility

  const [showingTemplates, setShowingTemplates] = useState(false);

  const handleToggleTemplates = (value) => {
    setShowingTemplates(value);
  };

  const toggleFormVisibility = () => {
    setShowForm(!showForm);
  };

  const handleOpenModal = () => {
    const jobTitleInput = coverLetterData.letterContent.inputs[0]; // Assuming the job title is the first input

    if (jobTitleInput && jobTitleInput.inputValue.trim()) {
        // If there is an existing job title, prepare the modal to allow updating
        setModalContent({
            title: 'Is this your job title?',
            description: 'You can update your job title for which you want to generate a cover letter.',
            inputValue: jobTitleInput.inputValue,
            inputPlaceholder: 'Enter job title here...',
            coverPoints: ''
        });
    } else {
        // If there is no job title, ask the user to enter one
        setModalContent({
            title: 'What is your job title?',
            description: 'Enter the job title for which you want to generate a cover letter.',
            inputValue: '',
            inputPlaceholder: 'Enter job title here...',
            coverPoints: ''
        });
    }
    setIsAIWriterModalOpen(true);
};

// Modify handleGenerateContent to update job title in state if changed
const handleGenerateContent = async () => {
 // console.log('Generating content with:', modalContent.inputValue);
  setIsAiGenerating(true);
  setIsAIWriterModalOpen(false);
  setAiLoading(true)
  try {
    //console.log(coverLetterData)
    //console.log(currentLanguage)
    const toAddress = coverLetterData.employerDetails.inputs[1].inputValue + coverLetterData.employerDetails.inputs[2].inputValue; 
    const fromAddress = coverLetterData.personalDetails.inputs[1].inputValue + coverLetterData.personalDetails.inputs[2].inputValue; 

      const context = modalContent.inputValue;
      const isType = 'generate';
      const sectionType = 'coverLetter';
      const format = toAddress;   
      const coverPoints = fromAddress;

      const generatedContent = await generateAIContent(context, isType, sectionType, format, coverPoints, currentLanguage);
      const formattedContent = generatedContent.generated_text.replace(/\n/g, '<br />\n');

      setCoverLetterData(prevData => {
          const updatedInputs = prevData.letterContent.inputs.map(input => {
              if (input.inputKey === "letterBody") {
                  return { ...input, inputValue: formattedContent };
              }
              return input;
          });

          return {
              ...prevData,
              letterContent: { ...prevData.letterContent, inputs: updatedInputs }
          };
      });
      setAiLoading(false)
     // console.log('AI generated content:', generatedContent);
  } catch (error) {
      console.error("Error generating AI content:", error);
  } finally {
      setIsAiGenerating(false);
      setAiLoading(false)
  }
};
const handleRewriteContent = async () => {
  setIsAiGenerating(true);
  setIsAIWriterModalOpen(false);
  setAiLoading(true)
  try {
    const letterBody = coverLetterData.letterContent.inputs[2].inputValue;

      const context = letterBody;
      const isType = 'rewrite';
      const sectionType = 'coverLetter';
      const format = null;   
      const coverPoints = null;
      const language = currentLanguage;
     
      const generatedContent = await generateAIContent(context, isType, sectionType, format, coverPoints, language);
      const formattedContent = generatedContent.generated_text.replace(/\n/g, '<br />\n');

      setCoverLetterData(prevData => {
          const updatedInputs = prevData.letterContent.inputs.map(input => {
              if (input.inputKey === "letterBody") {
                  return { ...input, inputValue: formattedContent };
              }
              return input;
          });
          return {
              ...prevData,
              letterContent: { ...prevData.letterContent, inputs: updatedInputs }
          };
      });
      setAiLoading(false)
     // console.log('AI generated content:', generatedContent);
  } catch (error) {
      console.error("Error generating AI content:", error);
  } finally {
      setIsAiGenerating(false);
      setAiLoading(false)
  }
};

  useEffect(() => {
    if (!loading && !user) {
      navigate('/login');
    } else if (user && coverletterID) {
     // console.log('coverletter form ID: ' + coverletterID)
      setIsLoading(true);
      const loadData = async () => {
        try {
         // console.log('this is my cover letter id' + coverletterID)
          const fetchedData = await fetchCoverletterById(coverletterID);
         // console.log("Fetched data:", fetchedData); // Debug: Log the fetched data
          if (fetchedData) {
            setCoverLetterFullData(fetchedData);
            setCoverLetterData(fetchedData.formObject);
            setCurrentLanguage(fetchedData.metadata.selectedLanguage)
            setCoverletterName(fetchedData.metadata.templateName)
            firstUpdate.current = false; // Data is now loaded
          }
        } catch (error) {
          console.error("Error fetching cover letter data:", error);
        } finally {
          setIsLoading(false);
        }
      };     
      loadData();
    }
  }, [user, loading, coverletterID, navigate]);

  const applyTranslations = (translations) => {
    setCoverLetterData(currentData => {
      const translatedData = { ...currentData };
  
      // Loop over each section (e.g., personalDetails, employerDetails, letterContent)
      Object.keys(translatedData).forEach(sectionKey => {
        const section = translatedData[sectionKey];
  
        // Apply translations to the section title if available
        if (translations[sectionKey]?.sectionTitle) {
          section.sectionTitle = translations[sectionKey].sectionTitle;
        }
  
        // Check and apply translations to each input within the section
        section.inputs = section.inputs.map(input => {
          // Check if there's a translation for this input
          const inputTranslations = translations[sectionKey]?.inputs?.find(it => it.inputKey === input.inputKey);
          return {
            ...input,
            inputLabel: inputTranslations?.inputLabel ?? input.inputLabel,
            placeholder: inputTranslations?.placeholder ?? input.placeholder
          };
        });
      });
  
      return translatedData;
    });
  };
  
  // Load and apply translations based on current language
  useEffect(() => {
    import(`./locales/${currentLanguage}.json`)
      .then(translations => {
        applyTranslations(translations.default || translations);
      })
      .catch(error => {
        console.error("Failed to load language file:", error);
      });
      autoSaveCoverLetter();
  }, [currentLanguage]);

  const handleInputChange = (sectionKey, inputIndex, newValue) => {
    setCoverLetterData((prevData) => {
      const newData = { ...prevData };
      newData[sectionKey].inputs[inputIndex].inputValue = newValue; 
      return newData;
    }); 
  };

  //Auto save
  const firstUpdate = useRef(true);
  const debouncedCoverLetterData = useDebounce(coverLetterData, 1000);

  function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
  }
  const [updating, setUpdating] = useState(false);
  const autoSaveCoverLetter = async () => {
    setUpdating(true);
    if (!debouncedCoverLetterData || firstUpdate.current) {
      return;
    }
  
    if (coverLetterData && currentCoverletterId) {
      setIsSaving(true);
      try {
        //console.log("Current coverLetterData:", coverLetterData); // Debugging line
       // console.log("Current coverLetterFullData.metadata:", coverLetterFullData.metadata); // Debugging line
  
        const updatedMetadata = {
          ...coverLetterFullData.metadata, // Spread existing metadata
          coverLetterID: currentCoverletterId, // Update/add coverLetterID          
          templateName: coverletterName,          
          selectedLanguage: currentLanguage,
          lastModifiedDate: new Date().toISOString(), // Update/add lastModifiedDate
        };
  
       // console.log("Updated metadata before save:", updatedMetadata); // Debugging line
  
        const coverLetterToSave = {
          formObject: { ...coverLetterData },
          metadata: updatedMetadata
        };
        await saveCoverLetter(coverLetterToSave);
       // console.log('Cover letter saved automatically');
        setUpdating(false);
      } catch (error) {
        console.error('Auto-saving cover letter failed:', error);
        setUpdating(false);
      } finally {
        setIsSaving(false);
        setUpdating(false);
      }
    }
  };
  useEffect(() => {
    // Function to handle clicking outside of the popover
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setPopoverVisible(false);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popoverRef]);
  const togglePopover = (sectionKey, inputIndex) => {
    if (popoverVisible && popoverContext.sectionKey === sectionKey && popoverContext.inputIndex === inputIndex) {
      setPopoverVisible(false);
    } else {
      setPopoverVisible(true);
      setPopoverContext({ sectionKey, inputIndex });
    }
  };

useEffect(() => {
  // Only proceed if it's not the first update and data has been loaded
  if (!firstUpdate.current) {
    autoSaveCoverLetter();
  }
}, [debouncedCoverLetterData]);
  
  if (isLoading || loading) {
    return <LoadingSpinner />;
  }

   const renderFormSections = () => {
    if (!coverLetterData || Object.keys(coverLetterData).length === 0) {
      // Consider rendering nothing or a placeholder if data hasn't loaded yet.
      return null; // or some placeholder indicating the data is loading
    }
    //console.log(coverLetterData)
    const renderField = (sectionKey, input, inputIndex) => {
      return input.inputType !== 'textarea' ? (
        <input
          type={input.inputType}
          value={input.inputValue}
          placeholder={input.placeholder}
          onChange={(e) => handleInputChange(sectionKey, inputIndex, e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 focus:outline-none focus:ring-2 focus:ring-blue-100"
          />
      ) : (<div>
        <ReactQuill
          theme="snow"
          value={input.inputValue}
          onChange={(content) => handleInputChange(sectionKey, inputIndex, content)}
          className="w-full"
        />
        {/* <button
            onClick={() => handleAIWriter(sectionKey, inputIndex)}
            disabled={isAiGenerating}
            // className={`p-2 text-white ${isAiGenerating ? 'bg-gray-400' : 'bg-blue-500 hover:bg-blue-700'} rounded`}
            className="flex gap-2 text-sm items-center justify-between align-center ai-writer-btn py-2 px-4 bg-blue-500 text-white rounded-3xl hover:bg-blue-700 transition-colors bg-gradient-to-r from-blue-600 to-violet-600 mb-2"
            >
              <BsStars /> AI Writer
          </button> */}
          <div className='flex justify-end'>
          <div className='mt-2 relative'>
          {!aiLoading &&   <button
        onClick={() => togglePopover(sectionKey, inputIndex)}
        className="ai-writer-btn"
        >
          <BsStars /> AI Writer
    </button>}
    {aiLoading && 
   <button type="button" className="flex gap-2 text-sm items-center justify-between align-center ai-writer-btn py-2 px-4 bg-blue-500 text-white rounded-3xl hover:bg-blue-700 transition-colors bg-gradient-to-r from-blue-600 to-violet-600 opacity-65">
   <svg width="20" height="20" fill="currentColor" className="mr-1 animate-spin" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
       <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z">
       </path>
   </svg>
   Generating..
</button>
    }   
    {popoverVisible && popoverContext.sectionKey === sectionKey && popoverContext.inputIndex === inputIndex && (
        <div 
            ref={popoverRef} 
            className="absolute bottom-full mt-5 right-0 p-4 z-10 popover-menu text-sm rounded-lg shadow-lg bg-white z-10 px-3 py-3"
            style={{ width: 'auto', minWidth: '160px' }}
        >
            <button
                onClick={() => handleAIWriter(sectionKey, inputIndex, 'generate')}
                className="p-2 px-3 cursor-pointer w-full text-left text-gray-500 hover:bg-gray-100 hover:rounded-md hover:text-blue-500 focus:outline-none"
            >
                Generate New
            </button>
            <button
                onClick={() => handleRewriteContent(sectionKey, inputIndex, 'rewrite')}
                className="p-2 px-3 cursor-pointer w-full text-left text-gray-500 hover:bg-gray-100 hover:rounded-md hover:text-blue-500 focus:outline-none"
            >
                Rewrite Section
            </button>
        </div>
    )}
          </div>
</div>

        </div>
      );
    };
  
    // Render sections with a specific layout based on the section key
    const renderSection = (sectionKey, sectionData) => {
      return (
        <div key={sectionKey} className="section bg-gray-100 rounded-lg order-gray-500 p-4 mb-4">
          <h2 className="text-xl font-semibold mb-4">{sectionData.icon} {sectionData.sectionTitle}</h2>
          {/* For "Personal Details" and "Employer Details", first three fields in 3 columns, others in 2 columns */}
         
          {(sectionKey === 'personalDetails' || sectionKey === 'employerDetails') && (
            <div className='bg-white rounded p-4'>
              <div className="grid grid-cols-3 gap-4">
                {sectionData.inputs.slice(0, 3).map((input, index) => (
                  <div key={`${sectionKey}-${index}`} className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      {input.inputLabel}
                    </label>
                    {renderField(sectionKey, input, index)}
                  </div>
                ))}
              </div>
              <div className="grid grid-cols-2 gap-4">
                {sectionData.inputs.slice(3).map((input, index) => (
                  <div key={`${sectionKey}-${index + 3}`} className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      {input.inputLabel}
                    </label>
                    {renderField(sectionKey, input, index + 3)}
                  </div>
                ))}
              </div>
            </div>
          )}
 
          {/* For "Content", all fields in 2 columns except the last two, which are full-width */}
          {sectionKey === 'letterContent' && (
         <div className='bg-white rounded p-4'>
              <div className="grid grid-cols-2 gap-4">
                {sectionData.inputs.slice(0, -2).map((input, index) => (
                  <div key={`${sectionKey}-${index}`} className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      {input.inputLabel}
                    </label>
                    {renderField(sectionKey, input, index)}
                  </div>
                ))}
              </div>
              {sectionData.inputs.slice(-2).map((input, index) => (
                <div key={`${sectionKey}-${sectionData.inputs.length - 2 + index}`} className="mb-4 w-full">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    {input.inputLabel}
                  </label>
                  {renderField(sectionKey, input, sectionData.inputs.length - 2 + index)}
                </div>
              ))}
           </div>
          )}
        </div>
      );
    };
  
    // Explicitly order and render the sections
    return (
      <>
        {renderSection('personalDetails', coverLetterData.personalDetails)}
        {renderSection('employerDetails', coverLetterData.employerDetails)}
        {renderSection('letterContent', coverLetterData.letterContent)}
      </>
    );
  };
  
  
  const handleAIWriter = (sectionKey, inputIndex, actionType) => {
    setIsAiGenerating(true);
    setPopoverVisible(false);
    const input = coverLetterData[sectionKey].inputs[inputIndex];

    const content = actionType === 'rewrite' ? rewriteAIContent(input.inputValue) : handleOpenModal(input.inputValue);
  
  };

  const logoImage = process.env.REACT_APP_ICON_IMAGE === 'cvdesigner-icon.png' ? cvdesignerLogo : resumeDesignLogo;
  const logoClass = process.env.REACT_APP_ICON_IMAGE === 'cvdesigner-icon.png' ? 'cvdesigner-icon' : 'resumedesign-icon';
  
  
  return (
    <div className="flex flex-col md:flex-row">

{isLoading && 
    <div className="flex items-center justify-center w-full">
    <LoadingSpinner displayType='block' />
    </div>
    }
 {!isLoading && !showTemplateCollection && 
      // <div className="json-preview w-full md:w-[40%] bg-gray-100 h-lvh overflow-hide">
          <div className={`flex flex-row w-full md:w-[40%] h-screen ${showForm ? 'edit-mode' : 'preview-mode'}`}> 
      <div className="flex flex-col h-screen w-full">
      <div className="bg-white flex justify-between"> 
          <div className='resume-form w-full flex justify-between p-4 py-3'>
          <div className='flex'>
          <Link to="/dashboard">
          <div className={`${logoClass}`}>
                <div className="logo">
                  <img src={logoImage} alt="Logo" className="logo-img" />
                </div>
              </div>
            </Link>       
          {/* <Link href="/dashboard">
            <button className="text-sm text-gray-600 rounded italic py-1 my-1 ml-4 px-2 hover:bg-gray-200 focus:outline-none focus:border-gray-500">
                Back to Dashboard
            </button>
        </Link> */}
          </div>
          <div className='flex'>
          <div className="resume-name">
            <input type="text" className="rounded-none max-w-full outline-none inline-block text-start overflow-hidden border-b duration-150 transition-colors bg-transparent cursor-text px-0 truncate border-transparent placeholder-opacity-1 py-0.5 my-0.5 
            placeholder-gray-200 focus:placeholder-gray-400 focus:border-brand-400 hover:border-gray-700" alt="Coverletter James Dow" 
            placeholder="Enter coverletter name" value={coverletterName} onChange={e => setCoverletterName(e.target.value)} onBlur={autoSaveCoverLetter}/>
             </div>
             <div className="resume-saving-status flex justify-center items-center">
                    {updating
                        ? <BiRefresh className="resume-updating" />
                        : <BiCloudUpload />}
                </div>
          </div>
          <div>  
          <LanguageSelector selected={currentLanguage} onSelectLanguage={setCurrentLanguage} />     
      {/* Other parts of your form */}
    </div>
          {/* <select className='text-sm text-gray-600 rounded py-1 my-1 ml-4 bg-gray-100 px-2 hover:bg-gray-200 focus:outline-none focus:border-gray-500' value={currentLanguage} onChange={(e) => setCurrentLanguage(e.target.value)}>
            <option value="en">English</option>
            <option value="en-GB">English (UK)</option>
            <option value="es">Español</option>
            <option value="es-MX">Español (MX)</option>
            <option value="fr">Français</option>
            <option value="de">Deutsch</option>
            <option value="it">Italiano</option>
            <option value="nl">Nederlands</option>
            <option value="pt">Português</option>
            <option value="pt-BR">Português (BR)</option>
            <option value="pl">Polski</option>
            <option value="cs">Čeština</option>
            <option value="ja">日本語</option>
            <option value="sv">Svenska</option>
            <option value="da">Dansk</option>
            <option value="de-CH">Switzerland</option>
            <option value="no">Norsk</option>
            <option value="fi">Suomi</option>
            <option value="ru">Русский</option>
            <option value="hu">Magyar</option>
            <option value="ro">Română</option>
            <option value="af">Afrikaans</option>
            <option value="el">Ελληνικά</option>
            <option value="en-IE">Ireland</option>
            <option value="de-AT">Austria</option>
            <option value="bg">Bulgaria</option>
            <option value="es-CL">Chile</option>
            <option value="sr">Serbia</option>
            <option value="vi">Vietnam</option>
        </select> */}
          </div>
      </div>

      <div className="flex-1 bg-white overflow-auto p-5">
      <button
      onClick={autoSaveCoverLetter}
      disabled={isSaving}
      className={`btn ${isSaving ? 'btn-disabled' : 'btn-primary'}`}
    >
      {/* {isSaving ? 'Saving...' : 'Save Cover Letter'} */}
    </button>
            {renderFormSections()}
        </div> 
        </div>
        </div>
}
{!isLoading && coverLetterData &&
    <CoverLetterPreview currentCoverletterId={currentCoverletterId} coverLetterData={coverLetterData} coverLetterFullData={coverLetterFullData} setShowTemplateCollection={setShowTemplateCollection} onToggleTemplates={handleToggleTemplates} showResume={showForm}/>       
    }
    {isAIWriterModalOpen && (
        <ModalComponent isOpen={isAIWriterModalOpen} onClose={() => setIsAIWriterModalOpen(false)}>
          <div className="p-4">
            <h3 className="mb-4 font-bold">{modalContent.title}</h3>
            <p className='text-sm'>{modalContent.description}</p>
            <input
              type="text"
              value={modalContent.inputValue}
              placeholder={modalContent.inputPlaceholder}
              onChange={(e) => setModalContent(prev => ({ ...prev, inputValue: e.target.value }))}
              className="w-full my-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5  focus:outline-none focus:ring-2 focus:ring-blue-100"
            />
            <button onClick={handleGenerateContent} className="flex gap-2 text-sm items-center justify-between align-center ai-writer-btn py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700 transition-colors bg-blue-500 hover:bg-blue-700">Generate with AI</button>
          </div>
        </ModalComponent>
      )}

            {/* Toggle Button for Mobile View */}
  {!showingTemplates && 
        <div className="md:hidden sticky-footer flex justify-between py-4 bg-white fixed bottom-0 w-full text-black shadow-md">
        <Link to="/dashboard" className="flex flex-col items-center fo-btn text-white text-md py-1.5 px-4 rounded">
          <BiHome className="text-xl" />
          <span className="text-xs">Dashboard</span>
        </Link>
        <button
          onClick={toggleFormVisibility}
          className="flex flex-col items-center fo-btn text-white py-1.5 px-4 text-md rounded"
        >
          <BiEdit className="text-xl" />
          <span className="text-xs">{showForm ? 'Preview' : 'Edit'}</span>
        </button>
      </div>
    }
  </div>
  );
};

export default CoverLetterForm;