// services/aiService.js
const fetchWithRetry = async (url, options, retries = 3) => {
  for (let attempt = 1; attempt <= retries; attempt++) {
    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`Attempt ${attempt} failed: ${response.statusText}`);
      }
      return response;
    } catch (error) {
      console.error(error);
      if (attempt === retries) {
        throw new Error(`Failed after ${retries} attempts`);
      }
    }
  }
};

export const generateAIContent = async (prompt, isType, sectionType, format, coverPoints, currentLanguage) => {
  try {
    const response = await fetchWithRetry('https://rd-server-418cf5202d66.herokuapp.com/generate-ai-content', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ prompt, isType, sectionType, format, coverPoints, currentLanguage }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error generating AI content:', error);
    throw error;
  }
};

export const rewriteAIContent = async (prompt, isType, sectionType, format, coverPoints, currentLanguage) => {
  try {
    const response = await fetchWithRetry('https://rd-server-418cf5202d66.herokuapp.com/generate-ai-content', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ prompt, isType, sectionType, format, coverPoints, currentLanguage }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error generating AI content:', error);
    throw error;
  }
};

export const generateResumeAnalysis = async (prompt, jobTitle) => {
  try {
    const response = await fetchWithRetry('https://rd-server-418cf5202d66.herokuapp.com/ai-resume-analyze', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ prompt, jobTitle }),
    });

    const data = await response.json();
    return data.generated_text; // Assuming `generated_text` is the field containing the analysis
  } catch (error) {
    console.error('Error generating AI content:', error);
    throw error;
  }
};

export const generateJobInterview = async (jobTitle, seniorityLevel, questionType, currentLanguage) => {
  try {
    const response = await fetchWithRetry('https://rd-server-418cf5202d66.herokuapp.com/generate-job-interview', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ jobTitle, seniorityLevel, questionType, currentLanguage }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error generating job interview content:', error);
    throw error;
  }
};
