import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, getDoc, query, where } from "firebase/firestore";
import { db } from "../config/firebaseConfig";
import { useAuth } from '../context/AuthContext';
import ResumePreview from '../components/Resume/ResumePreview';
import CoverLetterPreview from '../components/CoverLetter/CoverLetterPreview';
import { useNavigate } from 'react-router-dom';

const AdminDashboard = () => {
  const { user, setTempUser } = useAuth();
  const [userContext, setUserContext] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [adminEmail, setAdminEmail] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAdminEmail = async () => {
      try {
        const docRef = doc(db, "admin", "IStA6fJzjiHovjcz6B0V");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setAdminEmail(data.adminUser[0]);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching admin email: ", error);
      }
    };
    fetchAdminEmail();
  }, []);

  useEffect(() => {
    if (user && user.email !== adminEmail) {
      // Redirect or deny access if user is not admin
      console.log("Access denied");
    }
  }, [user, adminEmail]);

  const handleUserSelect = async (userId) => {
    const userDoc = await getDoc(doc(db, "users", userId));
    const selectedUser = { id: userId, ...userDoc.data() };
    setUserContext(selectedUser);
    setTempUser({ uid: userId, ...userDoc.data() }); // Set temporary user
    localStorage.setItem('selectedUserId', userId); // Store user ID in local storage
  };

  const handleExitUserContext = () => {
    setUserContext(null);
    setTempUser(null); // Reset temporary user
    localStorage.removeItem('selectedUserId'); // Remove user ID from local storage
  };

  useEffect(() => {
    const storedUserId = localStorage.getItem('selectedUserId');
    if (storedUserId) {
      handleUserSelect(storedUserId);
    }
  }, []);

  const handleSearch = async (e) => {
    e.preventDefault();
    if (searchQuery.trim() === '') return;

    const usersCollection = collection(db, "users");
    const q = query(usersCollection, where('email', '==', searchQuery));
    const querySnapshot = await getDocs(q);

    const results = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setSearchResults(results);
  };

  return (
    <div className="p-4 max-w-3xl mx-auto text-center">
      {userContext ? (
        <UserContextView user={userContext} onExit={handleExitUserContext} />
      ) : (
        <div>
          <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} handleSearch={handleSearch} />
          <UserList onUserSelect={handleUserSelect} searchResults={searchResults} />
        </div>
      )}
    </div>
  );
};

const SearchBar = ({ searchQuery, setSearchQuery, handleSearch }) => (
  <form onSubmit={handleSearch} className="flex items-center mb-4">
    <input
      type="text"
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      placeholder="Search by user ID or email ID"
      className="border border-gray-300 rounded-md p-2 w-full mr-2"
    />
    <button type="submit" className="bg-blue-500 text-white p-2 rounded-md">Search</button>
  </form>
);

const UserList = ({ onUserSelect, searchResults }) => (
  <div>
    <h1 className="text-xl font-bold mb-4">User Account</h1>
    {searchResults.length > 0 ? (
      searchResults.map(user => (
        <div key={user.id} className="flex justify-between items-center border-b border-gray-200 py-2">
          <p>{user.email}</p>
          <button onClick={() => onUserSelect(user.id)} className="bg-blue-500 text-white px-3 py-1 rounded-md">Switch to User</button>
        </div>
      ))
    ) : (
      <p className="text-gray-500">No user found</p>
    )}
  </div>
);

const UserContextView = ({ user, onExit }) => {
  const [resumes, setResumes] = useState([]);
  const [coverLetters, setCoverLetters] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const resumesCollection = collection(db, `users/${user.id}/resumes`);
      const resumesSnapshot = await getDocs(resumesCollection);
      setResumes(resumesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));

      const coverLettersCollection = collection(db, `users/${user.id}/cover-letters`);
      const coverLettersSnapshot = await getDocs(coverLettersCollection);
      setCoverLetters(coverLettersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };
    fetchUserData();
  }, [user]);

  const editResume = (resumeId) => {
    navigate(`/editor/resume/${resumeId}`);
  };

  const editCoverLetter = (coverLetterId) => {
    navigate(`/editor/cover-letter/${coverLetterId}`);
  };

  return (
    <div className='max-w-3xl mx-auto text-center'>
      <h1 className="text-xl font-bold mb-4">User: {user.email}</h1>
      <div>
        <h2 className="text-lg font-semibold mb-2">Resumes</h2>
        {resumes.map(resume => (
          <div key={resume.id} className="mb-4">
            <ResumePreview resume={resume} />
            <button onClick={() => editResume(resume.id)} className="bg-blue-500 text-white px-3 py-1 rounded-md mt-2">Edit</button>
          </div>
        ))}
      </div>
      <div>
        <h2 className="text-lg font-semibold mb-2">Cover Letters</h2>
        {coverLetters.map(letter => (
          <div key={letter.id} className="mb-4">
            <CoverLetterPreview letter={letter} />
            <button onClick={() => editCoverLetter(letter.id)} className="bg-blue-500 text-white px-3 py-1 rounded-md mt-2">Edit</button>
          </div>
        ))}
      </div>
      <button onClick={onExit} className="bg-red-500 text-white px-3 py-1 rounded-md mt-4">Exit User Context</button>
    </div>
  );
};

export default AdminDashboard;
