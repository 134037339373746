import React, { useState, useEffect } from 'react'; 
import { useAuth } from '../context/AuthContext';
import LoadingSpinner from '../components/LoadingSpinner';
import { fetchResumesForAnalysis } from '../services/resumeService';
import { generateResumeAnalysis } from '../services/aiService';
import ResumePreview from '../components/Resume/ResumePreview'; 
import { Remarkable } from 'remarkable';
import { Link } from 'react-router-dom'; 

const allowedSections = [
  "Contact Details",
  "Professional Summary",
  "Work Experience",
  "Education",
  "Skills",
  "Projects",
  "Achievements",
  "Certifications",
  "Languages",
  "Profile Links"
];

const ResumeAnalysis = () => {
  const { user, loading: authLoading } = useAuth(); 
  const [resumes, setResumes] = useState([]);
  const [selectedResume, setSelectedResume] = useState(null);
  const [analysisResult, setAnalysisResult] = useState('');
  const [operationLoading, setOperationLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searchInitiated, setSearchInitiated] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (!authLoading && user) {
      fetchResumesForAnalysis(user.uid)
        .then(resumes => {
          setResumes(resumes || []);
          setIsLoading(false);
        })
        .catch(error => {
          console.error("Failed to fetch resumes:", error);
          setIsLoading(false);
        });
    }
  }, [user, authLoading]);

  const extractSections = (data) => {
    let formattedSections = [];
    for (const sectionKey in data) {
      const section = data[sectionKey];
      if (allowedSections.includes(section.sectionTitle)) {
        const sectionTitle = section.sectionTitle;
        let sectionContent = [];

        if (section.inputGroups && Array.isArray(section.inputGroups)) {
          section.inputGroups.forEach(group => {
            group.inputs.forEach(input => {
              sectionContent.push(`${input.inputLabel}: ${input.inputValue}`);
            });
          });
        }

        sectionContent = sectionContent.join(', ');

        formattedSections.push({
          sectionTitle: sectionTitle,
          sectionShowing: section.isDisplay,
          sectionContent: sectionContent
        });
      }
    }
    return formattedSections;
  }

  const handleResumeSelect = async (event) => {
    const selectedId = event.target.value;
    const resume = resumes.find(r => r.id === selectedId);
    setSelectedResume(resume);
  
    const allSections = extractSections(resume.formObject);
    
    // Filter out the sections you don't want
    const sectionDetails = allSections.filter(section => 
      !['Certifications', 'Projects', 'Profile Links'].includes(section.sectionTitle)
    );
  
    const jobTitle = resume.formObject.personalDetails.inputGroups[0].inputs.find(input => input.inputKey === 'jobTitle').inputValue;
  
    console.log(sectionDetails);
  
    if (sectionDetails) {
      setOperationLoading(true);
      try {
        const analysis = await generateResumeAnalysis(sectionDetails, jobTitle);
        setAnalysisResult(analysis);
        setErrorMessage('');
      } catch (error) {
        console.error('Error in resume analysis:', error);
        setAnalysisResult('');
        setErrorMessage("Oops, something went wrong while analyzing your resume. Please try again later or reach out to our support team for assistance.");
      }
      setOperationLoading(false);
    }
  };
  
  if (operationLoading) {
    return <><LoadingSpinner /></>;
  }

  const renderList = (text) => {
    const md = new Remarkable();
    const htmlContent = md.render(text);
    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
  };
  const renderProgressBar = (score) => {
    const scoreValue = parseInt(score);
    const percentage = (scoreValue / 100) * 100;
  
    return (
      <div className="w-full bg-gray-200 rounded-full h-4">
        <div className="h-4 rounded-full bg-gradient-to-r from-red-500 via-yellow-500 to-green-500" style={{ width: `${percentage}%` }}></div>
      </div>
    );
  };
  
  
  const renderAnalysis = () => {
    if (!analysisResult) return null;

    // Split the analysis result into structured data
    const analysisLines = analysisResult.split('\n');
    const sections = {
      overallScore: '',
      strengths: '',
      weaknesses: '',
      suggestions: '',
      sectionScores: ''
    };
    
    let currentSection = '';
    analysisLines.forEach(line => {
      if (line.startsWith('1. **Overall Score:**')) {
        currentSection = 'overallScore';
        sections[currentSection] += line.replace('1. **Overall Score:**', '').trim();
      } else if (line.startsWith('2. **Strengths:**')) {
        currentSection = 'strengths';
        sections[currentSection] += line.replace('2. **Strengths:**', '').trim();
      } else if (line.startsWith('3. **Weaknesses:**')) {
        currentSection = 'weaknesses';
        sections[currentSection] += line.replace('3. **Weaknesses:**', '').trim();
      } else if (line.startsWith('4. **Suggestions for Improvement:**')) {
        currentSection = 'suggestions';
        sections[currentSection] += line.replace('4. **Suggestions for Improvement:**', '').trim();
      } else if (line.startsWith('5. **Detailed Section Scores:**')) {
        currentSection = 'sectionScores';
        sections[currentSection] += line.replace('5. **Detailed Section Scores:**', '').trim();
      } else if (currentSection) {
        sections[currentSection] += '\n' + line.trim();
      }
    });

    return (
      <div className="resume-analyze-data space-y-6 p-9">
        <h2 className="text-2xl font-bold mb-4">AI Resume Analysis Report</h2>
        <p className="text-gray-700 mb-6">
          Our detailed AI resume analysis highlights strengths, identifies weaknesses, and offers improvement suggestions to help you present your best self to employers.
        </p>
        <div className="resume-data-points overall-score">
          <h3 className="text-lg font-bold mb-2">Overall Score: <span className='overallScore'>{sections.overallScore}/100</span></h3>          
          {renderProgressBar(sections.overallScore)}
        </div>
        <div className="resume-data-points resume-strengths">
          <h3 className="text-lg font-bold mb-2">Strengths</h3>
          {renderList(sections.strengths)}
        </div>
        <div className="resume-data-points resume-weaknesses">
          <h3 className="text-lg font-bold mb-2">Weaknesses</h3>
          {renderList(sections.weaknesses)}
        </div>
        <div className="resume-data-points resume-improvements">
          <h3 className="text-lg font-bold mb-2">Suggestions for Improvement</h3>
          {renderList(sections.suggestions)}
        </div>
        <div className="resume-data-points resume-scores">
          <h3 className="text-lg font-bold mb-2">Detailed Section Scores</h3>
          {renderList(sections.sectionScores)}
        </div>
      </div>
    );
  };

  return (
    <>
      {user && isLoading ? (
        <LoadingSpinner />
      ) : !user && resumes.length === 0 ? (
        <div className="flex items-center justify-center h-[60%]">
          <div className='flex-wrap text-center'>
            <h2 className="text-2xl font-bold mb-5">No Resumes Found</h2>
            <p className="mb-4">You don't have any resumes yet. Please create a resume to use the AI analysis feature.</p>
            <Link to="/dashboard" className="text-blue-500 underline">Go to Dashboard to Create a New Resume</Link>
          </div>
        </div>
      ) : !selectedResume ? (
        <div className="flex items-center justify-center h-[60%]">
          <div className='flex-wrap'>
            <h2 className="text-2xl font-bold mb-5 text-center">AI Resume Analysis</h2>
            <div className="flex w-full p-3 bg-white justify-center rounded-lg shadow-md">   
              {/* <label className='p-3'><strong>Resume:</strong></label> */}
              <select 
                onChange={handleResumeSelect} 
                value={selectedResume?.id || ''}
                className="p-3 md:w-[70%]"
              >
                <option value="" disabled>Select your resume</option>
                {resumes.map((resume) => (
                  <option key={resume.id} value={resume.id}>
                    {resume.metadata.templateName}
                  </option>
                ))}
              </select> 
            </div> 
            <div className='flex items-center justify-center p-6 text-sm pb-3 text-gray-500 italic'>AI helps you to review your Resume/CV</div>
         </div>
        </div>
      ) : (
        <div className="flex flex-col md:flex-row w-full">
          <div className='flex flex-row w-full md:w-[45%] h-screen'>          
            <div className="flex flex-col w-full h-screen border-l-10 border-cyan-400">
              <div className="justify-center align-center p-3 pl-8">
                <label className='p-3'><strong>Resume:</strong></label>
                <select 
                  onChange={handleResumeSelect} 
                  value={selectedResume?.id || ''}
                  className="p-3 md:w-[55%]"
                >
                  <option value="" disabled>Select your resume</option>
                  {resumes.map((resume) => (
                    <option key={resume.id} value={resume.id}>
                      {resume.metadata.templateName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex-1 overflow-auto custom-scrollbar p-2.5"> 
                {errorMessage && (
                  <div className="text-red-500 text-center mt-4">
                    <p>Oops, something went wrong while analyzing your resume.</p>
                    <p>Please try again later or reach out to our support team for assistance.</p>
                  </div>
                )}
                {selectedResume && (
                  <div className="resume-analysis-preview text-md">
                    {renderAnalysis()}
                  </div>
                )}    
              </div>
            </div>
          </div>
          <div className="flex flex-row w-full md:w-[55%] h-screen analysis-preview">
            <div className="flex-1 overflow-auto custom-scrollbar p-5">
              {selectedResume && (
                <div className='resume-analysis-preview'>
                  <ResumePreview 
                    sections={selectedResume.formObject} 
                    resumeData={selectedResume} 
                    showPreviewOnly={true}
                  />
                </div>
              )}              
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ResumeAnalysis;
