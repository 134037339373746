import React from 'react';
import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom';
import { ResumeProvider } from './context/ResumeContext';
import Dashboard from './pages/Dashboard';
import Editor from './pages/Editor';
import FinishSignUp from './pages/FinishSignUp';
import JobInterview from './pages/JobInterview';
import Jobs from './pages/Jobs';
import Login from './pages/Login';
import MyAccount from './pages/MyAccount';
import Plans from './pages/Plans';
import ResumeAnalysis from './pages/ResumeAnalysis';
import Resumes from './pages/Resumes';
import Sample from './pages/Sample';
import Signup from './pages/Signup';
import Support from './pages/Support';
import VerifyEmail from './pages/VerifyEmail';
import Layout from './components/Layout';
import Admin from './pages/Admin';
import ExampleRedirect from './components/ExampleRedirect';
import CLExampleRedirect from './components/CLExampleRedirect';
import CvExampleNew from './pages/CvExampleNew';
import CLExampleNew from './pages/CLExampleNew';

const LayoutRoute = () => (
  <Layout>
    <Outlet />
  </Layout>
);

function App() {
  return (
    <ResumeProvider>
      <Router>
        <Routes>
          {/* Routes without Layout */}
          <Route path="/editor/resume/:resumeid" element={<Editor />} />
          <Route path="/editor/cover-letter/:coverLetterId" element={<Editor />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/example" element={<ExampleRedirect />} /> 
          <Route path="/cl-example" element={<CLExampleRedirect />} /> 

          {/* Routes with Layout */}
          <Route element={<LayoutRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/cvexample" element={<CvExampleNew />} />
            <Route path="/clexample" element={<CLExampleNew />} />
            <Route path="/finish-sign-up" element={<FinishSignUp />} />
            <Route path="/job-interview" element={<JobInterview />} />
            <Route path="/jobs" element={<Jobs />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/my-account" element={<MyAccount />} />
            <Route path="/plans" element={<Plans />} />
            <Route path="/resume-analysis" element={<ResumeAnalysis />} />
            <Route path="/resumes" element={<Resumes />} />
            <Route path="/sample" element={<Sample />} />
            <Route path="/support" element={<Support />} />
            <Route path="/" element={<Dashboard />} /> {/* Default route */}
          </Route>
        </Routes>
      </Router>
    </ResumeProvider>
  );
}

export default App;
