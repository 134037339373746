//modals/CoverLetterModal.js 

const CoverLetterModal = {
    formObject:{
       personalDetails: {
         sectionTitle: "Personal Details",
         icon: "👤",
         sectionID: "personalDetails",
         inputs: [
           { inputKey:"title", inputLabel: "Title", inputType: "text", placeholder: "e.g. Mr", show: true, required: false, inputValue: "" },
           { inputKey:"firstName", inputLabel: "First Name", inputType: "text", placeholder: "e.g. Samson", show: true, required: false, inputValue: "" },
           { inputKey:"lastName", inputLabel: "Last Name", inputType: "text", placeholder: "e.g. Luran", show: true, required: false, inputValue: "" },
           { inputKey:"phoneNumber", inputLabel: "Phone Number", inputType: "text", placeholder: "e.g. 9874654654", show: true, required: true, inputValue: "" },
           { inputKey:"emailAddress", inputLabel: "Email Address", inputType: "email", placeholder: "e.g. samson.luarn@gmail.com", show: true, required: true, inputValue: "" },
           { inputKey:"streetNumber", inputLabel: "Street and Number", inputType: "text", placeholder: "e.g. 7th Avenue", show: true, required: true, inputValue: "" },
           { inputKey:"city", inputLabel: "City", inputType: "text", placeholder: "e.g. New York", show: true, required: true, inputValue: "" },
           { inputKey:"postalCode", inputLabel: "Postal Code", inputType: "text", placeholder: "e.g. 1005", show: true, required: true, inputValue: "" },
           { inputKey:"country", inputLabel: "Country", inputType: "text", placeholder: "e.g. US", show: true, required: true, inputValue: "" },
           { inputKey:"linkedin", inputLabel: "LinkedIn", inputType: "text", placeholder: "e.g. Linkedin.com/in/samdoe", show: true, required: true, inputValue: "" }
         ]
       },
       employerDetails: {
         sectionTitle: "Employer Details",
         icon: "👨‍💼",
         sectionID: "employerDetails",
         inputs: [
           { inputKey:"title", inputLabel: "Title", inputType: "text", placeholder: "e.g. Mr", show: true, required: false, inputValue: "" },
           { inputKey:"firstName", inputLabel: "First Name", inputType: "text", placeholder: "e.g. Zamin", show: true, required: false, inputValue: "" },
           { inputKey:"lastName", inputLabel: "Last Name", inputType: "text", placeholder: "e.g. Taran", show: true, required: false, inputValue: "" },        
           { inputKey:"companyNamae", inputLabel: "Company Name", inputType: "text", placeholder: "e.g. Accenture", show: true, required: true, inputValue: "" },
           { inputKey:"department", inputLabel: "Department", inputType: "text", placeholder: "e.g. HR", show: true, required: true, inputValue: "" },
           { inputKey:"streetNumber", inputLabel: "Street and Number", inputType: "text", placeholder: "e.g. 5th Avenue", show: true, required: true, inputValue: "" },
           { inputKey:"city", inputLabel: "City", inputType: "text", placeholder: "e.g. New Jersey", show: true, required: true, inputValue: "" },
           { inputKey:"postalCode", inputLabel: "Postal Code", inputType: "text", placeholder: "e.g. 2005", show: true, required: true, inputValue: "" },
           { inputKey:"country", inputLabel: "Country", inputType: "text", placeholder: "e.g. US", show: true, required: true, inputValue: "" }
         ]
       },
       letterContent: {
         sectionTitle: "Content",
         icon: "📝",
         sectionID: "letterDetails",
         inputs: [
           { inputKey:"subject", inputLabel: "Subject", inputType: "text", placeholder: "e.g. Marketing Manager Position", show: true, required: true, inputValue: "" },
           { inputKey:"date", inputLabel: "Date", inputType: "text", placeholder: "e.g. 22/4/2024", show: true, required: true, inputValue: "" },
           { inputKey:"letterBody", inputLabel: "Letter Body", inputType: "textarea", placeholder: "Write the content of your letter here.", show: true, required: true, inputValue: "" },
           { inputKey:"signature", inputLabel: "Signature", inputType: "text", placeholder: "Your Name", show: true, required: true, inputValue: "" }
         ]
       }    
    },   
    metadata: {
     templateID:"temp1",
     templateName: "Cover Letter",
     description: "",
     creationDate: new Date().toISOString(),
     lastModifiedDate: new Date().toISOString(),
     userID:"",
     coverLetterID:"",
     selectedStyle:"Elegant",
     selectedFont:"roboto-regular",
     selectedLanguage:"English",
     fontSize:"M",
     textAlignment:"left",
     textTransform:"none",
     lineHeight:"1.35",
     sectionSpacing:"normal",
     selectedColor:""
   }
 };
 
 export default CoverLetterModal;
 