import React, { useEffect, useRef } from 'react';

const Popover = ({ isVisible, content, onClickOutside, resumeId }) => {
  const popoverRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        onClickOutside();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClickOutside]);

  if (!isVisible) {
    return null;
  }

  return (
    <div ref={popoverRef} className="absolute bottom-full mb-0 right-2 w-36 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5">
      {content.map((item, index) => (
        <button key={index} className="block px-4 py-2 font-bold text-sm text-left text-gray-500 w-full hover:bg-gray-100 hover:text-gray-600" onClick={() => item.action(resumeId)}>
          {item.label}
        </button>
      ))}
    </div>
  );
};

export default Popover;
