// PhotoUploadModal.js
import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from '../util/cropImage'; // Helper function for cropping (implementation below)
import { BsZoomIn, BsZoomOut, BsRepeat, BsPersonBoundingBox } from "react-icons/bs";
//import './Modal.css'; // Assume basic modal styling

const PhotoUploadModal = ({ isOpen, onClose, onSave }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const uploadImage = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setImageSrc(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleSave = async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels, rotation);
      onSave(croppedImage); // Pass the cropped image to the onSave callback
      onClose(); // Close the modal
    } catch (e) {
      console.error(e);
    }
  };

  const handleNew = async () => {
    setImageSrc(null)
  };

  if (!isOpen) return null;

  return (
    <div className="photo-modal">
      <div className="photo-modal-content">
      <h2 className='font-bold text-lg'>Add a Photo</h2>
        <span className="close" onClick={onClose}>&times;</span>
        {!imageSrc && (
        <div className="flex items-center justify-center w-full">
    <label for="dropzone-file" className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50">
        <div className="flex flex-col items-center justify-center pt-5 pb-6">
             <span className='text-gray-500 text-4xl mb-4'><BsPersonBoundingBox /></span>
            <p className="mb-2 text-sm text-gray-500"><span className="font-semibold">Click to upload</span> or drag and drop</p>
            <p className="text-xs text-gray-500">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
        </div>
        <input id="dropzone-file" type="file" className="hidden" accept="image/*" onChange={uploadImage} />
    </label>
</div>
        )}

        {imageSrc && (
          <div className="cropperWrap">
            <div className="cropper">
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              rotation={rotation}
              aspect={4 / 4}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
            />
            </div>
            <div className="photo-controls flex">
              <button className="flex justify-between items-center font-bold text-sm text-blue-500 hover:text-blue-800" onClick={(event) => { event.preventDefault(); setZoom(zoom + 0.1);}}><BsZoomIn /> <span className='ml-2'>Zoom In</span></button>
              <button className="flex justify-between items-center font-bold text-sm text-blue-500 hover:text-blue-800 ml-2 mr-2" onClick={(event) => { event.preventDefault(); setZoom(zoom - 0.1);}}><BsZoomOut />  <span className='ml-2'>Zoom Out</span></button>
              <button className="flex justify-between items-center font-bold text-sm text-blue-500 hover:text-blue-800" onClick={(event) => { event.preventDefault(); setRotation(rotation + 90);}}><BsRepeat /> <span className='ml-2'>Rotate</span></button>
            </div>
            <div className='flex justify-between items-end  photo-footer'>
             {imageSrc && (  <a className='py-2.5 cursor-pointer px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100' 
             onClick={handleNew}>New</a> )}
               <a className='text-white cursor-pointer bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2' 
             onClick={handleSave}>Save</a> 
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PhotoUploadModal;


