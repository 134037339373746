const ResumeModal = {
    formObject:{
      personalDetails: {
        "sectionTitle": "Personal Details", 
        "customSectionTitle": "",
        "icon": "👤",
        "sectionID": `${Math.random().toString(36).substr(2, 9)}`,
        "sectionTitleKey": "personalDetailsSectionTitle",
         "isShow": true,
        "onLoad": true,
        "isDisplay": true,
        "isExpanded": true,
        "inputGroups": [
          {
            "id":`${Math.random().toString(36).substr(2, 9)}`,
            "isExpanded": true,
            "aiWriter": {
              "isStatus": false,
              "actions": [{"id":"generate"},{"id":"rewrite"}]
            }, 
            "inputs": [
              {
                "inputKey":"firstName",
                "inputLabel": "First Name",
                "inputType": "text",
                "placeholder": "e.g. James",
                "show": true,
                "required": false,
                "inputValue": ""
              },
              {
                "inputKey":"lastName",
                "inputLabel": "Last Name",
                "inputType": "text",
                "placeholder": "e.g. Doe",
                "show": true,
                "required": false,
                "inputValue": ""
              },
              {
                "inputKey":"jobTitle",
                "inputLabel": "Job Title",
                "inputType": "text",
                "placeholder": "e.g. Marketing Manager",
                "show": true,
                "required": false,
                "inputValue": ""
              },
              {
                "inputKey":"photo",
                "inputLabel": "Photo",
                "inputType": "upload",
                "placeholder": "Upload your photo",
                "show": true,
                "required": false,
                "inputValue": "",
                "settings":{ 
                  "styleOptions": ['circle', 'square', 'rounded', 'unique'], 
                  "selectedStyle": "circle",
                  "photoVisible": true
                }                
              }              
            ]
          }
          ],           
      "allowMultiple": false, 
      "addButtonLabel": "Add professional summary",
      "defaultView":true,
      "sectionType": "personal"
      },
      contactDetails: {
        "sectionTitle": "Contact Details", 
        "customSectionTitle": "",
        "icon": "🌐",
        "sectionID": `${Math.random().toString(36).substr(2, 9)}`,
         "isShow": true,
        "onLoad": true,
        "isDisplay": true,
        "isExpanded": true,
        "inputGroups": [
          {
            "id":`${Math.random().toString(36).substr(2, 9)}`,
            "isExpanded": true,
            "aiWriter": {
              "isStatus": false,
              "actions": [{"id":"generate"},{"id":"rewrite"}]
            },
            "inputs": [
              {
                "inputKey":"email",
                "inputLabel": "Email",
                "inputType": "text",
                "placeholder": "e.g. mail@example.com",
                "show": true,
                "required": true,
                "inputValue": ""
              },
              {
                "inputKey":"phone",
                "inputLabel": "Phone",
                "inputType": "text",
                "placeholder": "305-123-4545",
                "show": true,
                "required": true,
                "inputValue": ""
              },
              {
                "inputKey":"city",
                "inputLabel": "City",
                "inputType": "text",
                "placeholder": "San Francisco",
                "show": true,
                "required": false,
                "inputValue": ""
              },
              {
                "inputKey":"country",
                "inputLabel": "Country",
                "inputType": "text",
                "placeholder": "United States",
                "show": true,
                "required": false,
                "inputValue": ""
              },
              {
                "inputKey":"postalCode",
                "inputLabel": "Postal Code",
                "inputType": "text",
                "placeholder": "94120",
                "show": true,
                "required": false,
                "inputValue": ""
              },              
              {
                "inputKey":"website",
                "inputLabel": "Website",
                "inputType": "text",
                "placeholder": "Enter your website URL",
                "show": true,
                "required": false,
                "inputValue": ""
              },
              {
                "inputKey":"linkedin",
                "inputLabel": "LinkedIn",
                "inputType": "text",
                "placeholder": "Enter your LinkedIn profile URL",
                "show": true,
                "required": false,
                "inputValue": ""
              },
              {
                "inputKey":"customField",
                "inputLabel": "Custom Field",
                "inputType": "text",
                "placeholder": "Enter your custom field value",
                "show": true,
                "required": false,
                "inputValue": ""
              }
            ]
          }
          ],           
      "allowMultiple": false, 
      "addButtonLabel": "Add professional summary",
      "defaultView":true,
      "sectionType": "contact"
      },
      professionalSummary: {
        "sectionTitle": "Professional Summary",
        "customSectionTitle": "",
        "icon": "📝",
        "sectionID": `${Math.random().toString(36).substr(2, 9)}`,
        "isShow": true,        
        "isDisplay": true,
        "inputGroups": [
          {
            "id":`${Math.random().toString(36).substr(2, 9)}`,
            "isExpanded": true,
            "aiWriter": {
              "isStatus": true,
              "actions": [{"id":"generate"},{"id":"rewrite"}]
            },
        "inputs": [
          {
            "inputKey":"summary",
                "inputLabel": "Summary",
            "inputType": "textarea",
            "placeholder": "Enter a concise summary of your professional background, key achievements, and career goals.",
            "show": true,
            "required": true,
            "inputValue": ""
          }
        ]
      }],
      "aiWriter":['Generate Content', 'Rewrite Section'],
      "allowMultiple": false, 
      "addButtonLabel": "Add professional summary",
      "sectionType": "summary"    
      },     
      education: {
        "sectionTitle": "Education", 
        "customSectionTitle": "",
        "icon": "🎓",
        "sectionID": `${Math.random().toString(36).substr(2, 9)}`,
        "isShow": true,
        "isDisplay": true,
        "inputGroups": [
          {
            "id":`${Math.random().toString(36).substr(2, 9)}`,
            "isExpanded": true,
            "aiWriter": {
            "isStatus": true,
            "actions": [{"id":"generate"},{"id":"rewrite"}]
            },
            "inputs": [
              {
                "inputKey":"courseName",
                "inputLabel": "Course Name",
                "inputType": "text",
                "placeholder": "Enter your course name",
                "show": true,
                "required": true,
                "inputValue": ""
              },
              {
                "inputKey":"collegeName",
                "inputLabel": "School/College Name",
                "inputType": "text",
                "placeholder": "Enter the your school/college name",
                "show": true,
                "required": true,
                "inputValue": ""
              },
              {
                "inputKey":"startDate",
                "inputLabel": "Start Date",
                "inputType": "daterange",
                "placeholder": "Enter start month/year",
                "show": true,
                "required": true,
                "inputValue": ""
              }, 
              {
                "inputKey":"endDate",
                "inputLabel": "End Date",
                "inputType": "daterange",
                "placeholder": "Enter end month/year or select 'Present'",
                "show": true,
                "required": false,
                "disabled": false,
                "inputValue": ""
              }, 
              {
                "inputKey":"present",
                "inputLabel": "Present",
                "inputType": "checkbox",
                "placeholder": "",
                "show": true,
                "required": false,
                "inputValue": ""
              },
              {
                "inputKey":"description",
                "inputLabel": "Description",
                "inputType": "textarea",
                "placeholder": "Describe your studies, achievements, etc.",
                "show": true,
                "required": false,
                "inputValue": "",
                "aiWriter": {
                "isStatus": true,
                    "actions": [{"id":"generate"},{"id":"rewrite"}]
                 } 
              }
            ]
          }
        ],
        "allowMultiple": true,
        "addButtonLabel": "Add another education",
        "sectionType": "education"
      },
      workExperience: {
        "sectionTitle": "Work Experience",
        "customSectionTitle": "",
        "icon": "💼",
        "sectionID": `${Math.random().toString(36).substr(2, 9)}`,
        "isShow": true,
        "isDisplay": true,
        "inputGroups": [
          {
            "id":`${Math.random().toString(36).substr(2, 9)}`,
            "isExpanded": true,
            "aiWriter": {
            "isStatus": true,
                "actions": [{"id":"generate"},{"id":"rewrite"}]
             },
            "inputs": [
              {
                "inputKey":"position",
                "inputLabel": "Position",
                "inputType": "text",
                "placeholder": "Enter your position",
                "show": true,
                "required": true,
                "inputValue": ""
              },
              {
                "inputKey":"companyName",
                "inputLabel": "Company Name",
                "inputType": "text",
                "placeholder": "Enter your company's name",
                "show": true,
                "required": true,
                "inputValue": ""
              },
              {
                "inputKey":"startDate",
                "inputLabel": "Start Date",
                "inputType": "daterange",
                "placeholder": "Enter start month/year",
                "show": true,
                "required": true,
                "inputValue": ""
              }, 
              {
                "inputKey":"endDate",
                "inputLabel": "End Date",
                "inputType": "daterange",
                "placeholder": "Enter end month/year or select 'Present'",
                "show": true,
                "required": false,
                "disabled": false,
                "inputValue": ""
              }, 
              {
                "inputKey":"present",
                "inputLabel": "Present",
                "inputType": "checkbox",
                "placeholder": "",
                "show": true,
                "required": false,
                "inputValue": ""
              },
              {
                "inputKey":"workSummary",
                "inputLabel": "Work Summary",
                "inputType": "textarea",
                "placeholder": "Describe your responsibilities, achievements, etc.",
                "show": true,
                "required": false,
                "inputValue": ""
              }
            ]
          }
        ],        
        "allowMultiple": true,
        "addButtonLabel": "Add another experience",
        "sectionType": "work"
      }, 
      skills: {
        "sectionTitle": "Skills",
        "customSectionTitle": "",
        "icon": "💡",
        "sectionID": `${Math.random().toString(36).substr(2, 9)}`,
        "isShow": true,        
        "isDisplay": true,
        "inputGroups": [
          {
            "id":`${Math.random().toString(36).substr(2, 9)}`,
            "isExpanded": true,
            'Showlevel':true,
            "inputs": [
              {
                "inputKey":"skill",
                "inputLabel": "Skill",
                "inputType": "text",
                "placeholder": "Enter a skill",
                "show": true,
                "required": true,
                "inputValue": ""
              },
              {
                "inputKey":"level",
                "inputLabel": "Level",
                "inputType": "dropdown",
                "placeholder": "Select skill level",
                "show": true,
                "required": true,
                "inputValue": "",
                "options": [
                  "Beginner",
                  "Moderate",
                  "Good",
                  "Very good",
                  "Excellent"
                ]
              }
            ]
          }
        ],   
        "aiWriter": {
        "isStatus": true,
        "actions": [{"id":"generate"}]
         },  
        "settings":[
            {"inputKey":"skillStyle",
                "inputLabel": "Skill Style",
                "inputType": "select", 
                "selectOptions": ['circle', 'square', 'bar', 'bar-rounded', 'dash-line', 'badge', 'bullets', 'none'], 
                "inputValue": "dash-line"
          }
        ],   
        "settingsIsShow": true,
        "allowMultiple": true,
        "addButtonLabel": "Add another skill",
        "sectionType": "skills"
      },      
      softSkills: {
        "sectionTitle": "Soft Skills",
        "customSectionTitle": "",
        "icon": "🌟",
        "sectionID": `${Math.random().toString(36).substr(2, 9)}`,
        "isShow": true,        
        "isDisplay": true,
        "inputGroups": [
          {
            "id":`${Math.random().toString(36).substr(2, 9)}`,
            "isExpanded": true,
            'Showlevel':true,
            "inputs": [
              {
                "inputKey":"softSkill",
                "inputLabel": "Soft Skill",
                "inputType": "text",
                "placeholder": "Enter a skill",
                "show": true,
                "required": true,
                "inputValue": ""
              },
              {
                "inputKey":"level",
                "inputLabel": "Level",
                "inputType": "dropdown",
                "placeholder": "Select skill level",
                "show": true,
                "required": true,
                "inputValue": "",
                "options": [
                  "Beginner",
                  "Moderate",
                  "Good",
                  "Very good",
                  "Excellent"
                ]
              }
            ]
          }
        ],   
        "aiWriter": {
        "isStatus": true,
        "actions": [{"id":"generate"}]
         },  
        "settings":[
            {"inputKey":"softSkillStyle",
                "inputLabel": "Skill Style","inputType": "select", "selectOptions": ['circle', 'square', 'bar', 'bar-rounded', 'dash-line', 'badge', 'bullets', 'none'], "inputValue": "circle"
          }
        ],   
        "settingsIsShow": true,
        "allowMultiple": true,
        "addButtonLabel": "Add another soft skill",
        "sectionType": "softSkills"
      },
      projects: {
        "sectionTitle": "Projects",
        "customSectionTitle": "",
        "icon": "📁",
        "sectionID": `${Math.random().toString(36).substr(2, 9)}`,
        "isShow": false,        
        "isDisplay": true,
        "aiWriter": {
        "isStatus": true,
        "actions": [{"id":"rewrite"}]
        },
        "inputGroups": [
          {
            "id":`${Math.random().toString(36).substr(2, 9)}`,
            "isExpanded": true,
            "inputs": [
              {
                "inputKey":"projectTitle",
                "inputLabel": "Project Title",
                "inputType": "text",
                "placeholder": "Enter the project title",
                "show": true,
                "required": true,
                "inputValue": ""
              },
              {
                "inputKey":"role",
                "inputLabel": "Role",
                "inputType": "text",
                "placeholder": "Enter your role",
                "show": true,
                "required": true,
                "inputValue": ""
              },
              {
                "inputKey":"startDate",
                "inputLabel": "Start Date",
                "inputType": "daterange",
                "placeholder": "Enter start month/year",
                "show": true,
                "required": true,
                "inputValue": ""
              }, 
              {
                "inputKey":"endDate",
                "inputLabel": "End Date",
                "inputType": "daterange",
                "placeholder": "Enter end month/year or select 'Present'",
                "show": true,
                "required": false,
                "disabled": false,
                "inputValue": ""
              }, 
              {
                "inputKey":"present",
                "inputLabel": "Present",
                "inputType": "checkbox",
                "placeholder": "",
                "show": true,
                "required": false,
                "inputValue": ""
              },
              {
                "inputKey":"description",
                "inputLabel": "Description",
                "inputType": "textarea",
                "placeholder": "Describe the project, your role, and any accomplishments.",
                "show": true,
                "required": true,
                "inputValue": ""
              }
            ]
          }
        ],
        "allowMultiple": true,
        "addButtonLabel": "Add another project",  
        "sectionType": "projects"
      },
      languages: { 
        "sectionTitle": "Languages",
        "customSectionTitle": "",
        "icon": "🌐",
        "sectionID": `${Math.random().toString(36).substr(2, 9)}`,
        "isShow": true,        
        "isDisplay": true,
        "inputGroups": [
          {
            "id":`${Math.random().toString(36).substr(2, 9)}`,
            "isExpanded": true,
            'Showlevel':true,
            "aiWriter": {
            "isStatus": false,
            "actions": [{"id":"generate"},{"id":"rewrite"}]
            },
            "inputs": [
              {
                "inputKey":"language",
                "inputLabel": "Language",
                "inputType": "text",
                "placeholder": "Enter a language",
                "show": true,
                "required": true,
                "inputValue": ""
              },
              {
                "inputKey":"level",
                "inputLabel": "Level",
                "inputType": "dropdown",
                "placeholder": "Select language level",
                "show": true,
                "required": true,
                "inputValue": "",
                "options": [
                  "Beginner",  
                  "Moderate", 
                  "Good",   
                  "Very good", 
                  "Fluent"         
                ]
              }
            ]
          }
        ],     
        "settings":[
          {"inputKey":"levelStyle",
                "inputLabel": "Level Style","inputType": "select", "selectOptions": ['circle', 'square', 'bar', 'bar-rounded', 'dash-line', 'badge', 'bullets', 'none'], "inputValue": "badge" }
        ],   
        "settingsIsShow": true,
        "allowMultiple": true,
        "addButtonLabel": "Add another language",
        "sectionType": "languages"
      },
      achievements: {
        "sectionTitle": "Achievements",
        "customSectionTitle": "",
        "icon": "🎯",
        "sectionID": `${Math.random().toString(36).substr(2, 9)}`,
        "isShow": true,        
        "isDisplay": true,
        "inputGroups": [
          {
            "id":`${Math.random().toString(36).substr(2, 9)}`,
            "isExpanded": true,
            "aiWriter": {
              "isStatus": true,
              "actions": [{"id":"rewrite"}]
            },
        "inputs": [
          {
            "inputKey":"achievements",
                "inputLabel": "Achievements",
            "inputType": "textarea",
            "placeholder": `List your key achievements, e.g., 'Increased sales by 20% within the first year as Sales Manager.'`,
            "show": true,
            "required": true,
            "inputValue": ""
          }
        ]
      }],
      "allowMultiple": false, 
      "addButtonLabel": "Add achievements",  
      "sectionType": "achievements"
      }, 
      awards: { 
        "sectionTitle": "Awards",
        "customSectionTitle": "",
        "icon": "🏆",
        "sectionID": `${Math.random().toString(36).substr(2, 9)}`,  
        "isShow": false,        
        "isDisplay": true,
          "inputGroups": [
            {
              "id":`${Math.random().toString(36).substr(2, 9)}`,
              "isExpanded": true,
              'Showlevel':true,
              "aiWriter": {
                "isStatus": true,
                "actions": [{"id":"rewrite"}]
              },
              "inputs": [
                {
                  "inputKey":"awardName",
                "inputLabel": "Award Name",
                  "inputType": "text",
                  "placeholder": "Enter the award name",
                  "show": true,
                  "required": true,
                  "inputValue": ""
                },
                {
                  "inputKey":"issuingOrganization",
                "inputLabel": "Issuing Organization",
                  "inputType": "text",
                  "placeholder": "Enter the issuing organization",
                  "show": true,
                  "required": true,
                  "inputValue": ""
                }, 
                {
                  "inputKey":"issuedDate",
                "inputLabel": "Issued Date",
                  "inputType": "daterange",
                  "placeholder": "Enter start month/year",
                  "show": true,
                  "required": true,
                  "inputValue": ""
                }, 
                {
                  "inputKey":"description",
                "inputLabel": "Description",
                  "inputType": "textarea",
                  "placeholder": "Describe the significance of the award",
                  "show": true,
                  "required": false,
                  "inputValue": ""
                }
              ]
            }
          ],
          "allowMultiple": true,
          "addButtonLabel": "Add another award", 
          "sectionType": "awards"
      },     
      references: {
        "sectionTitle": "References",
        "customSectionTitle": "",
        "icon": "👍",
        "sectionID": `${Math.random().toString(36).substr(2, 9)}`,
        "isShow": false,        
        "isDisplay": true,
          "inputGroups": [
            {
              "id":`${Math.random().toString(36).substr(2, 9)}`,
            "isExpanded": true,
            'Showlevel':true,
              "inputs": [
                {
                  "inputKey":"referenceName",
                "inputLabel": "Reference Name",
                  "inputType": "text",
                  "placeholder": "Enter reference's name",
                  "show": true,
                  "required": true,
                  "inputValue": ""
                },
                {
                  "inputKey":"organization",
                "inputLabel": "Organization",
                  "inputType": "text",
                  "placeholder": "Enter organization",
                  "show": true,
                  "required": false,
                  "inputValue": ""
                },
                {
                  "inputKey":"city",
                "inputLabel": "City",
                  "inputType": "text",
                  "placeholder": "Enter city",
                  "show": true,
                  "required": false,
                  "inputValue": ""
                },
                {
                  "inputKey":"phoneNumber",
                "inputLabel": "Phone number",
                  "inputType": "text",
                  "placeholder": "Enter phone number",
                  "show": true,
                  "required": true,
                  "inputValue": ""
                },
                {
                  "inputKey":"emailAddress",
                "inputLabel": "Email address",
                  "inputType": "text",
                  "placeholder": "Enter reference's email",
                  "show": true,
                  "required": true,
                  "inputValue": "",
                  "aiWriter": {
                  "isStatus": false 
                } 
                }
              ]
            }
          ],
          "allowMultiple": true,
          "addButtonLabel": "Add another reference",
          "sectionType": "references"
      },    
      courses: {
        "sectionTitle": "Courses",
        "customSectionTitle": "",
        "icon": "📘",
        "sectionID": `${Math.random().toString(36).substr(2, 9)}`,
        "isShow": false,        
        "isDisplay": true,
        "inputGroups": [
          {
            "id":`${Math.random().toString(36).substr(2, 9)}`,
            "isExpanded": true,
            "aiWriter": {
              "isStatus": true,
              "actions": [{"id":"rewrite"}]
            }, 
            "inputs": [
              {
                "inputKey":"courseName",
                "inputLabel": "Course Name",
                "inputType": "text",
                "placeholder": "Enter the course name",
                "show": true,
                "required": true,
                "inputValue": ""
              },
              {
                "inputKey":"institution",
                "inputLabel": "Institution",
                "inputType": "text",
                "placeholder": "Enter the institution or platform",
                "show": true,
                "required": true,
                "inputValue": ""
              }, 
              {
                "inputKey":"endDate",
                "inputLabel": "End Date",
                "inputType": "daterange",
                "placeholder": "Enter end month/year or select 'Present'",
                "show": true,
                "required": false,
                "disabled": false,
                "inputValue": ""
              },
              {
                "inputKey":"description",
                "inputLabel": "Description",
                "inputType": "textarea",
                "placeholder": "Briefly describe the course",
                "show": true,
                "required": false,
                "inputValue": ""
              }
            ]
          }
        ],
        "allowMultiple": true,
        "addButtonLabel": "Add Another Course",
        "sectionType": "courses"
      },
      certifications: {
        "sectionTitle": "Certifications",
        "customSectionTitle": "",
        "icon": "🏅",
        "sectionID": `${Math.random().toString(36).substr(2, 9)}`,
        "isShow": false,        
        "isDisplay": true,
          "inputGroups": [
            {
              "id":`${Math.random().toString(36).substr(2, 9)}`,
            "isExpanded": true,
            'Showlevel':true,
              "inputs": [
                {
                  "inputKey":"certificate",
                "inputLabel": "Certificate",
                  "inputType": "text",
                  "placeholder": "Enter the certificate name",
                  "show": true,
                  "required": true,
                  "inputValue": ""
                }, 
                {
                  "inputKey":"institution",
                "inputLabel": "Institution",
                  "inputType": "text",
                  "placeholder": "Enter the institution name",
                  "show": true,
                  "required": true,
                  "inputValue": ""
                }, 
                {
                  "inputKey":"issueDate",
                "inputLabel": "Issue Date",
                  "inputType": "daterange",
                  "placeholder": "MM/YYYY",
                  "show": true,
                  "required": false,
                  "inputValue": ""
                }, 
                {
                  "inputKey":"certificationUrl",
                "inputLabel": "Certification URL/ID",
                  "inputType": "text",
                  "placeholder": "Enter the URL/ID to the certification (if available)",
                  "show": true,
                  "required": false,
                  "inputValue": ""
                }
              ]
            }
          ],
          "allowMultiple": true,
          "addButtonLabel": "Add another certificate",
          "sectionType": "certifications"
       },
      internships: {
          "sectionTitle": "Internships",
          "customSectionTitle": "",
          "icon": "📚",
          "sectionID": `${Math.random().toString(36).substr(2, 9)}`,
          "isShow": false,        
          "isDisplay": true,
            "inputGroups": [
              {
                "id":`${Math.random().toString(36).substr(2, 9)}`,
              "isExpanded": true,
              'Showlevel':true,
              "aiWriter": {
                  "isStatus": true,
                  "actions": [{"id":"generate"},{"id":"rewrite"}]
                },
                "inputs": [
                  {
                    "inputKey":"position",
                "inputLabel": "Position",
                    "inputType": "text",
                    "placeholder": "Enter your position",
                    "show": true,
                    "required": true,
                    "inputValue": ""
                  },
                  {
                    "inputKey":"company",
                "inputLabel": "Company",
                    "inputType": "text",
                    "placeholder": "Enter the company name",
                    "show": true,
                    "required": true,
                    "inputValue": ""
                  },
                  {
                    "inputKey":"city",
                "inputLabel": "City",
                    "inputType": "text",
                    "placeholder": "Enter the city",
                    "show": true,
                    "required": false,
                    "inputValue": ""
                  },
                  {
                    "inputKey":"startDate",
                "inputLabel": "Start Date",
                    "inputType": "daterange",
                    "placeholder": "Enter start month/year",
                    "show": true,
                    "required": true,
                    "inputValue": ""
                  }, 
                  {
                    "inputKey":"endDate",
                "inputLabel": "End Date",
                    "inputType": "daterange",
                    "placeholder": "Enter end month/year or select 'Present'",
                    "show": true,
                    "required": false,
                    "disabled": false,
                    "inputValue": ""
                  }, 
                  {
                    "inputKey":"present",
                "inputLabel": "Present",
                    "inputType": "checkbox",
                    "placeholder": "",
                    "show": true,
                    "required": false,
                    "inputValue": ""
                  },
                  {
                    "inputKey":"description",
                   "inputLabel": "Description",
                    "inputType": "textarea",
                    "placeholder": "Describe the project, your role, and any accomplishments.",
                    "show": true,
                    "required": true,
                    "inputValue": ""
                  }
                ]
              }
            ],
            "allowMultiple": true,
            "addButtonLabel": "Add another internship",
            "sectionType": "internship"
      },
      profileLinks: {
        "sectionTitle": "Profile Links",
        "customSectionTitle": "",
        "icon": "🔗",
        "sectionID": `${Math.random().toString(36).substr(2, 9)}`,
        "isShow": false,
        "isDisplay": true, 
        "inputGroups": [
          {
            "id": `${Math.random().toString(36).substr(2, 9)}`,
            "isExpanded": true,
            "inputs": [
              {
                "inputKey": "profileType",
                "inputLabel": "Profile Type",
                "inputType": "text",
                "placeholder": "e.g., LinkedIn, GitHub",
                "show": true,
                "required": true,
                "inputValue": ""
              },
              {
                "inputKey": "profileURL",
                "inputLabel": "Profile URL",
                "inputType": "text",
                "placeholder": "Enter profile URL",
                "show": true,
                "required": true,
                "inputValue": ""
              }
            ]
          }
        ],
        "allowMultiple": true,
        "addButtonLabel": "Add another link",
        "sectionType": "profileLinks"
      },    
      hobbies: {
        "sectionTitle": "Hobbies",
        "customSectionTitle": "",
        "icon": "🎨",
        "sectionID": `${Math.random().toString(36).substr(2, 9)}`,        
        "isShow": false,        
        "isDisplay": true,
          "inputGroups": [
            {
            "id":`${Math.random().toString(36).substr(2, 9)}`,
            "isExpanded": true,
            'Showlevel':true,
            "aiWriter": {
                "isStatus": false,
                "actions": [{"id":"generate"}]
              }, 
              "inputs": [
                {
                  "inputKey":"hobby",
                "inputLabel": "Hobby",
                  "inputType": "text",
                  "placeholder": "Enter a hobby or interest",
                  "show": true,
                  "required": false, 
                  "inputValue": ""
                }
              ]
            } 
          ],
          "allowMultiple": true,
          "addButtonLabel": "Add another hobby",
          "sectionType": "hobby"
      },     
      volunteer: {
          "sectionTitle": "Volunteer",
          "customSectionTitle": "",
          "icon": "🤝",
          "sectionID": `${Math.random().toString(36).substr(2, 9)}`,
          "isShow": false,        
          "isDisplay": true,
          "sectionType": "volunteer",
          "inputGroups": [
            {
              "id":`${Math.random().toString(36).substr(2, 9)}`,
              "isExpanded": true,
              "aiWriter": {
                  "isStatus": true,
                  "actions": [{"id":"generate"},{"id":"rewrite"}]
                }, 
              "inputs": [
                {
                  "inputKey":"role",
                "inputLabel": "Role",
                  "inputType": "text",
                  "placeholder": "Enter your volunteer role",
                  "show": true,
                  "required": true,
                  "inputValue": ""
                },
                {
                  "inputKey":"organization",
                "inputLabel": "Organization",
                  "inputType": "text",
                  "placeholder": "Enter the organization name",
                  "show": true,
                  "required": true,
                  "inputValue": ""
                },
                {
                  "inputKey":"startDate",
                "inputLabel": "Start Date",
                  "inputType": "daterange",
                  "placeholder": "Enter start month/year",
                  "show": true,
                  "required": true,
                  "inputValue": ""
                }, 
                {
                  "inputKey":"endDate",
                "inputLabel": "End Date",
                  "inputType": "daterange",
                  "placeholder": "Enter end month/year or select 'Present'",
                  "show": true,
                  "required": false,
                  "disabled": false,
                  "inputValue": ""
                }, 
                {
                  "inputKey":"description",
                "inputLabel": "Description",
                  "inputType": "textarea",
                  "placeholder": "Describe your volunteer work, achievements, and the impact made",
                  "show": true,
                  "required": false,
                  "inputValue": ""
                }
              ]
            }
          ]
      },      
    },
    sectionOrder: {
        singleColumn: [
          "personalDetails",
          "contactDetails",
          "professionalSummary",
          "workExperience",
          "education",
          "projects",
          "skills",
          "certifications",
          "achievements",
          "awards",
          "courses",
          "volunteer",
          "hobbies",
          "references",
          "customSection",
          "languages",
          "internships",
          "profileLinks"
        ],
        twoColumns: {
          leftColumn: [
            "contactDetails",
            "skills",
            "softSkills",
            "languages",
            "hobbies",
            "profileLinks"
          ],
          rightColumn: [
            "personalDetails",
            "professionalSummary",
            "workExperience",
            "education",
            "projects",
            "achievements",
            "certifications",
            "awards",
            "courses",
            "volunteer",
            "references",
            "customSection",
            "internships"
          ]
        }
      },      
   metadata: {
    templateID:"temp12",
    templateName: "Resume",
    description: "",
    creationDate: new Date().toISOString(),
    lastModifiedDate: new Date().toISOString(),
    userID:"",
    resumeID:"",
    selectedStyle:"Visionary",
    selectedFont:"roboto-regular",
    selectedLanguage:"en",
    fontSize:"M",
    textAlignment:"left",
    textTransform:"none",
    lineHeight:"1.30",
    sectionSpacing:"normal",
    selectedColor:""
  },
  formOrder: [
    "personalDetails",
    "contactDetails",
    "professionalSummary",
    "workExperience",
    "education",
    "projects",
    "skills",
    "softSkills",
    "profileLinks",
    "certifications",
    "achievements",
    "awards",
    "courses",
    "volunteer",
    "hobbies",
    "references",
    "customSection",
    "languages",
    "internships"
  ]
};

export default ResumeModal;
