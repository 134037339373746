// ResumeContext.js
import React, { createContext, useContext, useState } from 'react';

const ResumeContext = createContext();

export const useResume = () => useContext(ResumeContext);

export const ResumeProvider = ({ children }) => {
  const [presentStyle, setPresentStyle] = useState('Professional');
  const [isSingleLayout, setIsSingleLayout] = useState(false);
  const [isPhotoThere, setIsPhotoThere] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState('en');

  const value = {
    presentStyle,
    setPresentStyle,
    isSingleLayout, 
    setIsSingleLayout,
    isPhotoThere, 
    setIsPhotoThere,
    currentLanguage,
    setCurrentLanguage
  }; 

  return <ResumeContext.Provider value={value}>{children}</ResumeContext.Provider>;
};
