import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { app, db, auth, storage };
// # Navigate to the project directory using Bash
// cd C:\Sam\Projects\ResumeDesign.ai\resumedesign

// # Ensure the scripts are executable
// chmod +x deploy-cvdesigner.sh
// chmod +x deploy-resume-design.sh

// # Run the deployment script for cvdesigner
// ./deploy-cvdesigner.sh

// # Run the deployment script for resume-design
// ./deploy-resume-design.sh
