// src/components/Job/JobDetails.js
import React from 'react';
import { BiBriefcaseAlt2, BiAlarm } from 'react-icons/bi';
import { BsStars } from "react-icons/bs";

const JobDetails = ({ job }) => {
  const {
    title,
    image,
    company,
    description,
    location,
    employmentType,
    datePosted,
    salaryRange,
    jobProviders
  } = job;

  const getRandomColor = () => {
    const colors = ['bg-red-400', 'bg-blue-400', 'bg-green-400', 'bg-yellow-400', 'bg-purple-400'];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const imageContent = (!image || image === "https://fonts.gstatic.com/s/i/googlematerialicons/auto_stories/v8/gm_grey-24dp/1x/gm_auto_stories_gm_grey_24dp.png") ?
    <div className={`w-14 h-14 rounded ${getRandomColor()} flex items-center justify-center text-white font-bold text-2xl`}>
      {company[0]}
    </div> :
    <img src={image} alt={title} className="" />;

  return (
    <div className="space-y-4 bg-white p-8 px-12">
      <h2 className="text-2xl font-bold">{title}</h2>
      <div className='flex'>
        <div className='company-logo'>
          {imageContent}
        </div>
        <div className='company-details text-gray-800  mb-2'>
          <p className="text-md">{company}</p>
          <p className="text-md">{location}</p>
        </div>
      </div>
      <div className='apply-now border-b border-t border-i-indigo-500 py-4'>
        {jobProviders && jobProviders.length > 0 && (
          <div className="flex flex-wrap gap-3 justify-between">
            <div className='flex flex-wrap gap-3'>
            {jobProviders.map(provider => (
              <a key={provider.url}
                href={provider.url}
                target="_blank"
                rel="noopener noreferrer"
                className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-700 transition-colors duration-300">
                Apply on {provider.jobProvider}
              </a>
            ))}
            </div>
               <a target="_blank"
                rel="noopener noreferrer"
                className="outline-button ai-btn top-create-btn hide" style={{'display':'none'}}>
                 <BsStars /> Generate Custome Resume
              </a>
          </div>
        )}
      </div>
      <div className='flex gap-5 text-sm text-gray-600'>
        <p><BiBriefcaseAlt2 className="inline mr-1.5" /> {employmentType}</p>
        <p><BiAlarm className="inline mr-1.5" /> {datePosted}</p>
      </div>
      {salaryRange && <p className="text-gray-800">Salary: {salaryRange}</p>}
      <div className="mt-4">
        <p className="text-gray-700 whitespace-pre-line text-sm">{description}</p>
      </div>
    </div>
  );
};

export default JobDetails;
