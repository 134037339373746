// src/pages/Editor.js
import React from 'react';
import { useParams } from 'react-router-dom';
import ResumeForm from '../components/Resume/ResumeForm';
import CoverLetterForm from '../components/CoverLetter/CoverLetterForm';

const Editor = () => {
    const { resumeid, coverLetterId } = useParams();
    return (
        <>
            {resumeid && <ResumeForm resumeID={resumeid} />}
            {coverLetterId && <CoverLetterForm coverletterID={coverLetterId} />}
        </>
    );
}

export default Editor;
