// Dropdown.js
import React, { useState, useRef, useEffect } from 'react';
import { BiUserCircle,BiGridVertical, BiWindowClose,BsArrowRepeat, BiX, BiShow, BiHide, BiTransfer } from "react-icons/bi";
import { MdMoreVert } from "react-icons/md"; 

const useOutsideAlerter = (ref, closeDropdown) => {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                closeDropdown();
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, closeDropdown]);
};

const Dropdown = ({ items, sectionKey, handleToggleIsDisplay, handleToggleIsShow, moveSectionToOtherColumn, isSingleLayout }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => setIsOpen(!isOpen);
    const closeDropdown = () => setIsOpen(false);
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, closeDropdown); 

    return (
        <div ref={wrapperRef} className="relative">
            <button
            onClick={toggleDropdown}
            className={`dropdown-toggle p-2 rounded-full bg-slate-100 text-gray-500 hover:bg-slate-200 focus:outline-none ${isOpen ? 'active:bg-slate-200 bg-slate-200' : ''}`}
            >
            <MdMoreVert />
            </button>
            {isOpen && (
                <div className="dropdown-menu absolute mt-2 right-0 bg-white shadow-lg rounded-md overflow-hidden z-10">
                    <div className="py-1">
                        {items.map((item) => (
                            <button
                                key={item.label}
                                onClick={() => {
                                    item.action(sectionKey);
                                    closeDropdown();
                                }}
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left flex items-center"
                            >
                               <span className="mr-2 text-sm">{item.icon}</span> <span className="ml-2 text-xs">{item.label}</span>
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Dropdown;
