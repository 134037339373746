// src/pages/Resumes.js
import React from 'react';

const Resumes = () => {
  return (
    <div>
      <h1>Resumes</h1>
    </div>
  );
};

export default Resumes;